const GUIDELINE_OFFSET = 5; //距离
const Current_GuideLineColor = '#7B89A1';  //当前元素辅助线色值
const Object_GuideLineColor = '#FFA500'; //与画布上元素对齐的辅助线色值
const Canvas_GuideLineColor = '#FF0000'; //与画布对齐的辅助线色值
const strokeWidth = 1  //辅助线宽度

//可以捕捉的参考对象
const getLineGuideStops = (skipNode, event) => {
  let width = event.designArea.width()
  let height = event.designArea.height()

	var vertical = [0, height / 2, height];
  var horizontal = [0, width / 2, width];
  
	event.elementGroup.children.forEach(guideItem => {
		if (guideItem.id() === skipNode.id() || guideItem.id() == 'transformer') {
			return;
		}
		let rotation = guideItem.rotation();
		let x = guideItem.x();
		let y = guideItem.y();
		let width = guideItem.width() * guideItem.scaleX();
		let height = guideItem.height() * guideItem.scaleY();
		let x1, x2, x3, y1, y2, y3;
		x1 = rotation == 90 ? x - height : rotation == 180 ? x - width : x;
		y1 = rotation == 180 ? y - height : rotation == -90 ? y - width : y;
		x2 = rotation == 90 ? x - height / 2 : rotation == 180 ? x - width / 2 : rotation == -90 ? x + height / 2 : x + width / 2;
		y2 = rotation == 90 ? y + width / 2 : rotation == 180 ? y - height / 2 : rotation == -90 ? y - width / 2 : y + height / 2;
		x3 = rotation == 0 ? x + width : rotation == -90 ? x + height : x;
		y3 = rotation == 0 ? y + height : rotation == 90 ? y + width : y;

		if (guideItem.attrs.type == 'Ellipse') {
			x1 = rotation == 90 || rotation == -90 ? x - height / 2 : x - width / 2;
			y1 = rotation == 90 || rotation == -90 ? y - width / 2 : y - height / 2;
			x2 = x;
			y2 = y;
			x3 = rotation == 90 || rotation == -90 ? x + height / 2 : x + width / 2;
			y3 = rotation == 90 || rotation == -90 ? y + width / 2 : y + height / 2;
		}
    horizontal.push([x1, x2, x3]);
		vertical.push([y1, y2, y3]);
  });
  return {
		horizontal: horizontal.flat(),
    vertical: vertical.flat(),
    canvas: {
      horizontal: [0, width / 2, width],
      vertical: [0, height / 2, height],
    }
	};
};

// 获取触发捕捉的点
const getObjectSnappingEdges = (node, event) => {
	let rotation = node.rotation();
	let x = node.x();
	let y = node.y();
	let width = node.width() * node.scaleX();
	let height = node.height() * node.scaleY();
	let x1, x2, x3, y1, y2, y3;
	x1 = rotation == 90 ? x - height : rotation == 180 ? x - width : x;
	y1 = rotation == 180 ? y - height : rotation == -90 ? y - width : y;
	x2 = rotation == 90 ? x - height / 2 : rotation == 180 ? x - width / 2 : rotation == -90 ? x + height / 2 : x + width / 2;
	y2 = rotation == 90 ? y + width / 2 : rotation == 180 ? y - height / 2 : rotation == -90 ? y - width / 2 : y + height / 2;
	x3 = rotation == 0 ? x + width : rotation == -90 ? x + height : x;
	y3 = rotation == 0 ? y + height : rotation == 90 ? y + width : y;

	if (node.attrs.type == 'Ellipse') {
		x1 = rotation == 90 || rotation == -90 ? x - height / 2 : x - width / 2;
		y1 = rotation == 90 || rotation == -90 ? y - width / 2 : y - height / 2;
		x2 = x;
		y2 = y;
		x3 = rotation == 90 || rotation == -90 ? x + height / 2 : x + width / 2;
		y3 = rotation == 90 || rotation == -90 ? y + width / 2 : y + height / 2;
	}

  return {
		horizontal: [
			{
				guide: x1,
				offset: 0,
				snap: 'start'
			},
			{
				guide: x2,
				offset: rotation == 90 || rotation == -90 ? -height / 2 : -width / 2,
				snap: 'center'
			},
			{
				guide: x3,
				offset: rotation == 90 || rotation == -90 ? -height : -width,
				snap: 'end'
			}
		],
		vertical: [
			{
				guide: y1,
				offset: 0,
				snap: 'start'
			},
			{
				guide: y2,
				offset: rotation == 90 || rotation == -90 ? -width / 2 : -height / 2,
				snap: 'center'
			},
			{
				guide: y3,
				offset: rotation == 90 || rotation == -90 ? -width : -height,
				snap: 'end'
			}
		]
	};
};

// 查找所有捕捉可能性
const getGuides = (lineGuideStops, itemBounds, event) => {
	var resultV = [];
  var resultH = [];
  
  lineGuideStops.horizontal.forEach(lineGuide => {
		itemBounds.horizontal.forEach(itemBound => {
			var diff = Math.abs(lineGuide - itemBound.guide);
      if (diff < GUIDELINE_OFFSET) {
        let isCanvasLine = lineGuideStops.canvas.horizontal.some(line => line == lineGuide)
				resultH.push({
					lineGuide: lineGuide,
					diff: diff,
					snap: itemBound.snap,
          offset: itemBound.offset,
          type: isCanvasLine ? 'canvas' : 'object'
				});
			}
		});
	});

	lineGuideStops.vertical.forEach(lineGuide => {
		itemBounds.vertical.forEach(itemBound => {
			var diff = Math.abs(lineGuide - itemBound.guide);
      if (diff < GUIDELINE_OFFSET) {
        let isCanvasLine = lineGuideStops.canvas.vertical.some(line => line == lineGuide)
				resultV.push({
					lineGuide: lineGuide,
					diff: diff,
					snap: itemBound.snap,
          offset: itemBound.offset,
          type: isCanvasLine ? 'canvas' : 'object'
				});
			}
		});
	});

	

  var guides = [];
	var minV = resultV.sort((a, b) => a.diff - b.diff)[0];
  var minH = resultH.sort((a, b) => a.diff - b.diff)[0];
  
	if (minV) {
		guides.push({
			lineGuide: minV.lineGuide,
			offset: minV.offset,
			orientation: 'V',
      snap: minV.snap,
      type: minV.type
		});
	}
	if (minH) {
		guides.push({
			lineGuide: minH.lineGuide,
			offset: minH.offset,
			orientation: 'H',
      snap: minH.snap,
      type: minH.type
		});
  }
  
	return guides;
};

// 绘制捕捉参考线
const drawGuides = (guides, event) => {
  guides.forEach(lg => {
		if (lg.orientation === 'H') {
      var line = new Konva.Line({
				points: [lg.lineGuide, -6000, lg.lineGuide, 6000],
				stroke: lg.type == 'canvas' ? Canvas_GuideLineColor : Object_GuideLineColor,
				strokeWidth: strokeWidth / event.designArea.scaleX(),
				name: 'guid-line',
				dash: [1, 1]
			});
			event.designArea.add(line);
			event.designLayer.batchDraw();
		} else if (lg.orientation === 'V') {
      var line = new Konva.Line({
				points: [-6000, lg.lineGuide, 6000, lg.lineGuide],
				stroke: lg.type == 'canvas' ? Canvas_GuideLineColor : Object_GuideLineColor,
				strokeWidth: strokeWidth / event.designArea.scaleY(),
				name: 'guid-line',
				dash: [1, 1]
			});
			event.designArea.add(line);
			event.designLayer.batchDraw();
		}
	});
};

const drawCurrentNodeGUides = (node, event) => {
	let rotation = node.rotation();
	let x = node.x();
	let y = node.y();
	let width = node.width() * node.scaleX();
	let height = node.height() * node.scaleY();
	let x1, x2, y1, y2;
	x1 = rotation == 90 ? x - height : rotation == 180 ? x - width : x;
	y1 = rotation == 180 ? y - height : rotation == -90 ? y - width : y;
	x2 = rotation == 90 || rotation == -90 ? x1 + height : x1 + width;
	y2 = rotation == 90 || rotation == -90 ? y1 + width : y1 + height;

	if (node.attrs.type == 'Ellipse') {
		x1 = rotation == 90 || rotation == -90 ? x - height / 2 : x - width / 2;
		y1 = rotation == 90 || rotation == -90 ? y - width / 2 : y - height / 2;
		x2 = rotation == 90 || rotation == -90 ? x + height / 2 : x + width / 2;
		y2 = rotation == 90 || rotation == -90 ? y + width / 2 : y + height / 2;
	}

	let leftLine = new Konva.Line({
		name: 'node-line-left',
		points: [x1, -6000, x1, 6000],
		stroke: Current_GuideLineColor,
		strokeWidth: 1 / event.designArea.scaleX(),
		dash: [1, 1]
	});
	event.designArea.add(leftLine);

	let rightLine = new Konva.Line({
		name: 'node-line-right',
		points: [x2 - 6000, x2, 6000],
		stroke: Current_GuideLineColor,
		strokeWidth: 1 / event.designArea.scaleX(),
		dash: [1, 1]
	});
	event.designArea.add(rightLine);

	let topLine = new Konva.Line({
		name: 'node-line-top',
		points: [-6000, y1, 6000, y1],
		stroke: Current_GuideLineColor,
		strokeWidth: 1 / event.designArea.scaleY(),
		dash: [1, 1]
	});
	event.designArea.add(topLine);

	let bottomLine = new Konva.Line({
		name: 'node-line-bottom',
		points: [-6000, y2, 6000, y2],
		stroke: Current_GuideLineColor,
		strokeWidth: 1 / event.designArea.scaleY(),
		dash: [1, 1]
	});
  event.designArea.add(bottomLine);

	event.designLayer.batchDraw();
};

export { drawCurrentNodeGUides, drawGuides, getGuides, getLineGuideStops, getObjectSnappingEdges };
