import { message } from 'ant-design-vue';
import i18n from '../../../language/i18n';
import { drawMirrorNode, drawVerticalText, getCellRang, getTextAlignValue, mmToPx, pxToMm, resetNodeAttr, setNodePos, setNodeSize, sumData } from '../js/common';
import { getNodeAttrs, recordHistory } from '../js/history';
import { drawCurrentNodeGUides, drawGuides, getGuides, getLineGuideStops, getObjectSnappingEdges } from '../js/objectSnapping';
var MIN_WIDTH = 20;
var MIN_HEIGHT = 20;
var MIN_LINE_WIDTH = 1;
var MIN_LINE_HEIGHT = 1;
let MovingAnchorName = '' //记录transformer拖拽的点

const magnification = 3.78; //1mm 约等于3.78px（96dpi）   像素 = 毫米 * ( 分辨率 / 25.4)
const mmToPoint = 3.96; // 1mm约等于3.96点 (31.7/8)  (203dpi(pc端采用203dpi进行计算)) 用于与pc端的数据转换

//创建变换组件
const KonvaTransformer = (enabledAnchors, node) => {
	let parameter = node.attrs.parameter;
	let Transformer = new Konva.Transformer({
		id: 'transformer',
		name: node.id(),
		nodes: [node],
		centeredScaling: false,
		rotateEnabled: false,
		borderStroke: parameter.Lock ? '#7B89A1' : '#003591',
		borderStrokeWidth: 1,
		anchorStroke: '#003591',
		anchorFill: 'rgba(0, 53, 154, 0.8)',
		anchorSize: 20,
		anchorCornerRadius: 10,
    enabledAnchors,
    borderDash: node.attrs.mirrorNode ? [5,2] : [],
		padding: parameter.Type == 'WinText' || parameter.Type == 'TimeText' ? 3 : 0,
		flipEnabled: false,
		boundBoxFunc: (oldBoundBox, newBoundBox) => {
			// console.log(oldBoundBox,newBoundBox)
			return newBoundBox;
    },
    anchorDragBoundFunc: (oldAbsPos, newAbsPos, movingAnchorName, event) => {
      MovingAnchorName = movingAnchorName
      parameter = node.attrs.parameter;
      let nodePos = node.getAbsolutePosition();
      let scaleX = Math.abs(node.scaleX())
      let scaleY = Math.abs(node.scaleY())
      
      if (parameter.Type == 'Line' || parameter.Type == 'LineHorizontal' || parameter.Type == 'LineVertical') {
        if (parameter.Type == 'LineHorizontal' && newAbsPos.x - nodePos.x < MIN_LINE_WIDTH) {
					return {
						x: oldAbsPos.x,
						y: newAbsPos.y
					};
        }
				if (parameter.Type == 'LineVertical' && newAbsPos.y - nodePos.y < MIN_LINE_HEIGHT) {
					return {
						x: newAbsPos.x,
						y: oldAbsPos.y
					};
				}
				if (parameter.Type == 'Line' && (parameter.LineDirection == 'LeftLine' || parameter.LineDirection == 'RightLine')) {
					let x = newAbsPos.x;
					let y = newAbsPos.y;
					if (newAbsPos.x - nodePos.x < MIN_LINE_WIDTH) {
						x = nodePos.x + MIN_WIDTH;
					}
					if (newAbsPos.y - nodePos.y < MIN_LINE_HEIGHT) {
						y = nodePos.y + MIN_HEIGHT;
					}
					return { x, y };
				}
				return newAbsPos;
      } else {
        let rotation = node.rotation();
        if ((node.attrs.type == 'WinText' || node.attrs.type == 'TimeText')) {
          MIN_WIDTH = Math.round(mmToPx(parameter.FontSize / mmToPoint, 2) * 1.1) + 1
          MIN_HEIGHT = Math.round(mmToPx(parameter.FontSize / mmToPoint, 2) * 1.1) + 5
        } else {
          let ratio = (node.height() * scaleY) / (node.width()) * scaleX;
          MIN_HEIGHT = ratio * MIN_WIDTH < MIN_WIDTH ? MIN_WIDTH : ratio * MIN_WIDTH;
        }
        
				if (movingAnchorName == 'middle-right') {
					switch (rotation) {
						case 90:
							if (newAbsPos.y - nodePos.y < MIN_WIDTH) {
								return {
									x: newAbsPos.x,
									y: oldAbsPos.y
								};
							}
							break;
						case 180:
							if (nodePos.x - newAbsPos.x < MIN_WIDTH) {
								return {
									x: oldAbsPos.x,
									y: newAbsPos.y
								};
							}
							break;
						case -90:
							if (nodePos.y - newAbsPos.y < MIN_WIDTH) {
								return {
									x: newAbsPos.x,
									y: oldAbsPos.y
								};
							}
							break;
						default:
							if (newAbsPos.x - nodePos.x < MIN_WIDTH) {
								return {
									x: oldAbsPos.x,
									y: newAbsPos.y
								};
							}
							break;
					}
				} else if (movingAnchorName == 'middle-left') {
          if (parameter.Type == 'WinText' || parameter.Type == 'TimeDate') { 
            let min_w = MIN_WIDTH
            if (parameter.TextArrange == 1) {
              min_w = node.getText().attrs.textWidth
            }
            switch (rotation) {
              case 90:
                let min_y = (nodePos.y + node.width() * scaleX) - min_w
                if (newAbsPos.y > min_y) {
                  return {
                    x: newAbsPos.x,
                    y: min_y
                  };
                }
                break;
              case 180:
                let max_x = (nodePos.x - node.width() * scaleX) + min_w
                if (newAbsPos.x < max_x) {
                  return {
                    x: max_x,
                    y: newAbsPos.y
                  };
                }
                break;
              case -90:
                let max_y = (nodePos.y - node.width() * scaleX) + min_w
                if (newAbsPos.y < max_y) {
                  return {
                    x: newAbsPos.x,
                    y: max_y
                  };
                }
                break;
              default:
                let min_x = (nodePos.x + node.width() * scaleX) - min_w
                if (newAbsPos.x > min_x) {
                  return {
                    x: min_x,
                    y: newAbsPos.y
                  };
                }
                break;
            }
          }
        } else if (movingAnchorName == 'top-center') {
          if (parameter.Type == 'WinText' || parameter.Type == 'TimeDate') {
            let min_h = MIN_HEIGHT
            if (parameter.TextArrange == 0) {
              let old_h = node.height()
              node.getText().height('auto')
              min_h = node.height()
              node.getText().height(old_h)
              node.height(old_h)
            } else if (parameter.TextArrange == 1) { 
              min_h = 20
            }
            switch (rotation) {
              case 90:
                let max_x = (nodePos.x - node.height() * scaleY) + min_h
                if (newAbsPos.x < max_x) {
                  return {
                    x: max_x,
                    y: newAbsPos.y
                  };
                }
                break;
              case 180:
                let min_y = (nodePos.y - node.height() * scaleY) + min_h
                if (newAbsPos.y < min_y) {
                  return {
                    x: newAbsPos.x,
                    y: min_y
                  };
                }
                break;
              case -90:
                let min_x = (nodePos.x + node.height() * scaleY) - min_h
                if (newAbsPos.x > min_x) {
                  return {
                    x: min_x,
                    y: newAbsPos.y
                  };
                }
                break;
              default:
                let max_y = (nodePos.y + node.height() * scaleY) - min_h
                if (newAbsPos.y > max_y) {
                  return {
                    x: newAbsPos.x,
                    y: max_y
                  };
                }
                break;
            }
					}
        } else if (movingAnchorName == 'bottom-center') {
					let min_h = MIN_HEIGHT;
          if (parameter.Type == 'WinText' || parameter.Type == 'TimeDate') {
            let old_h = node.height()
            node.getText().height('auto')
            min_h = node.height()
            node.getText().height(old_h)
            node.height(old_h)
					}
					switch (rotation) {
						case 90:
							if (nodePos.x - newAbsPos.x < min_h) {
								return {
									x: oldAbsPos.x,
									y: newAbsPos.y
								};
							}
							break;
						case 180:
							if (nodePos.y - newAbsPos.y < min_h) {
								return {
									x: newAbsPos.x,
									y: oldAbsPos.y
								};
							}
							break;
						case -90:
							if (newAbsPos.x - nodePos.x < min_h) {
								return {
									x: oldAbsPos.x,
									y: newAbsPos.y
								};
							}
							break;
						default:
							if (newAbsPos.y - nodePos.y < min_h) {
								return {
									x: newAbsPos.x,
									y: oldAbsPos.y
								};
							}
							break;
					}
				} else if (movingAnchorName == 'top-left') {
					let x = newAbsPos.x;
          let y = newAbsPos.y;
          let pos_x = 0;
          let pos_y = 0;
					switch (rotation) {
						case 90:
							if (newAbsPos.x - nodePos.x < MIN_WIDTH) {
								x = nodePos.x + MIN_WIDTH;
              }
              pos_y = nodePos.y + node.width() * scaleX - MIN_WIDTH
							if (newAbsPos.y > pos_y) {
								y = pos_y;
							}
							break;
            case 180:
              pos_x = nodePos.x - node.width() * scaleX + MIN_WIDTH
							if (newAbsPos.x < pos_x) {
								x = pos_x;
              }
							if (newAbsPos.y - nodePos.y < MIN_HEIGHT) {
								y = nodePos.y + MIN_HEIGHT;
							}
							break;
            case -90:
              pos_x = nodePos.x + node.height() * scaleY - MIN_HEIGHT
							if (newAbsPos.x > pos_x) {
								x = pos_x;
              }
              pos_y = nodePos.y - node.width() * scaleX + MIN_WIDTH
							if (newAbsPos.y < pos_y) {
								y = pos_y;
							}
							break;
            default:
              pos_x = nodePos.x + node.width() * scaleX - MIN_WIDTH
              if (newAbsPos.x > pos_x) {
								x = pos_x
              }
              pos_y = (nodePos.y + node.height() * scaleY) - MIN_HEIGHT
              if (newAbsPos.y > pos_y) {
								y = pos_y
              }
							break;
          }
					return { x, y };
        } else if (movingAnchorName == 'top-right') {
					let x = newAbsPos.x;
          let y = newAbsPos.y;
					switch (rotation) {
            case 90:
              let max_x = nodePos.x - node.height() * scaleY + MIN_HEIGHT
							if (newAbsPos.x < max_x) {
								x = max_x;
							}
							if (newAbsPos.y - nodePos.y < MIN_HEIGHT) {
								y = nodePos.y + MIN_HEIGHT;
							}
							break;
            case 180:
							if (nodePos.x - newAbsPos.x < MIN_WIDTH) {
								x = nodePos.x - MIN_WIDTH;
							}
							if (newAbsPos.y - nodePos.y < MIN_HEIGHT) {
								y = nodePos.y + MIN_HEIGHT;
							}
							break;
						case -90:
							let min_x = nodePos.x + node.height() * scaleY - MIN_HEIGHT
              if (newAbsPos.x > min_x) {
								x = min_x;
              }
              if (nodePos.y - newAbsPos.y < MIN_HEIGHT) {
								y = nodePos.y - MIN_HEIGHT;
							}
							break;
            default:
              if (newAbsPos.x - nodePos.x < MIN_WIDTH) {
								x = nodePos.x + MIN_WIDTH;
							}
              let min_y = nodePos.y + node.height() * scaleY - MIN_HEIGHT
              if (newAbsPos.y > min_y) {
								y = min_y
							}
							break;
          }
					return { x, y };
        } else if (movingAnchorName == 'bottom-left') {
					let x = newAbsPos.x;
          let y = newAbsPos.y;
					switch (rotation) {
						case 90:
							if (nodePos.x - newAbsPos.x < MIN_WIDTH) {
								x = nodePos.x - MIN_WIDTH;
              }
              let min_y = nodePos.y + node.width() * scaleX - MIN_WIDTH
							if (newAbsPos.y > min_y) {
								y = min_y;
							}
							break;
            case 180:
              let max_x = nodePos.x - node.width() * scaleX + MIN_WIDTH
							if (newAbsPos.x < max_x) {
                x = max_x;
							}
							if (nodePos.y - newAbsPos.y < MIN_HEIGHT) {
								y = nodePos.y - MIN_HEIGHT;
							}
							break;
						case -90:
							if (newAbsPos.x - nodePos.x < MIN_WIDTH) {
								x = nodePos.x + MIN_WIDTH;
              }
              let max_y = nodePos.y - node.width() * scaleX + MIN_WIDTH
							if (newAbsPos.y < max_y) {
								y = max_y;
							}
							break;
            default:
              let min_x = nodePos.x + node.width() * scaleX - MIN_WIDTH
							if (newAbsPos.x > min_x) {
								x = min_x;
							}
							if (newAbsPos.y - nodePos.y < MIN_HEIGHT) {
								y = nodePos.y + MIN_HEIGHT;
							}
							break;
					}
					return { x, y };
        } else if (movingAnchorName == 'bottom-right') {
					let x = newAbsPos.x;
          let y = newAbsPos.y;
					switch (rotation) {
						case 90:
							if (nodePos.x - newAbsPos.x < MIN_WIDTH) {
								x = nodePos.x - MIN_WIDTH;
							}
							if (newAbsPos.y - nodePos.y < MIN_HEIGHT) {
								y = nodePos.y + MIN_HEIGHT;
							}
							break;
						case 180:
							if (nodePos.x - newAbsPos.x < MIN_WIDTH) {
								x = nodePos.x - MIN_WIDTH;
							}
							if (nodePos.y - newAbsPos.y < MIN_HEIGHT) {
								y = nodePos.y - MIN_HEIGHT;
							}
							break;
						case -90:
							if (newAbsPos.x - nodePos.x < MIN_WIDTH) {
								x = nodePos.x + MIN_WIDTH;
							}
							if (nodePos.y - newAbsPos.y < MIN_HEIGHT) {
								y = nodePos.y - MIN_HEIGHT;
							}
							break;
						default:
							if (newAbsPos.x - nodePos.x < MIN_WIDTH) {
								x = nodePos.x + MIN_WIDTH;
							}
							if (newAbsPos.y - nodePos.y < MIN_HEIGHT) {
								y = nodePos.y + MIN_HEIGHT;
              }
							break;
          }
					return { x, y };
        } 
				return newAbsPos;
      }
		},
		anchorStyleFunc: anchor => {
			// console.log(anchor,123)
      anchor.cornerRadius(10);
      if (parameter.Type != 'Line' && parameter.Type != 'LineHorizontal' && parameter.Type != 'LineVertical') {
        if (parameter.Type == 'WinText' || parameter.Type == 'TimeText') {
          anchor.height(10);
          anchor.width(10);
          if (anchor.hasName('top-left') || anchor.hasName('top-center') || anchor.hasName('top-right')) {
            anchor.offsetY(8);
          }
          if (anchor.hasName('middle-left') || anchor.hasName('middle-right')) {
            anchor.offsetY(5);
          }
          if (anchor.hasName('bottom-left') || anchor.hasName('bottom-center') || anchor.hasName('bottom-right')) {
            anchor.offsetY(2);
          }

          if (anchor.hasName('top-left') || anchor.hasName('middle-left') || anchor.hasName('bottom-left')) {
            anchor.offsetX(8);
          }
          if (anchor.hasName('top-right') || anchor.hasName('middle-right') || anchor.hasName('bottom-right')) {
            anchor.offsetX(2);
          }
          if (anchor.hasName('top-center') || anchor.hasName('bottom-center')) {
            anchor.offsetX(5);
          }
        } else {
          if (anchor.hasName('bottom-center')) {
            anchor.height(6);
            anchor.offsetY(3);
            anchor.width(24);
            anchor.offsetX(12);
          }
          if (anchor.hasName('middle-right')) {
            anchor.height(24);
            anchor.offsetY(12);
            anchor.width(6);
            anchor.offsetX(3);
          }
        }
      }
			// let offset = parameter.Type == 'WinText' || parameter.Type == 'TimeText' ? 0 : 3;
			// if (parameter.Type != 'Line' && parameter.Type != 'LineHorizontal' && parameter.Type != 'LineVertical') {
			// 	if (anchor.hasName('bottom-center')) {
			// 		anchor.height(6);
			// 		anchor.offsetY(offset);
			// 		anchor.width(24);
			// 		anchor.offsetX(12);
			// 	}
			// 	if (anchor.hasName('middle-right')) {
			// 		anchor.height(24);
			// 		anchor.offsetY(12);
			// 		anchor.width(6);
			// 		anchor.offsetX(offset);
			// 	}
			// }
		}
	});
	return Transformer;
};

const getEnabledAnchors = node => {
	let enabledAnchors = [];
	if (node.attrs.parameter && node.attrs.parameter.Lock) {
		return enabledAnchors;
	}
	switch (node.attrs.type) {
		case 'WinText':
		case 'TimeText':
      if (node.attrs.parameter.AutoWrap) {
        if (node.attrs.parameter.TextAlign == 4) {
          if (node.attrs.parameter.TextArrange == 1) {
            enabledAnchors = ['top-center', 'bottom-center'];
          } else {
            enabledAnchors = ['middle-right', 'middle-left'];
          }
        } else {
          enabledAnchors = ['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right'];
        }
			} else {
				enabledAnchors = [];
			}
			break;
		case 'GeneralBarcode':
		case 'QRCode':
			enabledAnchors = ['bottom-right'];
			break;
		case 'LineHorizontal':
			enabledAnchors = ['middle-right'];
			break;
		case 'LineVertical':
			enabledAnchors = ['bottom-center'];
			break;
		case 'Line':
			enabledAnchors = ['middle-right', 'bottom-center'];
			break;
		case 'Image':
			if (node.attrs.parameter.ISParticipating) {
				enabledAnchors = ['bottom-right'];
      } else {
        enabledAnchors = ['middle-right', 'bottom-center', 'bottom-right'];
			}
			break;
		case 'Rectangle':
		case 'RoundRectangle':
		case 'Ellipse':
		case 'Diamond':
		case 'Triangle':
			enabledAnchors = ['middle-right', 'bottom-center', 'bottom-right'];
			break;
		case 'Table':
			enabledAnchors = ['middle-right', 'bottom-center'];
			break;
		default:
			enabledAnchors = ['bottom-right'];
			break;
	}
	return enabledAnchors;
};

//销毁变换组件
const destroyTransformer = designLayer => {
	const transformers = designLayer.find('Transformer');
	transformers.forEach(transformer => {
		// let node = transformer.nodes()
		// node[0].draggable(false)
		transformer.destroy();
  });
};

//清除表格单元格选中的效果
const destroyTableCellSelect = designLayer => {
	let cellSelectArr = designLayer.find('.cellSelect');
	cellSelectArr.forEach(item => {
		item.hide();
	});
	let elementGroup = designLayer.find('#elementGroup')[0];
	elementGroup.children.forEach(node => {
		if (node.attrs.type == 'Table') {
			node.attrs.parameter.cellMultiple = false;
			node.attrs.parameter.mulCellPos = [];
			node.attrs.parameter.currentPos = [0, 0];
		}
	});
	designLayer.draw();
};

//更新变换组件
const updateTransformer = designLayer => {
	const transformers = designLayer.find('Transformer');
  transformers.forEach(transformer => {
		transformer.forceUpdate();
	});
};

//控件事件监听处理
const TransformerListening = (event, node) => {
	var _this = event;
	let oldAttrs = '';
	let newAttrs = '';
	let isTouchMove = false;
	node.on('touchstart mousedown', ev => {
		// console.log('mousedown');
		let buttons = ev.evt.buttons;
		if (buttons == 2) return;

		// console.log("touchstart", ev);
		_this.designArea.draggable(false);
		isTouchMove = false;
		//多选状态下，不执行
		if (ev.evt.ctrlKey) return;
    if (_this.$refs.toolAreaRef.multipleSelection) return;

    let currentNode = node
    
    //如果当前触摸的控件为镜像控件，则将node重置为被镜像的控件
    if (currentNode.attrs.mirrorNode) {
      let id = currentNode.id().split('_')[0]
      currentNode = _this.elementGroup.find(`#${id}`)[0]
    }

    console.log(currentNode)

		//单选状态下
		if (_this.selectNode && _this.selectNode.id() != currentNode.id()) {
      let oldNode = _this.selectNode;
			if (oldNode) {
        oldNode.draggable(false);
        if (oldNode.attrs.parameter.CopyMirrorImage) {
          let old_mirrorNode = _this.elementGroup.find(`#${oldNode.id()}_mirror`)[0]
          old_mirrorNode.draggable(false);
        }
      }
      _this.handleDestroyTextarea()
			_this.selectNode = '';
			_this.elementAttr = {};
			destroyTableCellSelect(_this.designLayer);
    }

    destroyTransformer(_this.designLayer);
		_this.$refs.toolAreaRef.cancelMultipleSelection();
    
    _this.selectNode = currentNode;
    let mirrorNode = ''
    //该控件开启了画布镜像，获取镜像控件
    if (_this.selectNode.attrs.parameter.CopyMirrorImage) {
      let id = _this.selectNode.id()
      mirrorNode = _this.elementGroup.find(`#${id}_mirror`)[0]
    }

		if (_this.selectNode.attrs.parameter.Lock) {
      _this.selectNode.draggable(false);
      if (mirrorNode) {
        mirrorNode.draggable(false)
      }
			message.error(_this.$t(`tips.tip112`));
			_this.$forceUpdate();
		} else {
      _this.selectNode.draggable(true);
      if (mirrorNode) {
        mirrorNode.draggable(true)
      }
		}

    
		if (_this.selectNode.attrs.type == 'Table') {
			let parent = ev.target.getParent();
			let DataSourceList = _this.selectNode.attrs.parameter.DataSourceList;
			if (_this.selectNode.attrs.parameter.cellMultiple) {
				//表格单元格多选
				let currentPos = parent.attrs.pos;
				_this.selectNode.attrs.parameter.currentPos = currentPos;
				_this.selectNode.attrs.parameter.dataSourceIndex = DataSourceList.findIndex(cell => cell.Key[0] == currentPos[0] && cell.Key[1] == currentPos[1]);
				let cell = _this.selectNode.find(`.cell_${currentPos[0]}_${currentPos[1]}`)[0];
				let cellSelect = cell.find('.cellSelect')[0];
				let data = DataSourceList[_this.selectNode.attrs.parameter.dataSourceIndex];
				let isPushIndex = _this.selectNode.attrs.parameter.mulCellPos.findIndex(pos => pos[0] == data.Key[0] && pos[1] == data.Key[1]);
				if (isPushIndex == -1) {
					//选中
					_this.selectNode.attrs.parameter.mulCellPos.push(data.Key);
					cellSelect.show();
					_this.$nextTick(() => {
						if (_this.$refs.attrsDiaRef) {
							if (_this.selectNode.attrs.parameter.mulCellPos.length == 1 && cell.attrs.parameter.AllKeys.length > 1) {
								_this.$refs.attrsDiaRef.showTableSplit = true;
							} else {
								_this.$refs.attrsDiaRef.showTableSplit = false;
							}
						}
					});
				} else {
					//取消选中
					cellSelect.hide();
					_this.selectNode.attrs.parameter.mulCellPos.splice(isPushIndex, 1);
					if (cell.attrs.parameter.AllKeys.length > 1) {
						if (_this.$refs.attrsDiaRef) {
							_this.$refs.attrsDiaRef.showTableSplit = false;
						}
					}
					//剩下一个选中的单元格
					if (_this.selectNode.attrs.parameter.mulCellPos.length == 1) {
						let currentPos = _this.selectNode.attrs.parameter.mulCellPos[0];
						let cell = _this.selectNode.find(`.cell_${currentPos[0]}_${currentPos[1]}`)[0];
						if (cell.attrs.parameter.AllKeys.length > 1) {
							//存在合并单元格
							_this.selectNode.attrs.parameter.currentPos = currentPos;
							_this.selectNode.attrs.parameter.cellIndex = DataSourceList.findIndex(cell => cell.Key[0] == currentPos[0] && cell.Key[1] == currentPos[1]);
							_this.$nextTick(() => {
								if (_this.$refs.attrsDiaRef) {
									_this.$refs.attrsDiaRef.showTableSplit = true;
								}
							});
						} else {
							_this.$nextTick(() => {
								if (_this.$refs.attrsDiaRef) {
									_this.$refs.attrsDiaRef.showTableSplit = false;
								}
							});
						}
					}
				}
			} else {
				//表格单元格单选
				//取消之前的选中单元格
				let oldCurrentPos = _this.selectNode.attrs.parameter.currentPos;
				let oldCell = _this.selectNode.find(`.cell_${oldCurrentPos[0]}_${oldCurrentPos[1]}`)[0];
				let oldSelect = oldCell.find('.cellSelect')[0];
				oldSelect.hide();
				//显示选中单元格
				let currentPos = parent.attrs.pos;
				_this.selectNode.attrs.parameter.currentPos = currentPos;
				_this.selectNode.attrs.parameter.dataSourceIndex = DataSourceList.findIndex(cell => cell.Key[0] == currentPos[0] && cell.Key[1] == currentPos[1]);
				let cell = _this.selectNode.find(`.cell_${currentPos[0]}_${currentPos[1]}`)[0];
				let cellSelect = cell.find('.cellSelect')[0];
				cellSelect.show();
				_this.$nextTick(() => {
					if (cell.attrs.parameter.AllKeys.length > 1) {
						_this.$refs.attrsDiaRef.showTableSplit = true;
					} else {
						_this.$refs.attrsDiaRef.showTableSplit = false;
					}
				});
			}
		}

		setNodePos(_this.selectNode);
		setNodeSize(_this.selectNode);
		_this.elementAttr = JSON.parse(JSON.stringify(_this.selectNode.attrs.parameter));
		let transformer = KonvaTransformer(getEnabledAnchors(_this.selectNode), _this.selectNode);
    _this.elementGroup.add(transformer);
    
    //为镜像控件加上选框
    if (mirrorNode) {
      let transformer = KonvaTransformer([], mirrorNode);
      _this.elementGroup.add(transformer);
    }

		_this.designLayer.draw();
	});

	node.on('touchmove mousemove', ev => {
		// console.log("touchmove", ev);
		isTouchMove = true;
	});

	node.on('touchend mouseup', ev => {
		// console.log("touchend", ev);
		isTouchMove = false;
	});

	node.on('tap click', ev => {
		// console.log("tap", ev);
		//移动过程中不执行
		if (isTouchMove) return;
		//多选状态下
		if (ev.evt.ctrlKey) {
			//按住ctrl （多选状态）
      _this.$refs.toolAreaRef.multipleSelection = true;

      let currentNode = node
      
      //如果当前触摸的控件为镜像控件，则将node重置为被镜像的控件
      let mirrorNode = ''
      if (currentNode.attrs.mirrorNode) {
        let id = currentNode.id().split('_')[0]
        mirrorNode = currentNode
        currentNode = _this.elementGroup.find(`#${id}`)[0]
      } else {
        //否则判断该控件是否开启画布镜像
        if (node.attrs.parameter.CopyMirrorImage) {
          mirrorNode = _this.elementGroup.find(`#${currentNode.id()}_mirror`)[0]
        }
      }
      
			if (_this.selectNode) {
				//如果已经存在选中的元素，将该元素加入到多选数组中
				let transformer = _this.designLayer.find('.' + _this.selectNode.id())[0];
				if (transformer) {
					transformer.enabledAnchors([]);
				}
				_this.$refs.toolAreaRef.selectionElement.push(_this.selectNode);
      }
      
      //判断元素是否已经被选中
			let nodeIndex = _this.$refs.toolAreaRef.selectionElement.findIndex(item => item.id() == currentNode.id());

			if (nodeIndex == -1) {
				//选中
				if (node.attrs.parameter.Lock) {
					message.error(_this.$t(`tips.tip112`));
          currentNode.draggable(false);
          if (mirrorNode) {
            mirrorNode.draggable(false)
          }
				} else {
          currentNode.draggable(true);
          if (mirrorNode) {
            mirrorNode.draggable(true)
          }
				}

				_this.$refs.toolAreaRef.selectionElement.push(currentNode);
				let transformer = KonvaTransformer([], currentNode);
        _this.elementGroup.add(transformer);
        //为镜像控件加上选框
        if (mirrorNode) {
          let transformer = KonvaTransformer([], mirrorNode);
          _this.elementGroup.add(transformer);
        }
			} else {
				//取消选中
        currentNode.draggable(false);
        if (mirrorNode) {
          mirrorNode.draggable(false)
        }
				let transformer_arr = _this.elementGroup.children.filter(item => {
					if (item instanceof Konva.Transformer) {
						return item.name() == currentNode.id() || item.name() == `${currentNode.id()}_mirror`;
					}
				});
        if (transformer_arr.length > 0) {
          transformer_arr.forEach(transformer => {
            transformer.destroy();
          })
				}
				_this.$refs.toolAreaRef.selectionElement.splice(nodeIndex, 1);
			}

			_this.selectNode = '';
			_this.elementAttr = {};
			// console.log('多选数组', _this.$refs.toolAreaRef.selectionElement);
		}
	});

	//双击画布上显示文本输入框
  node.on('dbltap dblclick ', ev => {
    if (_this.$refs.toolAreaRef.multipleSelection) return;
    if (node.attrs.mirrorNode) return
    if (node.attrs.type == 'WinText') {
      if(node.attrs.parameter.DataSourceList.length > 1 || node.attrs.parameter.DataSourceList[0].DataSourceType != 0) 
        return
			node.hide();
      let stageBox = _this.designStage.container().getBoundingClientRect();
      let textPosition = node.absolutePosition();

			let areaPosition = {
				x: stageBox.left + textPosition.x,
				y: stageBox.top + textPosition.y
			};

			let textarea = document.createElement('textarea');
			document.body.appendChild(textarea);

			let scale = _this.designArea.scaleX();
			let fontSize = mmToPx(node.attrs.parameter.FontSize / mmToPoint, 2);
			let letterSpacing = mmToPx(node.attrs.parameter.LetterSpacing, 2);
			let lineHeight = mmToPx(node.attrs.parameter.LineHeight, 2);
      lineHeight = lineHeight / fontSize + 1;

			let textDecoration = [];
			if (node.attrs.parameter.UnderLine) {
				textDecoration.push('underline');
			}
			if (node.attrs.parameter.DeleteLine) {
				textDecoration.push('line-through');
			}
			textDecoration = textDecoration.join(' ');

			textarea.value = node.attrs.parameter.DataSourceList[0].FirstValue;
      textarea.id = 'textareaInput';
      textarea.placeholder = i18n.global.t(`tips.tip124`)
			textarea.style.position = 'absolute';
			textarea.style.top = areaPosition.y + 'px';
      
      if (node.attrs.parameter.TextArrange == 1) {
        textarea.style.left = areaPosition.x - lineHeight / 2 * scale + 'px';
        textarea.style.width = node.width() * scale + lineHeight * scale + 'px';
        textarea.style.height = node.height() * scale + 'px';
      } else {
        textarea.style.left = areaPosition.x + 'px';
        if (node.attrs.parameter.TextAlign == 4) {
          textarea.style.width = node.width() * scale + 'px';
        } else {
          textarea.style.width = node.width() * scale + letterSpacing * scale + 'px';
        }
        textarea.style.height = node.height() * scale + 'px';
      }
			
			textarea.style.fontSize = fontSize * scale + 'px';
			textarea.style.fontWeight = node.attrs.parameter.Bold ? 'bold' : 'normal';
			textarea.style.fontStyle = node.attrs.parameter.Italic ? 'italic' : '';
			textarea.style.textDecoration = textDecoration;
			textarea.style.letterSpacing = letterSpacing * scale + 'px';
			textarea.style.lineHeight = lineHeight;
			textarea.style.fontFamily = node.attrs.parameter.FontFamily;
			textarea.style.textAlign = getTextAlignValue(node.attrs.parameter.TextAlign);
			textarea.style.background = node.attrs.parameter.AntiBlack ? '#000' : 'none';
      textarea.style.color = node.attrs.parameter.AntiBlack ? '#fff' : '#000';
      textarea.style.border = 'none';
			textarea.style.padding = '0px';
			textarea.style.margin = '0px';
			textarea.style.overflow = 'hidden';
			textarea.style.outline = 'none';
			textarea.style.resize = 'none';
      textarea.style.wordBreak = 'break-all';
      textarea.style.transformOrigin = 'left top';
      
      if (!node.attrs.parameter.AutoWrap || node.attrs.parameter.TextAlign == 4) {
        textarea.style.whiteSpace = 'nowrap'
      }
      
      if (node.attrs.parameter.TextArrange == 1) {
				textarea.style.writingMode = 'vertical-rl';
      }

			let rotation = node.rotation();
			var transform = '';
			if (rotation) {
				transform += 'rotateZ(' + rotation + 'deg)';
      }
      if (node.attrs.parameter.TextAlign == 4) {
        if (node.attrs.parameter.MirrorImage) {
          transform += 'translateX(100%) scaleX('+ (-node.scaleX()) +') scaleY('+ node.scaleY() +')';
        } else {
          transform += 'scaleX('+ node.scaleX() +') scaleY('+ node.scaleY() +')';
        }
      } else {
        if (node.attrs.parameter.MirrorImage) {
          transform += 'translateX(100%) scaleX('+ (-node.scaleX()) +')';
        }
      }
			

			var px = 0;
			// also we need to slightly move textarea on firefox
			// because it jumps a bit
			var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
			if (isFirefox) {
				px += 2 + Math.round(fontSize / 20);
			}
			transform += 'translateY(-' + px + 'px)';

      textarea.style.transform = transform;
      
        //横向文本
        if (node.attrs.parameter.TextArrange == 0 && node.attrs.parameter.AutoWrap && node.attrs.parameter.TextAlign != 4) { 
          textarea.style.height = 'auto';
          textarea.style.height = textarea.scrollHeight + 'px';
        } 
      
      textarea.focus();
      
			textarea.addEventListener('keydown', function (e) {
        setTimeout(() => {
          node.attrs.parameter.DataSourceList[0].FirstValue = textarea.value;
          node.attrs.parameter.DataSourceList[0].NormalText = textarea.value ? textarea.value : i18n.global.t(`tips.tip124`);
          node.attrs.parameter.Data = textarea.value;
          if (node.attrs.parameter.TextArrange == 1) {
            drawVerticalText(node, _this.designLayer);
            _this.designLayer.batchDraw()
            if (node.attrs.parameter.TextAlign != 4) { 
              textPosition = node.absolutePosition();
              areaPosition = {
                x: stageBox.left + textPosition.x,
                y: stageBox.top + textPosition.y
              };
              textarea.style.left = areaPosition.x - lineHeight / 2 * scale + 'px';
              textarea.style.width = node.width() * scale + lineHeight * scale + 'px';
              textarea.style.height = node.height() * scale + letterSpacing * scale + 'px';
            } else {
              textarea.style.height = node.height()  * scale + 'px';
              let transform = textarea.style.transform
              if (transform) {
                transform = transform.split(' ')
                let index = transform.findIndex(item => item.includes('scaleY'))
                if (index > -1) {
                  transform[index] = 'scaleY('+ node.scaleY() +')'
                  textarea.style.transform = transform.join(' ')
                }
              }
            }
          } else {
            let value = textarea.value ? textarea.value : i18n.global.t(`tips.tip124`)
            let data = node.attrs.parameter.TextPrefix + value + node.attrs.parameter.TextSuffix;
            node.getText().text(data);
            resetNodeAttr(node);

            if (node.attrs.parameter.TextAlign != 4) { 
              if (node.attrs.parameter.AutoWrap) {
                textarea.style.height = 'auto';
                let height = textarea.scrollHeight;
                textarea.style.height = height + 'px';
              } else {
                textarea.style.width = node.width() * scale + letterSpacing * scale + 'px';
              }
            } else {
              let transform = textarea.style.transform
              if (transform) {
                transform = transform.split(' ')
                let index = transform.findIndex(item => item.includes('scaleX'))
                if (index > -1) {
                  transform[index] = 'scaleX('+ node.scaleX() +')'
                  textarea.style.transform = transform.join(' ')
                  textarea.style.width = node.width()  * scale + 'px';
                }
              }
            }
          }
          

					setNodePos(_this.selectNode);
					setNodeSize(_this.selectNode);
					_this.elementAttr = JSON.parse(JSON.stringify(_this.selectNode.attrs.parameter));

					updateTransformer(_this.designLayer);
				}, 5);
			});
		}
	});

	let isDrag = false;
	let attrs = [];
	let clientX = 0;
	let clientY = 0;
	let multipleSelection = false;
  let selectionElement = [];
  let selectNode = ''
  let selectNode_x = 0
  let selectNode_y = 0
  let mirrorNode = ''
  let mirrorNode_x = 0
  let mirrorNode_y = 0
  let isDragMirror = false //是否拖拽的是镜像元素


	node.on('dragstart', ev => {
		// console.log('dragstart', ev);
		multipleSelection = _this.$refs.toolAreaRef.multipleSelection;
    selectionElement = _this.$refs.toolAreaRef.selectionElement;
    clientX = ev.evt.clientX || ev.evt.touches[0].clientX;
    clientY = ev.evt.clientY || ev.evt.touches[0].clientY;
    // 判断拖动的控件是否为镜像控件
    if (node.attrs.mirrorNode) { 
      isDragMirror = true
      mirrorNode = node
      mirrorNode_x = mirrorNode.x()
      mirrorNode_y = mirrorNode.y()
      //获取被镜像的控件
      selectNode = _this.elementGroup.find(`#${mirrorNode.id().split('_')[0]}`)[0]
      selectNode_x = selectNode.x()
      selectNode_y = selectNode.y()
    } else {
      isDragMirror = false
      selectNode = node
      selectNode_x = node.x()
      selectNode_y = node.y()
      if (node.attrs.parameter.CopyMirrorImage) {
        mirrorNode = _this.elementGroup.find(`#${node.id()}_mirror`)[0]
        mirrorNode_x = mirrorNode.x()
        mirrorNode_y = mirrorNode.y()
      }
    }
		if (multipleSelection) {
			//记录初始位置
      selectionElement.map(item => {
        let obj = {
          x: item.x(),
          y: item.y(),
        }
        //需要判断该控件是否有画布镜像，有的话则需要记录位置
        if (item.attrs.parameter.CopyMirrorImage) { 
          let mirrorNode = _this.elementGroup.find(`#${item.id()}_mirror`)[0]
          obj.mirrorNode_x = mirrorNode.x()
          obj.mirrorNode_y = mirrorNode.y()
        }
				attrs.push(obj);
			});
    }

		drawCurrentNodeGUides(selectNode, _this);

		isDrag = false;
		oldAttrs = getNodeAttrs(selectNode);
	});

	node.on('dragmove', ev => {
    // console.log("dragmove", ev);
    let newClientX = ev.evt.clientX || ev.evt.touches[0].clientX;
		let newClientY = ev.evt.clientY || ev.evt.touches[0].clientY;
		let distX = (newClientX - clientX) / _this.multiple;
    let distY = (newClientY - clientY) / _this.multiple;
    

    let id = ''
    if (isDragMirror) {
      //如果拖动的是镜像控件，则要处理被镜像控件的坐标
      selectNode.x(selectNode_x - distX)
      selectNode.y(selectNode_y - distY)
      id = mirrorNode.id().split('_')[0]
    } else {
      //如果拖动的是普通控件，则要判断是否需要处理镜像控件的坐标
      if (selectNode.attrs.parameter.CopyMirrorImage) {
        drawMirrorNode(selectNode, _this)
      }
      id = selectNode.id()
    }
    
    
		if (multipleSelection) {
			selectionElement.forEach((item, index) => {
        if (item.id() != id && !item.attrs.parameter.Lock) {
          if (isDragMirror) {
            item.x(attrs[index].x - distX);
            item.y(attrs[index].y - distY);
          } else {
            item.x(attrs[index].x + distX);
					  item.y(attrs[index].y + distY);
          }

          //需要判断该控件是否有画布镜像，有的话则需要处理
          if (item.attrs.parameter.CopyMirrorImage) {
            let mirrorNode = _this.elementGroup.find(`#${item.id()}_mirror`)[0]
            if (isDragMirror) {
              mirrorNode.x(attrs[index].mirrorNode_x + distX);
              mirrorNode.y(attrs[index].mirrorNode_y + distY);
            } else {
              mirrorNode.x(attrs[index].mirrorNode_x - distX);
              mirrorNode.y(attrs[index].mirrorNode_y - distY);
            }
          }
        }
			});
    }

    if (!multipleSelection && !selectNode) {
			return;
    }

		//控件移动过程中处理并绘制辅助线
		const drawMoveLine = () => {
			let x = selectNode.x();
			let y = selectNode.y();
			let width = selectNode.width() * selectNode.scaleX();
			let height = selectNode.height() * selectNode.scaleY();
      let x1, x2, y1, y2;
      let rotation = selectNode.rotation()
			x1 = rotation == 90 ? x - height : rotation == 180 ? x - width : x;
			y1 = rotation == 180 ? y - height : rotation == -90 ? y - width : y;

			x2 = rotation == 90 || rotation == -90 ? x1 + height : x1 + width;
			y2 = rotation == 90 || rotation == -90 ? y1 + width : y1 + height;

			let type = selectNode.attrs.type;
			if (type == 'Ellipse') {
				x1 = rotation == 90 || rotation == -90 ? x - height / 2 : x - width / 2;
				y1 = rotation == 90 || rotation == -90 ? y - width / 2 : y - height / 2;

				x2 = rotation == 90 || rotation == -90 ? x + height / 2 : x + width / 2;
				y2 = rotation == 90 || rotation == -90 ? y + width / 2 : y + height / 2;
			}

			const node_line_left = _this.designArea.find('.node-line-left')[0];
			const node_line_right = _this.designArea.find('.node-line-right')[0];
			const node_line_top = _this.designArea.find('.node-line-top')[0];
			const node_line_bottom = _this.designArea.find('.node-line-bottom')[0];
			node_line_left.points([x1, -6000, x1, 6000]);
			node_line_right.points([x2, -6000, x2, 6000]);
			node_line_top.points([-6000, y1, 6000, y1]);
			node_line_bottom.points([-6000, y2, 6000, y2]);
		};

		drawMoveLine();
		// 清除所有的线
		const guid_line = _this.designLayer.find('.guid-line');
		guid_line.forEach(item => {
			item.destroy();
		});

		// 查找可能的捕捉线
		var lineGuideStops = getLineGuideStops(selectNode, _this);
		// 查找当前对象的捕捉点
		var itemBounds = getObjectSnappingEdges(selectNode, _this);
		// 查找可以捕捉当前对象的范围
    var guides = getGuides(lineGuideStops, itemBounds, _this);
		if (!guides.length) {
			return;
		}
		drawGuides(guides, _this);

		//元素吸附
		let rotation = selectNode.rotation();
		let width = selectNode.width() * selectNode.scaleX();
    let height = selectNode.height() * selectNode.scaleY();
    let offsetX = 0
    let offsetY = 0
    
		guides.forEach(lg => {
			let x = 0;
			let y = 0;
			switch (lg.orientation) {
				case 'H':
					if (selectNode.attrs.type == 'Ellipse') {
						x = rotation == 90 || rotation == -90 ? lg.lineGuide + lg.offset + height / 2 : lg.lineGuide + lg.offset + width / 2;
					} else {
						x = rotation == 90 ? lg.lineGuide + lg.offset + height : rotation == 180 ? lg.lineGuide + lg.offset + width : lg.lineGuide + lg.offset;
          }
          offsetX = x - selectNode.x();
          selectNode.x(x);
					break;
				case 'V':
					if (selectNode.attrs.type == 'Ellipse') {
						y = rotation == 90 || rotation == -90 ? lg.lineGuide + lg.offset + width / 2 : lg.lineGuide + lg.offset + height / 2;
					} else {
						y = rotation == 180 ? lg.lineGuide + lg.offset + height : rotation == -90 ? lg.lineGuide + lg.offset + width : lg.lineGuide + lg.offset;
          }
          offsetY = y - selectNode.y();
          selectNode.y(y);
					break;
      }
		});
		//吸附后重新绘制辅助线
    drawMoveLine();

    //处理镜像控件吸附后的坐标
    if (isDragMirror) {
      mirrorNode.x(mirrorNode.x() - offsetX)
      mirrorNode.y(mirrorNode.y() - offsetY)
    } else {
      if (selectNode.attrs.parameter.CopyMirrorImage) {
        drawMirrorNode(selectNode, _this)
      }
    }


		_this.designLayer.batchDraw();

		isDrag = true;

    setNodePos(selectNode);
    
		if (_this.selectNode) {
			_this.elementAttr = JSON.parse(JSON.stringify(_this.selectNode.attrs.parameter));
		}
		_this.isDraw = true;
		_this.$forceUpdate();
	});

	node.on('dragend', ev => {
		// console.log("dragend", ev);
		attrs = [];
		clientX = 0;
		clientY = 0;

		//控件移动结束删除辅助线
		const node_line_left = _this.designArea.find('.node-line-left');
		const node_line_right = _this.designArea.find('.node-line-right');
		const node_line_top = _this.designArea.find('.node-line-top');
		const node_line_bottom = _this.designArea.find('.node-line-bottom');
		const guid_line = _this.designLayer.find('.guid-line');
		node_line_left.forEach(item => {
			item.destroy();
		});
		node_line_right.forEach(item => {
			item.destroy();
		});
		node_line_top.forEach(item => {
			item.destroy();
		});
		node_line_bottom.forEach(item => {
			item.destroy();
		});
		guid_line.forEach(item => {
			item.destroy();
		});
		_this.designLayer.draw();

    if (isDrag) {
      if(selectNode.attrs.parameter.CopyMirrorImage){
        drawMirrorNode(selectNode, _this)
      }
			newAttrs = getNodeAttrs(selectNode);
			recordHistory(_this, oldAttrs, newAttrs, 'edit');
		}
    isDrag = false;
    isDragMirror = false
    selectNode = ''
    selectNode_x = 0
    selectNode_y = 0
    mirrorNode = ''
    mirrorNode_x = 0
    mirrorNode_y = 0
		oldAttrs = '';
		newAttrs = '';
	});

	let isTransform = false;
	node.on('transformstart', ev => {
    // console.log("transformstart", ev);
		isTransform = false;
		oldAttrs = getNodeAttrs(node);
	});

	let startX = '';
	let startY = '';
	let points = '';
	node.on('transform', async ev => {
    // console.log('transform', ev);
		switch (node.attrs.type) {
			case 'WinText':
			case 'TimeText':
        if (node.attrs.parameter.TextArrange == 1) {
          // 竖向文本
					if (node.attrs.parameter.TextAlign != 4) {
						let tempText = node.find('.tempText')[0];
            let fontSize = Math.round((node.attrs.parameter.FontSize / mmToPoint) * magnification * 100) / 100;
            
            if (MovingAnchorName == 'middle-left' || MovingAnchorName == 'middle-right') { 
              // 拖拽左右中心点的时候，宽度增大
              let width = node.width() * node.scaleX();
              node.getText().width('auto')
              let min_w = tempText.attrs.textWidth
              let w = Math.max(width, min_w);
              tempText.width(w);
              node.width(w);
            } else {
              if (MovingAnchorName == 'top-left' || MovingAnchorName == 'top-right' || MovingAnchorName == 'bottom-left' || MovingAnchorName == 'bottom-right') {
                //拉伸顶点 需要改变字体大小
                let scale = node.scaleX()
                node.attrs.parameter.FontSize = node.attrs.parameter.FontSize * scale
              }

              let height = Math.max(node.height() * node.scaleY(), fontSize * 1.1);
              node.height(height);
              tempText.height(height);

              let width = node.width() * node.scaleX()
              if (MovingAnchorName == 'top-center' || MovingAnchorName == 'bottom-center') {
                // 高度拉伸时，重置宽度为文本内容实际宽度
                let textWidth = node.getText().attrs.textWidth
                node.getText().width(textWidth < width ? textWidth : width);
                node.width(textWidth < width ? textWidth : width);
              } else {
                node.getText().width(width);
                node.width(width);
              }
            }
           
						node.attrs.parameter.BoxWidth = pxToMm(tempText.height(), 2);
						node.scaleX(1);
						node.scaleY(1);
						_this.designLayer.draw();
						drawVerticalText(node, _this.designLayer);
          } else {
						setNodeSize(node);
					}
        } else {
          //横向文本
          if (node.attrs.parameter.TextAlign != 4) {
            let scaleX = node.scaleX()
            let scaleY = node.scaleY()
           
            if (MovingAnchorName == 'top-center' || MovingAnchorName == 'bottom-center') {
              // 拖拽上下中心点的时候，高度增大
              let height = node.height() * scaleY;
              node.getText().height('auto')
              let min_h = node.getText().height()
              let h = Math.max(height, min_h);
						  node.getText().height(h);
              node.height(h);
            } else {
              if (MovingAnchorName == 'top-left' || MovingAnchorName == 'top-right' || MovingAnchorName == 'bottom-left' || MovingAnchorName == 'bottom-right') {
                //拉伸顶点 需要改变字体大小
                node.attrs.parameter.FontSize = node.attrs.parameter.FontSize * scaleX
                let fontSize = mmToPx(node.attrs.parameter.FontSize / mmToPoint, 2) 
                node.getText().fontSize(fontSize);
                _this.designLayer.batchDraw()
                let height = node.height() * scaleY;
                node.getText().height('auto')
                let min_h = node.getText().height()
                let h = Math.max(height, min_h);
                node.getText().height(h);
                node.height(h);
              } else if (MovingAnchorName == 'middle-left' || MovingAnchorName == 'middle-right') {
                 // 宽度拉伸时，重置高度为文本内容实际高度
                 node.getText().height('auto');
                 node.height(node.getText().height());
              }
              let MIN_TEXT_WIDTH = 2 * node.getText().padding() + node.getText().fontSize() * 1.1;
              let width = Math.max(node.width() * scaleX, MIN_TEXT_WIDTH);
              node.getText().width(width);
            }
            node.attrs.parameter.BoxWidth = pxToMm(node.getText().width(), 2);
            node.scaleX(1);
            node.scaleY(1);
            if (node.getText().scaleX() < 0) {
              //如果文本内容镜像，需处理x坐标
              node.getText().x(node.getText().width());
            }
            _this.designLayer.batchDraw()
          } else {
						setNodeSize(node);
          }
				}
        break;
      case 'GeneralBarcode':
      case 'QRCode':
      case 'Image':
        break;
      case 'LineHorizontal':
			case 'LineVertical':
			case 'Line':
				points = node.points();
				if (node.attrs.type == 'LineHorizontal') {
					points[2] = node.width() * node.scaleX();
        } else if (node.attrs.type == 'LineVertical') {
          points[3] = node.height() * node.scaleY();
				} else {
					if (node.attrs.parameter.LineDirection == 'RightLine') {
						points[2] = node.width() * node.scaleX();
						points[3] = node.height() * node.scaleY();
					} else {
						points[1] = node.height() * node.scaleY();
						points[2] = node.width() * node.scaleX();
					}
				}
				node.points(points);
				node.scaleX(1);
				node.scaleY(1);
				startX = mmToPx(node.attrs.parameter.StartX, 2);
				startY = mmToPx(node.attrs.parameter.StartY, 2);
				node.x(startX);
        node.y(startY);
				break;
			case 'Rectangle':
			case 'RoundRectangle':
				node.width(node.width() * node.scaleX());
				node.height(node.height() * node.scaleY());
				startX = mmToPx(node.attrs.parameter.StartX, 2);
				startY = mmToPx(node.attrs.parameter.StartY, 2);
				node.x(startX);
				node.y(startY);
				node.scaleX(1);
        node.scaleY(1);
				break;
			case 'Ellipse':
				node.width(node.width() * node.scaleX());
				node.height(node.height() * node.scaleY());
				node.radiusX(node.radiusX() * node.scaleX());
				node.radiusY(node.radiusY() * node.scaleY());
				node.scaleX(1);
        node.scaleY(1);
				break;
			case 'Diamond':
				points = node.points();
				points[1] = (node.height() * node.scaleY()) / 2;
				points[2] = (node.width() * node.scaleX()) / 2;
				points[4] = node.width() * node.scaleX();
				points[5] = (node.height() * node.scaleY()) / 2;
				points[6] = (node.width() * node.scaleX()) / 2;
				points[7] = node.height() * node.scaleY();

				node.width(node.width() * node.scaleX());
				node.height(node.height() * node.scaleY());
				startX = mmToPx(node.attrs.parameter.StartX, 2);
				startY = mmToPx(node.attrs.parameter.StartY, 2);
				node.x(startX);
				node.y(startY);
				node.scaleX(1);
        node.scaleY(1);
				break;
      case 'Triangle':
        points = node.points();
				points[1] = node.height() * node.scaleY();
				points[2] = (node.width() * node.scaleX()) / 2;
				points[4] = node.width() * node.scaleX();
				points[5] = node.height() * node.scaleY();

				node.width(node.width() * node.scaleX());
				node.height(node.height() * node.scaleY());
				startX = mmToPx(node.attrs.parameter.StartX, 2);
				startY = mmToPx(node.attrs.parameter.StartY, 2);
				node.x(startX);
				node.y(startY);
				node.scaleX(1);
        node.scaleY(1);
				break;
			case 'Table':
				scaleTable(_this, node);
				break;
    }
    if (node.attrs.parameter.CopyMirrorImage) {
      drawMirrorNode(node, _this)
    }
		setNodeSize(node);
    setNodePos(node);
    if (_this.selectNode) {
			_this.elementAttr = JSON.parse(JSON.stringify(node.attrs.parameter));
		}
		_this.designLayer.draw();
		_this.isDraw = true;
		isTransform = true;
	});

	node.on('transformend', ev => {
		// console.log("transformend", ev);
		if (isTransform) {
			switch (node.attrs.type) {
				case 'Table':
					_this.elementAttr = JSON.parse(JSON.stringify(node.attrs.parameter));
					_this.$forceUpdate();
					break;
			}
			newAttrs = getNodeAttrs(node);
			recordHistory(_this, oldAttrs, newAttrs, 'edit');
		}
		isTransform = false;
		oldAttrs = '';
    newAttrs = '';
    MovingAnchorName = ''
	});
};

//缩放表格
const scaleTable = (_this, node) => {
	let strokeWidth = Math.round(node.attrs.parameter.PenWidth * magnification * 100) / 100;
	let row = node.attrs.parameter.Row;
	let column = node.attrs.parameter.Column;
	let old_tableWidth = node.width();
	let old_tableHeight = node.height();
	let new_tableWidth = node.width() * node.scaleX();
	let new_tableHeight = node.height() * node.scaleY();
	//限制最小宽高
	let min_cellSize = 2; //最小单元格尺寸
	let min_tableWidth = column * min_cellSize + strokeWidth * (column + 1);
	let min_tableHeight = row * min_cellSize + strokeWidth * (row + 1);
	if (new_tableWidth < min_tableWidth) {
		new_tableWidth = min_tableWidth;
	}
	if (new_tableHeight < min_tableHeight) {
		new_tableHeight = min_tableHeight;
	}

	let dist_width = new_tableWidth - old_tableWidth;
	let dist_height = new_tableHeight - old_tableHeight;

	//计算除边线以外的单元格总宽高
	let allCellWidth = old_tableWidth - strokeWidth * (column + 1);
	let allCellHeight = old_tableHeight - strokeWidth * (row + 1);

	//按照单元格比例计算出每列的缩放值
	let distXArr = [];
	for (let i = 0; i < node.attrs.parameter.CellWidth.length; i++) {
		let w = node.attrs.parameter.CellWidth[i];
		let val = (w / allCellWidth) * dist_width;
		distXArr.push(val);
		node.attrs.parameter.CellWidth[i] = w + val;
	}
	//按照单元格比例计算出每行的缩放值
	let distYArr = [];
	for (let i = 0; i < node.attrs.parameter.CellHeight.length; i++) {
		let h = node.attrs.parameter.CellHeight[i];
		let val = (h / allCellHeight) * dist_height;
		distYArr.push(val);
		node.attrs.parameter.CellHeight[i] = h + val;
	}

	let DicLineLocW = []; //列线的坐标（线的左上角）
	let DicLineLocH = []; //行线的坐标（线的左上角）

	//重置行线的参数
	let tableRowLine = node.find('.tableRowLine')[0];
	let sunDistY = 0;
	tableRowLine.children.forEach((item, index) => {
		let y = item.y() + sunDistY;
		sunDistY += distYArr[index];
		item.setAttrs({
			y,
			width: new_tableWidth,
			hitFunc: context => {
				context.beginPath();
				context.rect(0, y, new_tableWidth, item.height());
				context.closePath();
			}
		});
		DicLineLocH.push(y);
	});

	//重置列线的参数
	let tableColumnLine = node.find('.tableColumnLine')[0];
	let sunDistX = 0;
	tableColumnLine.children.forEach((item, index) => {
		let x = item.x() + sunDistX;
		sunDistX += distXArr[index];
		item.setAttrs({
			x,
			height: new_tableHeight,
			hitFunc: context => {
				context.beginPath();
				context.rect(x, 0, item.width(), new_tableHeight);
				context.closePath();
			}
		});
		DicLineLocW.push(x);
	});
	node.attrs.parameter.DicLineLocW = JSON.parse(JSON.stringify(DicLineLocW));
	node.attrs.parameter.DicLineLocH = JSON.parse(JSON.stringify(DicLineLocH));

	//重置单元格位置
	let cellGroup = node.find('.cellGroup')[0];
	let DataSourceList = cellGroup.children.map(cell => {
		let cellBg = cell.find('.cellBg')[0];
		let cellText = cell.find('.cellText')[0];
		let cellSelect = cell.find('.cellSelect')[0];
		let nameArr = cell.name().split('_');
		//nameArr[1] 行坐标   nameArr[2]列坐标
		let allPos = cell.attrs.allPos;
		let distW = distXArr[nameArr[2]];
		let distH = distYArr[nameArr[1]];
		if (allPos.length > 0) {
			//合并的单元格
			const { minRow, maxRow, minColumn, maxColumn } = getCellRang(allPos);
			distW = sumData(distXArr.slice(minColumn, maxColumn + 1));
			distH = sumData(distYArr.slice(minRow, maxRow + 1));
		}
		let rowLine = node.find(`.rowLine_${nameArr[1]}`)[0];
		let columnLine = node.find(`.columnLine_${nameArr[2]}`)[0];
		//无需加上线条的粗细
		// 因为cellgroup的起点为(pendWidth, pendWidth), rowLine、columnLine的起点为(0, 0), 两者正好相差一个pendWidth
		let x = columnLine.x();
		let y = rowLine.y();

		let width = cell.width() + distW;
		let height = cell.height() + distH;

		cell.setAttrs({
			x,
			y,
			width,
			height,
			clipWidth: width,
			clipHeight: height
		});
		cellBg.setAttrs({
			width,
			height
		});
		cellText.setAttrs({
			x: cell.attrs.parameter.MirrorImage ? width : 0,
			width,
			height
		});
		cellSelect.setAttrs({
			width,
			height
		});

		cell.attrs.parameter.StartX = Math.round((cell.x() / magnification) * 100) / 100;
		cell.attrs.parameter.StartY = Math.round((cell.y() / magnification) * 100) / 100;
		cell.attrs.parameter.Width = Math.round((cell.width() / magnification) * 100) / 100;
		cell.attrs.parameter.Height = Math.round((cell.height() / magnification) * 100) / 100;

		return cell.attrs.parameter;
	});

	node.attrs.parameter.DataSourceList = DataSourceList;

	node.width(new_tableWidth);
	node.height(new_tableHeight);
	node.scaleX(1);
	node.scaleY(1);
	_this.designLayer.batchDraw();
};

//修改原点为中心
const changeOriginToCenter = node => {
	if (node.attrs.type == 'Ellipse') {
		return;
	}
	node.setAttrs({
		offsetX: node.width() / 2,
		offsetY: node.height() / 2
	});
	let x1 = node.x() + (node.offsetX() * node.scaleX() * Math.cos((node.rotation() * Math.PI) / 180) - node.offsetY() * node.scaleY() * Math.sin((node.rotation() * Math.PI) / 180));
	let y1 = node.y() + (node.offsetY() * node.scaleY() * Math.cos((node.rotation() * Math.PI) / 180) + node.offsetX() * node.scaleX() * Math.sin((node.rotation() * Math.PI) / 180));
	node.x(x1);
	node.y(y1);
};

//修改原点为左上角
const changeOriginToTopleft = node => {
	if (node.attrs.type == 'Ellipse') {
		return;
  }
	let x1 = node.x() - (node.offsetX() * node.scaleX() * Math.cos((node.rotation() * Math.PI) / 180) - node.offsetY() * node.scaleY() * Math.sin((node.rotation() * Math.PI) / 180));
	let y1 = node.y() - (node.offsetY() * node.scaleY() * Math.cos((node.rotation() * Math.PI) / 180) + node.offsetX() * node.scaleX() * Math.sin((node.rotation() * Math.PI) / 180));
	node.offsetX(0);
	node.offsetY(0);
	node.x(x1);
	node.y(y1);
};

export { KonvaTransformer, TransformerListening, changeOriginToCenter, changeOriginToTopleft, destroyTableCellSelect, destroyTransformer, getEnabledAnchors, updateTransformer };
