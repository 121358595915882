import { mmToPx } from '../js/common';
var _this = '';
const gridSize = 5; // 设置每个网格的大小 mm
const strokeColor = 'rgba(214, 220, 230, 1)';
const strokeColorSolid = 'rgba(123, 137, 161, 0.5)';
const strokeWidth = 1 //px

//网格图层
const drawGridLayer = event => {
	_this = event;
	_this.gridLayer = new Konva.Layer({
    id: 'gridLayer',
	});
	_this.designStage.add(_this.gridLayer);
  _this.gridLayer.zIndex(1);
  let gridGroup = new Konva.Group({
    id: 'gridGroup',
    x:  _this.designArea.x(),
    y:  _this.designArea.y(),
    offsetX: _this.designArea.offsetX(),
		offsetY:  _this.designArea.offsetY(),
		width: _this.designArea.width(),
		height:  _this.designArea.height(),
		scaleX: _this.designArea.scaleX(),
    scaleY: _this.designArea.scaleY(),
    visible: localStorage.getItem('showGrid') && localStorage.getItem('showGrid') == 'true' ? true : false, //是否可见
  })
  _this.gridLayer.add(gridGroup)

  
	drawAxisX(gridGroup);
	drawAxisY(gridGroup);
	_this.gridLayer.batchDraw();
};

//绘制横线
const drawAxisX = (gridGroup) => {
  let labelHeight = _this.labelAttr.height * _this.UnitConversion
  let AxisXGroup = new Konva.Group({
    id: "AxisXGroup",
		x: 0,
		y: 0,
		width: _this.designArea.width(),
		height:  _this.designArea.height(),
	});
	gridGroup.add(AxisXGroup);
	let line_count = Math.ceil(labelHeight / gridSize);
	for (let i = 1; i < line_count; i++) {
    let y = mmToPx(i * gridSize);
    let solid = (i * gridSize) % 10 == 0 ? true : false
    let line = new Konva.Line({
      name: 'AxisXLine',
			points: [0, y, AxisXGroup.width(), y],
			stroke: solid ? strokeColorSolid : strokeColor,
      strokeWidth: strokeWidth / _this.designArea.scaleX(),
      dash: solid ? '' : [2, 1],
      hitFunc: (context) => {
        context.beginPath();
        context.closePath();
      }
		});
		AxisXGroup.add(line);
	}
};

//绘制竖线
const drawAxisY = (gridGroup) => {
  let labelWidth = _this.labelAttr.width * _this.UnitConversion
  let AxisYGroup = new Konva.Group({
    id: "AxisYGroup",
		x: 0,
		y: 0,
		width: _this.designArea.width(),
		height:  _this.designArea.height(),
	});
  gridGroup.add(AxisYGroup);
  let line_count = Math.ceil(labelWidth / gridSize);
	for (let i = 1; i < line_count; i++) {
    let x = mmToPx(i * gridSize);
    let solid = (i * gridSize) % 10 == 0 ? true : false
    let line = new Konva.Line({
      name: 'AxisYLine',
			points: [x, 0, x, AxisYGroup.height()],
			stroke: solid ? strokeColorSolid : strokeColor,
      strokeWidth: strokeWidth / _this.designArea.scaleY(),
      dash: solid ? '' : [2, 1],
      hitFunc: (context) => {
        context.beginPath();
        context.closePath();
      }
		});
		AxisYGroup.add(line);
	}
};

//移动画布，缩放画布，重置网格
const gridMoveAndScale = (event) => {
  _this = event;
  let gridGroup = _this.gridLayer.find("#gridGroup")[0];
  if (!gridGroup.visible()) return
  gridGroup.setAttrs({
    x:  _this.designArea.x(),
    y:  _this.designArea.y(),
		scaleX: _this.designArea.scaleX(),
		scaleY: _this.designArea.scaleY(),
  });

  let AxisXGroup = _this.gridLayer.find("#AxisXGroup")[0];
  AxisXGroup.setAttrs({
    width: _this.designArea.width(),
		height:  _this.designArea.height(),
  })
  AxisXGroup.children.forEach((line) => {
    line.strokeWidth(strokeWidth / _this.designArea.scaleX())
  })
  let AxisYGroup = _this.gridLayer.find("#AxisYGroup")[0];
  AxisYGroup.setAttrs({
    width: _this.designArea.width(),
		height:  _this.designArea.height(),
  })
  AxisYGroup.children.forEach((line) => {
    line.strokeWidth(strokeWidth / _this.designArea.scaleY())
  })

  _this.gridLayer.batchDraw();
}
 
//显示网格时，修改尺寸时  重置网格
const gridReset = (event) => {
  _this = event;
  let gridGroup = _this.gridLayer.find("#gridGroup")[0];
  if(!gridGroup.visible())return
  gridGroup.setAttrs({
    x:  _this.designArea.x(),
    y:  _this.designArea.y(),
    offsetX: _this.designArea.offsetX(),
		offsetY:  _this.designArea.offsetY(),
		width: _this.designArea.width(),
		height:  _this.designArea.height(),
		scaleX: _this.designArea.scaleX(),
		scaleY: _this.designArea.scaleY(),
  });

  gridGroup.removeChildren()
  drawAxisX(gridGroup);
  drawAxisY(gridGroup);
  
  _this.gridLayer.batchDraw();
}

export { drawGridLayer, gridMoveAndScale, gridReset };

