<template>
  <div>
    <div class="memberDia" v-show="showMemberDia" @click="showMemberDia = false">
      <div class="main-box" @click.stop="">
        <CloseOutlined class="close-btn" @click.stop="showMemberDia = false" />
        <img src="../../static/icon/member.png" alt="" />
        <div class="text">{{ text }}</div>
        <el-button
          @click.stop="handleShowUpgradeMember()"
          v-if="VUE_APP_VERSION == 'hprt'"
          >{{ $t(`button.upgradeMember`) }}</el-button
        >
        <el-button v-else @click.stop="showMemberDia = false">{{
          $t(`button.confirm`)
        }}</el-button>
      </div>
    </div>
    <member-upgrade ref="memberUpgradeRef" />
  </div>
</template>
<script>
import { useRouter } from "vue-router";
import { CloseOutlined } from "@ant-design/icons-vue";
import { defineComponent, watch, getCurrentInstance, toRefs, reactive } from "vue";
import MemberUpgrade from "./MemberUpgrade.vue";

export default defineComponent({
  components: {
    CloseOutlined,
    MemberUpgrade,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    let globalProperties = getCurrentInstance().appContext.config.globalProperties;

    const state = reactive({
      showMemberDia: false,
      text: "",
      VUE_APP_VERSION: process.env.VUE_APP_VERSION,
      memberUpgradeRef: null,
      type: "page", //跳转方式 page: 直接跳转会员购买页面  dialog: 显示会员购买弹窗
      page: "", //member会员 expansion扩容
    });

    const show = (value, type = "page", page = "") => {
      state.showMemberDia = true;
      state.text = value;
      state.type = type;
      let configuration = JSON.parse(localStorage.getItem("configuration"));
      let member_space = configuration.member_space;
      let index = state.text.indexOf(member_space);
      if (page) {
        state.page = page;
      } else {
        if (index < 0) {
          // 云存储空间已满，请购买扩容套餐以升级云存储！
          state.page = "expansion";
        } else {
          // 云存储空间已满，升级会员扩容到20M
          state.page = "member";
        }
      }
    };

    //打开会员升级弹窗
    const handleShowUpgradeMember = () => {
      state.showMemberDia = false;
      if (state.type == "dialog") {
        if (state.page == "expansion") {
          window.open("/expansion", "_blank");
        } else {
          state.memberUpgradeRef.show();
        }
      } else {
        if (state.page == "expansion") {
          router.push("/expansion");
        } else {
          router.push("/member");
        }
      }
    };

    return {
      router,
      ...toRefs(state),
      show,
      handleShowUpgradeMember,
    };
  },
});
</script>
<style scoped>
.memberDia {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-box {
  width: 400px;
  min-height: 400px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}
.close-btn {
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 20px;
  color: #fccf8a;
}
.text {
  padding: 0 28px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #1a1d21;
  line-height: 28px;
}
.main-box ::v-deep(.el-button) {
  margin-bottom: 24px;
  width: 340px;
  height: 40px;
  background: linear-gradient(97deg, #fce8c9 0%, #fccf8a 100%);
  border-radius: 4px;
  border-color: #fce8c9;
  font-size: 16px;
  font-weight: 500;
  color: #864510;
}
.main-box ::v-deep(.el-button):hover {
  border: 1px solid #fce8c9 !important;
  color: #864510 !important;
}
@media only screen and (max-width: 1280px) {
}
</style>
