<template>
  <a-modal
    class="exchange-outerbox"
    centered
    :maskClosable="true"
    :closable="false"
    :visible="exchangeVisible"
    :footer="null"
    @cancel="handleClose"
  >
    <div class="exchange-box">
      <div class="exchange-close" @click="handleClose()">
        <img :src="require('../../static/icon/exchange-close.png')" />
      </div>
      <div class="exchange-content">
        <div class="exchange-title">{{ $t(`tips.tip96`) }}</div>
        <div class="exchange-input">
          <a-input
            v-model:value="form.exchange_code"
            :placeholder="$t(`tips.tip96`)"
          />
        </div>
        <div
          class="exchange-btn"
          :style="{
            background: form.exchange_code
              ? 'linear-gradient(97deg, #FCE8C9 0%, #FCCF8A 100%)'
              : 'rgba(255,255,255,0.54)',
            color: form.exchange_code ? '#864510' : 'rgba(134,69,16,0.54)',
          }"
          @click="handleExchange()"
        >
          {{ $t(`button.exchangeNow`) }}
        </div>
      </div>
      <div class="exchange-tipBox">
        <div class="exchange-tipTile">{{ $t(`tips.tip97`) }}</div>
        <ul>
          <li>{{ $t(`tips.tip98`) }}</li>
          <li>{{ $t(`tips.tip99`) }}</li>
          <li>{{ $t(`tips.tip100`) }}</li>
          <li>{{ $t(`tips.tip101`) }}</li>
        </ul>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, toRefs, getCurrentInstance } from "vue";
import { message } from "ant-design-vue";

export default defineComponent({
  emits: ["refresh"],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    let globalProperties =
      getCurrentInstance().appContext.config.globalProperties;
    const axios = globalProperties.$axioshanma;
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const state = reactive({
      exchangeVisible: false,
      form: {
        user_id: userInfo.userid,
        exchange_code: "",
      },
    });

    const show = () => {
      state.exchangeVisible = true;
    };

    const handleExchange = () => {
      if (!state.form.exchange_code) {
        message.error(proxy.$t(`tips.tip96`));
        return;
      }
      axios("/api/tem.app_member/exchangeMember", state.form).then((res) => {
        message.success(proxy.$t(`tips.tip102`));
        handleClose();
        emit("refresh");
      });
    };

    const handleClose = () => {
      state.form.exchange_code = "";
      state.exchangeVisible = false;
    };

    return {
      ...toRefs(state),
      show,
      handleClose,
      handleExchange,
    };
  },
});
</script>
<style>
.exchange-outerbox .ant-modal-content {
  width: 484px;
  margin: 0 calc((100% - 484px) / 2);
}
.exchange-outerbox .ant-modal-body {
  padding: 0;
}
.exchange-input .ant-input {
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  border: none;
  font-size: 16px;
}
.exchange-input .ant-input:focus {
  box-shadow: none;
}
</style>
<style scoped>
.exchange-box {
  width: 484px;
  background-image: url("../../static/icon/exchange-bg.png");
  background-position: 0 0;
  background-size: 100% 100%;
  padding: 72px 64px 65px;
  position: relative;
}
.exchange-close {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 31px;
  height: 31px;
  cursor: pointer;
}
.exchange-content{
  padding-bottom: 19px;
  border-bottom: 1px dashed #FBD08C;
}
.exchange-title {
  font-size: 20px;
  font-weight: 500;
  color: #864510;
  line-height: 24px;
  margin-bottom: 16px;
}
.exchange-btn {
  width: 100%;
  height: 40px;
  background: rgba(255, 255, 255, 0.54);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #864510;
  line-height: 40px;
  margin-top: 24px;
  text-align: center;
  cursor: pointer;
}
.exchange-tipBox {
  margin-top: 19px;
}
.exchange-tipTile {
  font-size: 16px;
  color: #535d6c;
  line-height: 24px;
  margin-bottom: 5px;
}
.exchange-tipBox ul {
  padding-left: 15px;
  list-style-type: disc;
}
.exchange-tipBox li {
  font-size: 14px;
  color: #7b89a1;
  line-height: 25px;
}
</style>
