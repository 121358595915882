<template>
  <div>
    <!-- 左侧操作栏 -->
    <div
      class="edit-print-left"
      ref="printLeft"
      ondragstart="return false;"
      oncontextmenu="return false;"
      @click.stop="(showIcons = false), (showRects = false)"
    >
      <div class="unit-title">
        <img
          class="here-img"
          v-if="VUE_APP_VERSION == 'hprt'"
          src="../../../assets/here/here-logo.png"
          alt=""
        />
        <img v-else class="edit-logo" src="../../../assets/logo.png" alt="" />
      </div>
      <div class="unit-box">
        <div
          class="unit-li"
          v-for="(item, index) in unitList"
          :key="index"
          @click.stop=""
          @mousedown="handleUnit($event, item, index)"
          @dblclick="handledbUnit($event, item, index)"
        >
          <div class="u-img">
            <img class="obj-fit" :src="item.img" style="width: 40%; margin: 0 auto" />
          </div>
          <div class="u-text">{{ item.text }}</div>
          <div
            v-if="item.type == 'Shape' || item.type == 'Icon'"
            class="iconfont icon-youxia u-text-icons"
          ></div>
          <img
            v-if="item.type == 'Table' && VUE_APP_VERSION == 'hprt'"
            class="unit-vip"
            src="../../../../static/icon/VIPjiaobiao-active.png"
          />
        </div>
        <!-- 形状操作栏 -->
        <div v-if="showRects" class="unit-rect" @click.stop="">
          <div
            v-for="(item, index) in rectsList"
            :key="index"
            @click.stop=""
            @mousedown="handleRects($event, item, index)"
            @dblclick="handledbRects($event, item, index)"
            class="unit-rect-li"
          >
            <div class="u-img">
              <img class="obj-fit" :src="item.img" style="width: 40%; margin: 0 auto" />
            </div>
            <div class="u-text">{{ item.text }}</div>
          </div>
        </div>
        <!-- 图标操作栏 -->
        <div v-if="showIcons" class="unit-icons">
          <div class="edit-icon-box" @click.stop="">
            <div class="edit-icon-left">
              <el-tree
                :data="iconsData"
                :props="defaultProps"
                node-key="id"
                :default-expanded-keys="[iconId]"
                :current-node-key="iconChildrenId"
                @node-click="handleNodeClick"
              ></el-tree>
            </div>
            <div class="edit-icon-right">
              <div class="icons-title">{{ iconTitle }}</div>
              <div class="icons-main">
                <div
                  class="icons-item"
                  v-for="(item, index) in iconList"
                  :key="index"
                  @click.stop=""
                  @mousedown="handleIcons($event, item, index)"
                  @dblclick="handledbIcons($event, item, index)"
                >
                  <el-image class="icons-item-img" :src="item.url" :alt="item.name" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 拖拉组件效果 -->
    <div
      class="unit-div"
      v-if="unitFlag"
      :style="{
        left: point.left + 'px',
        top: point.top + 'px',
        cursor: cursor,
      }"
    >
      <div class="u-img">
        <img
          class="obj-fit"
          :src="unitList[unitIndex].img"
          style="width: 50%; margin: 0 auto"
        />
      </div>
      <div class="u-text">{{ unitList[unitIndex].text }}</div>
    </div>
    <!-- 拖拉形状效果 -->
    <div
      class="unit-div"
      v-if="rectsFlag"
      :style="{
        left: point.left + 'px',
        top: point.top + 'px',
        cursor: cursor,
      }"
    >
      <div class="u-img">
        <img
          class="obj-fit"
          :src="rectsList[rectsIndex].img"
          style="width: 50%; margin: 0 auto"
        />
      </div>
      <div class="u-text">{{ rectsList[rectsIndex].text }}</div>
    </div>
    <!-- 拖拉图标效果 -->
    <div
      class="unit-div"
      v-if="iconFlag"
      :style="{
        left: point.left + 'px',
        top: point.top + 'px',
        cursor: cursor,
      }"
    >
      <div class="u-img">
        <img
          class="obj-fit"
          :src="iconList[iconIndex].url"
          style="width: 50%; margin: 0 auto"
        />
      </div>
      <div class="u-text">{{ iconList[iconIndex].name }}</div>
    </div>
  </div>
</template>
<script>
import "../css/edit.css";
import "../css/edit-small.css";
import {
  getTextAlignValue,
  booleanChange,
  setFontStyle,
  getCodeType,
  getFontFamily,
  getGenerateCodeImg,
  resetNodeAttr,
  changeType,
  getCellRang,
  sumData,
  dataPrecision,
  pxToMm,
  randomString,
  urlToBase64,
  formatDate,
  dealTimeOffset,
  getNowDateOrTime,
  getTypeNextNum,
} from "../js/common.js";
import {
  getEnabledAnchors,
  KonvaTransformer,
  TransformerListening,
  destroyTableCellSelect,
} from "../js/transformerStyle";
import { getNodeAttrs, recordHistory } from "../js/history.js";

export default {
  props: {
    designStage: {
      type: Object,
      default: {},
    },
    designArea: {
      type: Object,
      default: {},
    },
    designLayer: {
      type: Object,
      default: {},
    },
    elementGroup: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      VUE_APP_VERSION: process.env.VUE_APP_VERSION,
      unitList: [
        {
          img: require("../../../assets/wenben.png"),
          text: this.$t(`module.editText1`),
          type: "WinText",
        },
        {
          img: require("../../../assets/time.png"),
          text: this.$t(`module.editText2`),
          type: "TimeText",
        },
        {
          img: require("../../../assets/tiaoxingma.png"),
          text: this.$t(`module.editText3`),
          type: "GeneralBarcode",
        },
        {
          img: require("../../../assets/erweima.png"),
          text: this.$t(`module.editText4`),
          type: "QRCode",
        },
        {
          img: require("../../../assets/tupian.png"),
          text: this.$t(`module.editText5`),
          type: "Image",
        },
        {
          img: require("../../../assets/xingzhuang.png"),
          text: this.$t(`module.editText6`),
          type: "Shape",
        },
        {
          img: require("../../../assets/tubiao.png"),
          text: this.$t(`module.editText13`),
          type: "Icon",
        },
        {
          img: require("../../../assets/xiexian.png"),
          text: this.$t(`module.editText14`),
          type: "Line",
        },
        {
          img: require("../../../assets/shuipingxian.png"),
          text: this.$t(`module.editText15`),
          type: "LineHorizontal",
        },
        {
          img: require("../../../assets/chuizhixian.png"),
          text: this.$t(`module.editText16`),
          type: "LineVertical",
        },
        {
          img: require("../../../assets/table.png"),
          text: this.$t(`module.editText17`),
          type: "Table",
        },
      ],
      rectsList: [
        {
          img: require("../../../assets/juxing.png"),
          text: this.$t(`module.editText7`),
          type: "Rectangle",
        },
        {
          img: require("../../../assets/yuanjiaojuxing.png"),
          text: this.$t(`module.editText8`),
          type: "RoundRectangle",
        },
        {
          img: require("../../../assets/lingxing.png"),
          text: this.$t(`module.editText9`),
          type: "Diamond",
        },
        {
          img: require("../../../assets/tuoyuan.png"),
          text: this.$t(`module.editText10`),
          type: "Ellipse",
        },
        {
          img: require("../../../assets/sanjiaoxing.png"),
          text: this.$t(`module.editText11`),
          type: "Triangle",
        },
        // {
        // 	img: require("../../../assets/shixinjuxing.png"),
        // 	text: this.$t(`module.editText12`),
        // 	type: "SolidRectangle"
        // }
      ],
      unitFlag: false,
      unitIndex: 0,
      rectsIndex: 0,
      rectsFlag: false,
      defaultProps: {
        value: "id",
        children: "son",
        label: "name",
      },
      iconsData: [],
      iconList: [],
      iconTitle: "",
      iconId: "",
      iconChildrenId: "",
      iconIndex: -1,
      showIcons: false,
      showRects: false,
      iconFlag: false,
      dbClick: false, //判断是否双击
      nodeType: "WinText",
      point: {
        left: 0,
        top: 0,
      },
      cursor: "no-drop",
      magnification: 3.78, //1mm 约等于3.78px（96dpi）
      mmToPoint: 3.96, // 1mm约等于3.96点 (31.7/8)  (203dpi(pc端采用203dpi进行计算)) 用于与pc端的数据转换
    };
  },
  methods: {
    //获取图标分类
    getIConCategory() {
      this.$axioshanma("/api/tem.app_shared/getLogClass").then((res) => {
        this.iconsData = res;
        this.iconId = this.iconsData[0].id;
        this.iconChildrenId = this.iconsData[0].son[0].id;
        this.iconTitle = this.iconsData[0].son[0].name;
        this.getFirstData(this.iconChildrenId);
        this.showIcons = !this.showIcons;
      });
    },
    //获取图标分类内容
    getFirstData(id) {
      let obj = {
        id: id,
      };
      this.$axioshanma("/api/tem.app_shared/getLogList", obj).then((res) => {
        this.$nextTick(() => {
          this.iconList = res;
        });
      });
    },
    //点击图标分类
    handleNodeClick(e) {
      if (!e.son) {
        this.iconTitle = e.name;
        this.getFirstData(e.id);
      }
    },
    //按下组件
    handleUnit(event, value, index) {
      setTimeout(() => {
        if (this.dbClick) {
          return;
        }
        if (value.type == "Icon") {
          this.showRects = false;
          this.getIConCategory();
        } else if (value.type == "Shape") {
          this.showRects = !this.showRects;
          this.showIcons = false;
        } else {
          event.preventDefault();
          this.showIcons = false;
          this.showRects = false;
          this.point.left = event.pageX;
          this.point.top = event.pageY;
          this.unitIndex = index;
          this.nodeType = value.type;
          if (this.nodeType == "Table") {
            if (
              this.$parent.userMemberInfo.status != 1 &&
              this.VUE_APP_VERSION == "hprt"
            ) {
              this.$parent.$refs.MemberDiaRef.show(this.$t(`tips.tip104`), "dialog","member");
              return;
            }
          }
          this.unitFlag = true;
          let editLabel = document.getElementById("editLabel");
          let editBox = document.getElementById("editBox");

          editLabel.onmousemove = (e) => {
            if (!this.unitFlag) {
              return;
            }
            this.point.left = e.pageX;
            this.point.top = e.pageY;
            if (
              this.point.left > editBox.offsetLeft &&
              this.point.top > editBox.offsetTop
            ) {
              this.cursor = "move";
              this.selectUnit = [];
            } else {
              this.cursor = "no-drop";
            }
          };
          editLabel.onmouseup = (e) => {
            this.unitFlag = false;
            editLabel.onmouseup = null;
            editLabel.onmousemove = null;
            //计算designArea左上角坐标点
            let designArea = {
              x:
                this.designArea.x() -
                (this.designArea.width() * this.designArea.scaleX()) / 2,
              y:
                this.designArea.y() -
                (this.designArea.height() * this.designArea.scaleY()) / 2,
            };
            let x =
              (this.point.left - editBox.offsetLeft - designArea.x) /
              this.designArea.scaleX();
            let y =
              (this.point.top - editBox.offsetTop - designArea.y) /
              this.designArea.scaleY();

            if (this.cursor == "move") {
              this.cursor = "no-drop";
              this.handleAddNode(this.nodeType, x, y);
            }
          };
        }
      }, 300);
    },
    // 双击按下组件
    handledbUnit(event, value, index) {
      this.dbClick = true;
      this.selectUnit = [];
      this.nodeType = value.type;
      if (value.type == "Icon") {
        this.showRects = false;
        this.getIConCategory();
      } else if (value.type == "Shape") {
        this.showRects = !this.showRects;
        this.showIcons = false;
      } else {
        if (this.nodeType == "Table") {
          if (this.$parent.userMemberInfo.status != 1 && this.VUE_APP_VERSION == "hprt") {
            this.$parent.$refs.MemberDiaRef.show(this.$t(`tips.tip104`), "dialog","member");
            return;
          }
        }
        //计算画布中心点坐标
        let x = this.designArea.width() / 2;
        let y = this.designArea.height() / 2;
        this.handleAddNode(this.nodeType, x, y, "dbClick");
      }

      setTimeout(() => {
        this.dbClick = false;
      }, 500);
    },
    //按下形状
    handleRects(event, value, index) {
      setTimeout(() => {
        if (this.dbClick) {
          return;
        }
        event.preventDefault();
        this.point.left = event.pageX - 20;
        this.point.top = event.pageY - 20;
        this.rectsIndex = index;
        this.nodeType = value.type;
        this.rectsFlag = true;
        let editLabel = document.getElementById("editLabel");
        let editBox = document.getElementById("editBox");
        editLabel.onmousemove = (e) => {
          if (!this.rectsFlag) {
            return;
          }
          this.showRects = false;

          this.point.left = e.pageX;
          this.point.top = e.pageY;
          if (
            this.point.left > editBox.offsetLeft &&
            this.point.top > editBox.offsetTop
          ) {
            this.cursor = "move";
            this.selectUnit = [];
          } else {
            this.cursor = "no-drop";
          }
        };
        editLabel.onmouseup = (e) => {
          this.rectsFlag = false;
          editLabel.onmouseup = null;
          editLabel.onmousemove = null;
          //计算designArea左上角坐标点
          let designArea = {
            x:
              this.designArea.x() -
              (this.designArea.width() * this.designArea.scaleX()) / 2,
            y:
              this.designArea.y() -
              (this.designArea.height() * this.designArea.scaleY()) / 2,
          };
          let x =
            (this.point.left - editBox.offsetLeft - designArea.x) /
            this.designArea.scaleX();
          let y =
            (this.point.top - editBox.offsetTop - designArea.y) /
            this.designArea.scaleY();

          if (this.cursor == "move") {
            this.cursor = "no-drop";
            this.handleAddNode(this.nodeType, x, y);
          }
        };
      }, 300);
    },
    //双击按下形状
    handledbRects(event, value, index) {
      this.dbClick = true;
      this.selectUnit = [];
      this.rectsIndex = index;
      this.nodeType = value.type;

      //计算画布中心点坐标
      let x = this.designArea.width() / 2;
      let y = this.designArea.height() / 2;

      this.handleAddNode(this.nodeType, x, y, "dbClick");
      this.showRects = false;

      setTimeout(() => {
        this.dbClick = false;
      }, 500);
    },
    //按下图标
    handleIcons(event, value, index) {
      setTimeout(() => {
        if (this.dbClick) {
          return;
        }
        event.preventDefault();
        this.point.left = event.pageX - 20;
        this.point.top = event.pageY - 20;
        this.iconIndex = index;
        this.nodeType = "Icon";
        this.iconFlag = true;
        let editLabel = document.getElementById("editLabel");
        let editBox = document.getElementById("editBox");
        editLabel.onmousemove = (e) => {
          if (!this.iconFlag) {
            return;
          }
          this.showIcons = false;

          this.point.left = e.pageX;
          this.point.top = e.pageY;
          if (
            this.point.left > editBox.offsetLeft &&
            this.point.top > editBox.offsetTop
          ) {
            this.cursor = "move";
            this.selectUnit = [];
          } else {
            this.cursor = "no-drop";
          }
        };
        editLabel.onmouseup = (e) => {
          this.iconFlag = false;
          editLabel.onmouseup = null;
          editLabel.onmousemove = null;
          //计算designArea左上角坐标点
          let designArea = {
            x:
              this.designArea.x() -
              (this.designArea.width() * this.designArea.scaleX()) / 2,
            y:
              this.designArea.y() -
              (this.designArea.height() * this.designArea.scaleY()) / 2,
          };
          let x =
            (this.point.left - editBox.offsetLeft - designArea.x) /
            this.designArea.scaleX();
          let y =
            (this.point.top - editBox.offsetTop - designArea.y) /
            this.designArea.scaleY();

          if (this.cursor == "move") {
            this.cursor = "no-drop";
            this.handleAddNode(this.nodeType, x, y);
          }
        };
      }, 300);
    },
    //双击按下图标
    handledbIcons(event, value, index) {
      this.dbClick = true;
      this.selectUnit = [];
      this.iconIndex = index;
      this.nodeType = "Icon";
      //计算画布中心点坐标
      let x = this.designArea.width() / 2;
      let y = this.designArea.height() / 2;

      this.handleAddNode(this.nodeType, x, y, "dbClick");
      this.showIcons = false;

      setTimeout(() => {
        this.dbClick = false;
      }, 500);
    },

    //添加控件
    handleAddNode(type, x, y, clickType) {
      let params = { type, x, y, clickType };
      //取消多选
      destroyTableCellSelect(this.designLayer);
      this.$parent.$refs.toolAreaRef.cancelMultipleSelection();
      switch (type) {
        case "WinText":
          this.addText(params);
          break;
        case "TimeText":
          this.addTimeDate(params);
          break;
        case "GeneralBarcode":
        case "QRCode":
          this.addCode(params);
          break;
        case "Image":
          this.addImage(params);
          break;
        case "Icon":
          let imgUrl = this.iconList[this.iconIndex].url;
          urlToBase64(imgUrl, (dataURL) => {
            params.imgUrl = dataURL;
            this.addImage(params);
          });
          break;
        case "Rectangle":
        case "RoundRectangle":
          this.addRectangle(params);
          break;
        case "Ellipse":
          this.addEllipse(params);
          break;
        case "Diamond":
          this.addDiamond(params);
          break;
        case "Triangle":
          this.addTriangle(params);
          break;
        case "Line":
        case "LineHorizontal":
        case "LineVertical":
          this.addLine(params);
          break;
        case "Table":
          if (
            this.$parent.userMemberInfo.status != 1 &&
            process.env.VUE_APP_VERSION == "hprt"
          ) {
            this.$refs.MemberDiaRef.show(this.$t(`tips.tip104`), "dialog", "member");
            this.dbClick = false;
            return;
          }
          this.addTable(params);
          break;
      }
      this.$parent.isDraw = true;
    },

    //添加文本
    addText({ type, x, y, clickType }) {
      let textNode = new Konva.Label({
        id: randomString(8),
        type,
        x,
        y,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
        draggable: true,
      });

      let Tag = new Konva.Tag({
        fill: "",
      });
      textNode.add(Tag);

      let text = new Konva.Text({
        text: this.$t(`tips.tip124`),
        fontFamily: "阿里巴巴普惠体",
        fontSize: 18 / this.$parent.multiple,
        fontStyle: "",
        textDecoration: "",
        align: getTextAlignValue(0),
        lineHeight: 1,
        letterSpacing: 0,
        fill: "#000000",
        scaleX: 1,
        scaleY: 1,
        wrap: "char",
      });
      textNode.add(text);
      this.elementGroup.add(textNode);
      let width = text.width() + text.fontSize();
      text.width(width);
      textNode.width(width);
      if (clickType == "dbClick") {
        textNode.x(x - textNode.width() / 2);
        textNode.y(y - textNode.height() / 2);
      }

      textNode.setAttr("parameter", {
        Id: textNode.id(),
        Type: textNode.attrs.type,
        Name: `${type}${getTypeNextNum(this.elementGroup, type)}`,
        StartX: pxToMm(textNode.x(), 2),
        StartY: pxToMm(textNode.y(), 2),
        Width: pxToMm(textNode.width() * textNode.scaleX(), 2),
        Height: pxToMm(textNode.height() * textNode.scaleY(), 2),
        BoxWidth: pxToMm(textNode.width() * textNode.scaleX(), 2),
        Lock: false,
        Rotate: textNode.rotation(),
        AntiBlack: Tag.fill() ? true : false,
        MirrorImage: text.scaleX() < 0 ? true : false,
        FontFamily: text.fontFamily(),
        FontSize: dataPrecision(text.fontSize(), 1), //单位：pt
        Bold: text.fontStyle().indexOf("bold") > -1 ? true : false,
        Italic: text.fontStyle().indexOf("italic") > -1 ? true : false,
        UnderLine: text.textDecoration().indexOf("underline") > -1 ? true : false,
        DeleteLine: text.textDecoration().indexOf("line-through") > -1 ? true : false,
        TextAlign: 0,
        LineHeight: 0,
        LetterSpacing: 0,
        AutoWrap: true,
        Data: this.$t(`tips.tip124`),
        TextPrefix: "", //前缀
        TextSuffix: "", //后缀
        TextArrange: 0, //  0:横向 1:纵向 2:弧度
        DataSourceList: [
          {
            FirstValue: "",
            NormalText: this.$t(`tips.tip124`),
            DataSourceType: 0, //数据源 0普通文本 1数据库文本 2 序列化文本 3元素值
            SequenceType: 0,
            InitialValue: "1",
            AddedValue: 1,
            Quantity: 1,
            ColumnName: "",
            QuerySql: "",
            TableName: "",
            ConnectionStr: "",
            DataType: 2, //数据类型 0为日期 1为数字 2文本
            DataFormat: "yyyy-MM-dd HH:mm:ss", //日期格式
            ReferenceId: "", //引用元素值的Id
            SheetIndex: [-1, -1],
            DataBase: [],
          },
        ],
        dataSourceIndex: 0,
        CopyMirrorImage: false,
      });
      let transformer = KonvaTransformer(getEnabledAnchors(textNode), textNode);
      TransformerListening(this.$parent, textNode);
      this.elementGroup.add(transformer);
      this.designArea.draggable(false);
      if (this.$parent.selectNode) {
        this.$parent.selectNode.draggable(false);
      }
      this.designLayer.draw();
      this.$parent.selectNode = textNode;
      this.$parent.elementAttr = JSON.parse(JSON.stringify(textNode.attrs.parameter));
      let newAttrs = getNodeAttrs(textNode);
      recordHistory(this.$parent, "", newAttrs, "add");
    },
    //添加条形码、二维码
    addCode({ type, x, y, clickType }) {
      let canvas = document.createElement("canvas");
      try {
        let options = {
          bcid: type == "GeneralBarcode" ? "code128" : type == "QRCode" ? "qrcode" : "",
          text: "0123456789",
          scale: 12,
          includetext: true, //是否显示文本
          textxalign: getTextAlignValue(1), //left center right justify
          textsize: 10,
          textyalign: "bottom", //above bottom
          eclevel: type == "QRCode" ? "M" : "",
        };

        if (type == "GeneralBarcode") {
          options.height = 8;
        }
        bwipjs.toCanvas(canvas, options);
        let url = canvas.toDataURL("image/png");
        let image = new Image();
        image.setAttribute("crossOrigin", "Anonymous");
        image.onload = () => {
          let scaleX = this.designArea.width() / image.width;
          let scaleY = this.designArea.height() / image.height;
          let scale = scaleX > scaleY ? scaleY : scaleX;
          let width = image.width * scale * 0.3;
          let height = image.height * scale * 0.3;
          let pos_x = clickType == "dbClick" ? x - width / 2 : x;
          let pos_y = clickType == "dbClick" ? y - height / 2 : y;
          let barcodeGroup = new Konva.Group({
            id: randomString(8),
            type,
            x: pos_x,
            y: pos_y,
            width: image.width,
            height: image.height,
            scaleX: scale * 0.3,
            scaleY: scale * 0.3,
            draggable: true,
            rotation: 0,
          });

          let barcodeImg = new Konva.Image({
            type,
            x: 0,
            y: 0,
            width: image.width,
            height: image.height,
            image,
            imageSrc: image.src,
            scaleX: 1,
            scaleY: 1,
          });
          barcodeGroup.add(barcodeImg);
          this.elementGroup.add(barcodeGroup);
          barcodeGroup.setAttr("options", options);
          barcodeGroup.setAttr("parameter", {
            Id: barcodeGroup.id(),
            Name: `${type == "GeneralBarcode" ? "BarCode" : "QRCode"}${getTypeNextNum(
              this.elementGroup,
              type
            )}`, //名称
            Type: barcodeGroup.attrs.type,
            StartX: pxToMm(barcodeGroup.x(), 2),
            StartY: pxToMm(barcodeGroup.y(), 2),
            Width: pxToMm(barcodeGroup.width() * barcodeGroup.scaleX(), 2),
            Height: pxToMm(barcodeGroup.height() * barcodeGroup.scaleY(), 2),
            Lock: false,
            Data: options.text,
            Rotate: barcodeGroup.rotation(),
            FontSize: dataPrecision(options.textsize, 1),
            BarcodeType:
              type == "GeneralBarcode" ? "CODE128" : type == "QRCode" ? "QR_CODE" : "",
            TextAlign: 1,
            BarcodeNumPosition: options.includetext
              ? options.textyalign == "above"
                ? 2
                : 1
              : 0,
            ECLevelBits: "M", //二维码纠错率
            DataSourceList: [
              {
                FirstValue: options.text,
                NormalText: options.text,
                DataSourceType: 0, //数据源 0普通文本 1数据库文本 2 序列化文本 3元素值
                SequenceType: 0,
                InitialValue: "1",
                AddedValue: "",
                Quantity: "",
                ColumnName: "",
                QuerySql: "",
                TableName: "",
                ConnectionStr: "",
                DataType: 2, //数据类型 0为日期 1为数字 2文本
                DataFormat: "yyyy-MM-dd HH:mm:ss", //日期格式
                ReferenceId: "", //引用元素值的Id
                SheetIndex: [-1, -1],
                DataBase: [],
              },
            ],
            isEdit: false, //用来判断条码内容是否编辑过
            dataSourceIndex: 0,
            MirrorImage: barcodeImg.scaleX() < 0 ? true : false,
            CopyMirrorImage: false,
          });
          let transformer = KonvaTransformer(
            getEnabledAnchors(barcodeGroup),
            barcodeGroup
          );
          TransformerListening(this.$parent, barcodeGroup);
          this.elementGroup.add(transformer);
          this.designStage.draggable(false);
          if (this.$parent.selectNode) {
            this.$parent.selectNode.draggable(false);
          }
          this.designLayer.draw();
          this.$parent.selectNode = barcodeGroup;
          this.$parent.elementAttr = JSON.parse(
            JSON.stringify(barcodeGroup.attrs.parameter)
          );
          let newAttrs = getNodeAttrs(barcodeGroup);
          recordHistory(this.$parent, "", newAttrs, "add");
        };
        image.src = url;
      } catch (e) {
        console.log(e);
      }
    },
    //添加时间日期
    addTimeDate({ type, x, y, clickType }) {
      let timeDateNode = new Konva.Label({
        id: randomString(8),
        type,
        x,
        y,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
        draggable: true,
      });

      let Tag = new Konva.Tag({
        fill: "",
      });
      timeDateNode.add(Tag);
      let date = getNowDateOrTime("date");
      let time = getNowDateOrTime("time");
      let text = new Konva.Text({
        text: formatDate(dealTimeOffset(date, time, 0, 0), "yyyy/MM/dd", "HH:mm:ss"),
        fontFamily: "阿里巴巴普惠体",
        fontSize: 18 / this.$parent.multiple,
        fontStyle: "",
        textDecoration: "",
        align: getTextAlignValue(0),
        lineHeight: 1,
        letterSpacing: 0,
        fill: "#000000",
        scaleX: 1,
        scaleY: 1,
        wrap: "char",
      });
      timeDateNode.add(text);
      this.elementGroup.add(timeDateNode);
      let width = text.width() + text.fontSize();
      text.width(width);
      timeDateNode.width(width);
      if (clickType == "dbClick") {
        timeDateNode.x(x - timeDateNode.width() / 2);
        timeDateNode.y(y - timeDateNode.height() / 2);
      }

      timeDateNode.setAttr("parameter", {
        Id: timeDateNode.id(),
        Name: `${type}${getTypeNextNum(this.elementGroup, type)}`,
        Type: timeDateNode.attrs.type,
        StartX: pxToMm(timeDateNode.x(), 2),
        StartY: pxToMm(timeDateNode.y(), 2),
        Width: pxToMm(timeDateNode.width() * timeDateNode.scaleX(), 2),
        Height: pxToMm(timeDateNode.height() * timeDateNode.scaleY(), 2),
        BoxWidth: pxToMm(timeDateNode.width() * timeDateNode.scaleX(), 2),
        Lock: false,
        Data: text.text(),
        DataSourceType: 0,
        Rotate: timeDateNode.rotation(),
        AntiBlack: Tag.fill() ? true : false,
        MirrorImage: text.scaleX() < 0 ? true : false,
        FontFamily: text.fontFamily(),
        FontSize: dataPrecision(text.fontSize(), 1),
        Bold: text.fontStyle().indexOf("bold") > -1 ? true : false,
        Italic: text.fontStyle().indexOf("italic") > -1 ? true : false,
        UnderLine: text.textDecoration().indexOf("underline") > -1 ? true : false,
        DeleteLine: text.textDecoration().indexOf("line-through") > -1 ? true : false,
        TextAlign: 0,
        LineHeight: 0,
        LetterSpacing: 0,
        AutoWrap: true,
        TextArrange: 0,
        TimeType: 0, //时间类型  0为系统时间 1为指定时间
        DataFormat: "yyyy/MM/dd", //日期格式
        TimeFormat: "HH:mm:ss", //时间格式
        DayOffset: 0, //天数偏移
        MinuteOffset: 0, //分钟偏移
        SelectDate: date,
        SelectTime: time,
        DateFormatChecked: ["date", "time"],
        CopyMirrorImage: false,
      });
      let transformer = KonvaTransformer(getEnabledAnchors(timeDateNode), timeDateNode);
      this.elementGroup.add(transformer);
      TransformerListening(this.$parent, timeDateNode);
      this.designStage.draggable(false);
      if (this.$parent.selectNode) {
        this.$parent.selectNode.draggable(false);
      }
      this.designLayer.draw();
      this.$parent.selectNode = timeDateNode;
      this.$parent.elementAttr = JSON.parse(JSON.stringify(timeDateNode.attrs.parameter));
      let newAttrs = getNodeAttrs(timeDateNode);
      recordHistory(this.$parent, "", newAttrs, "add");
    },
    //添加图片
    addImage({ type, x, y, clickType, imgUrl }) {
      let image = new Image();
      image.setAttribute("crossOrigin", "Anonymous");
      image.onload = async () => {
        let scaleX = this.designArea.width() / image.width;
        let scaleY = this.designArea.height() / image.height;
        let scale = scaleX > scaleY ? scaleY : scaleX;
        let img_width = image.width * scale * 0.3;
        let img_height = image.height * scale * 0.3;

        let pos_x = clickType == "dbClick" ? x - img_width / 2 : x;
        let pos_y = clickType == "dbClick" ? y - img_height / 2 : y;

        let imgGroup = new Konva.Group({
          id: randomString(8),
          type: "Image",
          x: pos_x,
          y: pos_y,
          width: image.width,
          height: image.height,
          scaleX: scale * 0.3,
          scaleY: scale * 0.3,
          rotation: 0,
          draggable: true,
        });

        let imageObj = new Konva.Image({
          type: "Image",
          x: 0,
          y: 0,
          width: image.width,
          height: image.height,
          image,
          imageSrc: image.src,
          scaleX: 1,
          scaleY: 1,
        });
        imgGroup.add(imageObj);
        this.elementGroup.add(imgGroup);
        imgGroup.setAttr("parameter", {
          Id: imgGroup.id(),
          Name: `Image${getTypeNextNum(this.elementGroup, "Image")}`,
          Type: imgGroup.attrs.type,
          StartX: pxToMm(imgGroup.x(), 2),
          StartY: pxToMm(imgGroup.y(), 2),
          Width: pxToMm(imgGroup.width() * imgGroup.scaleX(), 2),
          Height: pxToMm(imgGroup.height() * imgGroup.scaleY(), 2),
          Lock: false,
          Rotate: imgGroup.rotation(),
          imageMode: 0,
          ISParticipating: true,
          MirrorImage: imageObj.scaleX() < 0 ? true : false,
          CopyMirrorImage: false,
        });
        let transformer = KonvaTransformer(getEnabledAnchors(imgGroup), imgGroup);
        this.elementGroup.add(transformer);
        TransformerListening(this.$parent, imgGroup);
        this.designStage.draggable(false);
        if (this.$parent.selectNode) {
          this.$parent.selectNode.draggable(false);
        }
        this.designLayer.draw();
        this.$parent.selectNode = imgGroup;
        this.$parent.elementAttr = JSON.parse(JSON.stringify(imgGroup.attrs.parameter));
        let newAttrs = getNodeAttrs(imgGroup);
        recordHistory(this.$parent, "", newAttrs, "add");
      };
      image.src = imgUrl ? imgUrl : require("../../../assets/tupian.png");
    },
    //添加线条
    addLine({ type, x, y, clickType }) {
      let len = 100 / this.$parent.multiple;
      let StartX = x;
      let StartY = y;
      let EndX = x;
      let EndY = y;
      let points = [];
      if (type == "LineHorizontal") {
        points = [0, 0, len, 0];
        StartX = clickType == "dbClick" ? x - len / 2 : x;
        EndX = StartX + len;
      } else if (type == "LineVertical") {
        points = [0, 0, 0, len];
        StartY = clickType == "dbClick" ? y - len / 2 : y;
        EndY = StartY + len;
      } else if (type == "Line") {
        //左斜线
        points = [0, len, len, 0];
        StartX = clickType == "dbClick" ? x - len / 2 : x;
        StartY = clickType == "dbClick" ? y - len / 2 : y;
        EndX = StartX + len;
        EndY = StartY + len;
      }
      let lineNode = new Konva.Line({
        id: randomString(8),
        type,
        x: StartX,
        y: StartY,
        points,
        stroke: "#000000",
        strokeWidth: 2,
        dash: [3, 1],
        dashEnabled: false,
        hitStrokeWidth: 10,
        draggable: true,
        scaleX: 1,
        scaleY: 1,
        rotation: 0,
      });
      this.elementGroup.add(lineNode);
      lineNode.setAttr("parameter", {
        Id: lineNode.id(),
        Name: `${type}${getTypeNextNum(this.elementGroup, type)}`,
        Type: lineNode.attrs.type,
        StartX: pxToMm(StartX, 2),
        StartY: pxToMm(StartY, 2),
        EndX: pxToMm(EndX, 2),
        EndY: pxToMm(EndY, 2),
        Width: pxToMm(lineNode.width() * lineNode.scaleX(), 2),
        Height: pxToMm(lineNode.height() * lineNode.scaleY(), 2),
        Lock: false,
        PenWidth: Math.round((lineNode.strokeWidth() / this.magnification) * 10) / 10,
        Dash: lineNode.dashEnabled(),
        DashStyle: 0, //0实线 1虚线
        LineDirection: type == "Line" ? "LeftLine" : type,
        CopyMirrorImage: false,
      });
      let transformer = KonvaTransformer(getEnabledAnchors(lineNode), lineNode);
      this.elementGroup.add(transformer);
      TransformerListening(this.$parent, lineNode);
      this.designStage.draggable(false);
      if (this.$parent.selectNode) {
        this.$parent.selectNode.draggable(false);
      }
      this.designLayer.draw();
      this.$parent.selectNode = lineNode;
      this.$parent.elementAttr = JSON.parse(JSON.stringify(lineNode.attrs.parameter));
      let newAttrs = getNodeAttrs(lineNode);
      recordHistory(this.$parent, "", newAttrs, "add");
    },
    //添加矩形
    addRectangle({ type, x, y, clickType }) {
      let width = 150 / this.$parent.multiple;
      let height = 80 / this.$parent.multiple;
      let pos_x = clickType == "dbClick" ? x - width / 2 : x;
      let pos_y = clickType == "dbClick" ? y - height / 2 : y;

      let rectNode = new Konva.Rect({
        id: randomString(8),
        type,
        x: pos_x,
        y: pos_y,
        width,
        height: height,
        fill: "",
        stroke: "black",
        strokeWidth: 1,
        dash: [3, 1],
        dashEnabled: false,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
        cornerRadius: type == "RoundRectangle" ? 4 : 0,
        draggable: true,
      });
      this.elementGroup.add(rectNode);
      rectNode.setAttr("parameter", {
        Id: rectNode.id(),
        Name: `${type}${getTypeNextNum(this.elementGroup, type)}`,
        Type: rectNode.attrs.type,
        StartX: pxToMm(rectNode.x(), 2),
        StartY: pxToMm(rectNode.y(), 2),
        Width: pxToMm(rectNode.width() * rectNode.scaleX(), 2),
        Height: pxToMm(rectNode.height() * rectNode.scaleY(), 2),
        Lock: false,
        Rotate: rectNode.rotation(),
        PenWidth: Math.round((rectNode.strokeWidth() / this.magnification) * 10) / 10,
        CornerRadius:
          Math.round((rectNode.cornerRadius() / this.magnification) * 10) / 10,
        Dash: rectNode.dashEnabled(),
        DashStyle: 0, //0实线 1虚线
        Fill: false,
        CopyMirrorImage: false,
      });
      let transformer = KonvaTransformer(getEnabledAnchors(rectNode), rectNode);
      this.elementGroup.add(transformer);
      TransformerListening(this.$parent, rectNode);
      this.designStage.draggable(false);
      if (this.$parent.selectNode) {
        this.$parent.selectNode.draggable(false);
      }
      this.designLayer.draw();
      this.$parent.selectNode = rectNode;
      this.$parent.elementAttr = JSON.parse(JSON.stringify(rectNode.attrs.parameter));
      let newAttrs = getNodeAttrs(rectNode);
      recordHistory(this.$parent, "", newAttrs, "add");
    },
    //添加椭圆
    addEllipse({ type, x, y, clickType }) {
      //椭圆x,y为中心点坐标 radiusX，radiusY为半径长度
      let width = 150 / this.$parent.multiple;
      let height = 80 / this.$parent.multiple;
      let pos_x = clickType == "dbClick" ? x : x + width / 2;
      let pos_y = clickType == "dbClick" ? y : y + height / 2;

      let ellipseNode = new Konva.Ellipse({
        id: randomString(8),
        type,
        x: pos_x,
        y: pos_y,
        width,
        height,
        radiusX: width / 2,
        radiusY: height / 2,
        fill: "",
        stroke: "black",
        strokeWidth: 1,
        dash: [3, 1],
        dashEnabled: false,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
        draggable: true,
      });
      this.elementGroup.add(ellipseNode);
      ellipseNode.setAttr("parameter", {
        Id: ellipseNode.id(),
        Name: `${type}${getTypeNextNum(this.elementGroup, type)}`,
        Type: ellipseNode.attrs.type,
        StartX: pxToMm(ellipseNode.x() - ellipseNode.radiusX() * ellipseNode.scaleX(), 2),
        StartY: pxToMm(ellipseNode.y() - ellipseNode.radiusY() * ellipseNode.scaleY(), 2),
        Width: pxToMm(ellipseNode.width() * ellipseNode.scaleX(), 2),
        Height: pxToMm(ellipseNode.height() * ellipseNode.scaleY(), 2),
        Lock: false,
        Rotate: ellipseNode.rotation(),
        PenWidth: Math.round((ellipseNode.strokeWidth() / this.magnification) * 10) / 10,
        Dash: ellipseNode.dashEnabled(),
        DashStyle: 0, //0实线 1虚线
        Fill: false,
        CopyMirrorImage: false,
      });
      let transformer = KonvaTransformer(getEnabledAnchors(ellipseNode), ellipseNode);
      this.elementGroup.add(transformer);
      TransformerListening(this.$parent, ellipseNode);
      this.designStage.draggable(false);
      if (this.$parent.selectNode) {
        this.$parent.selectNode.draggable(false);
      }
      this.designLayer.draw();
      this.$parent.selectNode = ellipseNode;
      this.$parent.elementAttr = JSON.parse(JSON.stringify(ellipseNode.attrs.parameter));
      let newAttrs = getNodeAttrs(ellipseNode);
      recordHistory(this.$parent, "", newAttrs, "add");
    },
    //添加菱形
    addDiamond({ type, x, y, clickType }) {
      let width = 150 / this.$parent.multiple;
      let height = 80 / this.$parent.multiple;
      let pos_x = clickType == "dbClick" ? x - width / 2 : x;
      let pos_y = clickType == "dbClick" ? y - height / 2 : y;

      let diamondNode = new Konva.Line({
        id: randomString(8),
        type,
        x: pos_x,
        y: pos_y,
        width,
        height,
        points: [0, height / 2, width / 2, 0, width, height / 2, width / 2, height],
        closed: true,
        fill: "",
        stroke: "black",
        strokeWidth: 1,
        dash: [3, 1],
        dashEnabled: false,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
        draggable: true,
      });
      this.elementGroup.add(diamondNode);
      diamondNode.setAttr("parameter", {
        Id: diamondNode.id(),
        Name: `${type}${getTypeNextNum(this.elementGroup, type)}`,
        Type: diamondNode.attrs.type,
        StartX: pxToMm(diamondNode.x(), 2),
        StartY: pxToMm(diamondNode.y(), 2),
        Width: pxToMm(diamondNode.width() * diamondNode.scaleX(), 2),
        Height: pxToMm(diamondNode.height() * diamondNode.scaleY(), 2),
        Lock: false,
        Rotate: diamondNode.rotation(),
        PenWidth: Math.round((diamondNode.strokeWidth() / this.magnification) * 10) / 10,
        Dash: diamondNode.dashEnabled(),
        DashStyle: 0, //0实线 1虚线
        Fill: false,
        CopyMirrorImage: false,
      });
      let transformer = KonvaTransformer(getEnabledAnchors(diamondNode), diamondNode);
      this.elementGroup.add(transformer);
      TransformerListening(this.$parent, diamondNode);
      this.designStage.draggable(false);
      if (this.$parent.selectNode) {
        this.$parent.selectNode.draggable(false);
      }
      this.designLayer.draw();
      this.$parent.selectNode = diamondNode;
      this.$parent.elementAttr = JSON.parse(JSON.stringify(diamondNode.attrs.parameter));
      let newAttrs = getNodeAttrs(diamondNode);
      recordHistory(this.$parent, "", newAttrs, "add");
    },
    //添加三角形
    addTriangle({ type, x, y, clickType }) {
      let width = 150 / this.$parent.multiple;
      let height = 80 / this.$parent.multiple;
      let pos_x = clickType == "dbClick" ? x - width / 2 : x;
      let pos_y = clickType == "dbClick" ? y - height / 2 : y;

      let triangleNode = new Konva.Line({
        id: randomString(8),
        type,
        x: pos_x,
        y: pos_y,
        width,
        height,
        points: [0, height, width / 2, 0, width, height],
        closed: true,
        fill: "",
        stroke: "black",
        strokeWidth: 1,
        dash: [3, 1],
        dashEnabled: false,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
        draggable: true,
      });
      this.elementGroup.add(triangleNode);
      triangleNode.setAttr("parameter", {
        Id: triangleNode.id(),
        Name: `${type}${getTypeNextNum(this.elementGroup, type)}`,
        Type: triangleNode.attrs.type,
        StartX: pxToMm(triangleNode.x(), 2),
        StartY: pxToMm(triangleNode.y(), 2),
        Width: pxToMm(triangleNode.width() * triangleNode.scaleX(), 2),
        Height: pxToMm(triangleNode.height() * triangleNode.scaleY(), 2),
        Lock: false,
        Rotate: triangleNode.rotation(),
        PenWidth: Math.round((triangleNode.strokeWidth() / this.magnification) * 10) / 10,
        Dash: triangleNode.dashEnabled(),
        DashStyle: 0, //0实线 1虚线
        Fill: false,
        MirrorImage: triangleNode.scaleY() < 0 ? true : false,
        CopyMirrorImage: false,
      });
      let transformer = KonvaTransformer(getEnabledAnchors(triangleNode), triangleNode);
      this.elementGroup.add(transformer);
      TransformerListening(this.$parent, triangleNode);
      this.designStage.draggable(false);
      if (this.$parent.selectNode) {
        this.$parent.selectNode.draggable(false);
      }
      this.designLayer.draw();
      this.$parent.selectNode = triangleNode;
      this.$parent.elementAttr = JSON.parse(JSON.stringify(triangleNode.attrs.parameter));
      let newAttrs = getNodeAttrs(triangleNode);
      recordHistory(this.$parent, "", newAttrs, "add");
    },
    //添加表格
    addTable({ type, x, y, clickType }) {
      let strokeWidth = 1;
      let row = 2;
      let column = 2;
      let tableWidth = this.designArea.width() * 0.7;
      let tableHeight = tableWidth * 0.4;
      let pos_x = clickType == "dbClick" ? x - tableWidth / 2 : x;
      let pos_y = clickType == "dbClick" ? y - tableHeight / 2 : y;
      let cellWidth = (tableWidth - strokeWidth * (column + 1)) / column;
      let cellHeight = (tableHeight - strokeWidth * (row + 1)) / row;

      let tableNode = new Konva.Group({
        id: randomString(8),
        type,
        x: pos_x,
        y: pos_y,
        width: tableWidth,
        height: tableHeight,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
        draggable: true,
      });

      let DicLineLocW = []; //列线的坐标（线的左上角） 单位px
      let DicLineLocH = []; //行线的坐标（线的左上角） 单位px

      //绘制横线
      let tableRowLine = new Konva.Group({
        name: "tableRowLine",
      });
      tableNode.add(tableRowLine);
      for (let i = 0; i <= row; i++) {
        let y = i * cellHeight + i * strokeWidth;
        let rowLine = new Konva.Rect({
          name: `rowLine_${i}`,
          x: 0,
          y,
          width: tableWidth,
          height: strokeWidth,
          fill: "#000000",
          hitFunc: (context) => {
            context.beginPath();
            context.rect(0, y, tableWidth, strokeWidth);
            context.closePath();
          },
        });
        tableRowLine.add(rowLine);
        DicLineLocH.push(y);
      }

      //绘制竖线
      let tableColumnLine = new Konva.Group({
        name: "tableColumnLine",
      });
      tableNode.add(tableColumnLine);
      for (let i = 0; i <= column; i++) {
        let x = i * cellWidth + i * strokeWidth;
        let columnLine = new Konva.Rect({
          name: `columnLine_${i}`,
          x,
          y: 0,
          width: strokeWidth,
          height: tableHeight,
          fill: "#000000",
          hitFunc: (context) => {
            context.beginPath();
            context.rect(x, 0, strokeWidth, tableHeight);
            context.closePath();
          },
        });
        tableColumnLine.add(columnLine);
        DicLineLocW.push(x);
      }
      //绘制单元格
      let cellGroup = new Konva.Group({
        name: "cellGroup",
        x: strokeWidth,
        y: strokeWidth,
      });
      tableNode.add(cellGroup);

      let DataSourceList = [];
      let CellWidth = []; //记录每一列的宽度
      let CellHeight = []; //记录每一行的高度
      for (let i = 0; i < row; i++) {
        for (let j = 0; j < column; j++) {
          if (i == 0) {
            CellWidth.push(cellWidth);
          }
          if (j == 0) {
            CellHeight.push(cellHeight);
          }
          let cell = new Konva.Group({
            name: `cell_${i}_${j}`,
            x: j * cellWidth + j * strokeWidth,
            y: i * cellHeight + i * strokeWidth,
            width: cellWidth,
            height: cellHeight,
            pos: [i, j],
            allPos: [[i, j]], //用来记录合并单元格情况
            clipWidth: cellWidth, //可见范围
            clipHeight: cellHeight, //可见范围
          });

          let cellBg = new Konva.Rect({
            name: "cellBg",
            pos: [i, j],
            x: 0,
            y: 0,
            width: cellWidth,
            height: cellHeight,
            fill: "#FFFFFF",
            antiBlack: false,
          });
          cell.add(cellBg);

          let cellText = new Konva.Text({
            name: "cellText",
            pos: [i, j],
            x: 0,
            y: 0,
            width: cellWidth,
            height: cellHeight,
            text: "",
            fontFamily: "阿里巴巴普惠体",
            fontFamilyUrl: "",
            fontSize: 18 / this.$parent.multiple,
            fontStyle: "",
            textDecoration: "",
            align: getTextAlignValue(0),
            verticalAlign: "middle",
            lineHeight: 1,
            letterSpacing: 0,
            fill: "#000000",
            scaleX: 1,
            scaleY: 1,
          });
          cell.setAttr("parameter", {
            AllKeys: cell.attrs.allPos, //存放合并的单元格坐标
            Key: cell.attrs.pos,
            StartX: Math.round((cell.x() / this.magnification) * 100) / 100,
            StartY: Math.round((cell.y() / this.magnification) * 100) / 100,
            Width: Math.round((cell.width() / this.magnification) * 100) / 100,
            Height: Math.round((cell.height() / this.magnification) * 100) / 100,
            FirstValue: cellText.text() ? cellText.text() : "",
            NormalText: cellText.text() ? cellText.text() : "",
            SequenceType: 0,
            InitialValue: "1",
            AddedValue: "",
            Quantity: "",
            ColumnName: "",
            QuerySql: "",
            TableName: "",
            ConnectionStr: "",
            DataSourceType: 0,
            AutoWrap: true,
            AntiBlack: cellBg.attrs.antiBlack,
            MirrorImage: cellText.scaleX() < 0 ? true : false,
            FontFamily: cellText.fontFamily(),
            FontSize: dataPrecision(cellText.fontSize(), 1),
            Bold: cellText.fontStyle().indexOf("bold") > -1 ? true : false,
            Italic: cellText.fontStyle().indexOf("italic") > -1 ? true : false,
            UnderLine: cellText.textDecoration().indexOf("underline") > -1 ? true : false,
            DeleteLine:
              cellText.textDecoration().indexOf("line-through") > -1 ? true : false,
            TextAlign: 0,
            LineHeight: 0,
            LetterSpacing: 0,
            SheetIndex: [-1, -1],
            DataBase: [],
          });
          cell.add(cellText);

          let cellSelect = new Konva.Rect({
            name: "cellSelect",
            pos: [i, j],
            x: 0,
            y: 0,
            width: cellWidth,
            height: cellHeight,
            fill: "#3F80F5",
            opacity: 0.1,
          });
          cellSelect.hide();
          cell.add(cellSelect);
          cellGroup.add(cell);
          DataSourceList.push(cell.attrs.parameter);
          if (i == 0 && j == 0) {
            cellSelect.show();
            cellSelect.moveToTop();
          }
        }
      }
      tableNode.setAttr("parameter", {
        Id: tableNode.id(),
        Name: `${type}${getTypeNextNum(this.elementGroup, type)}`,
        Type: tableNode.attrs.type,
        StartX: pxToMm(tableNode.x(), 2),
        StartY: pxToMm(tableNode.y(), 2),
        Width: pxToMm(tableNode.width() * tableNode.scaleX(), 2),
        Height: pxToMm(tableNode.height() * tableNode.scaleY(), 2),
        Lock: false,
        Rotate: tableNode.rotation(),
        DataSourceList, //记录单元格数据
        DicLineLocW, //记录列线
        DicLineLocH, //记录行线
        Row: row, //行数
        Column: column, //列数
        CellWidth, //记录每列单元格的宽度
        CellHeight, //记录每行单元格的高度
        cellMultiple: false, //是否开启多选
        mulCellPos: [], //记录多选的单元格的坐标
        currentPos: [0, 0], //当前选中单元格的坐标
        dataSourceIndex: 0, //当前选中单元格的下标
        PenWidth: Math.round((strokeWidth / this.magnification) * 10) / 10, //边框粗细
      });
      this.elementGroup.add(tableNode);
      let transformer = KonvaTransformer(getEnabledAnchors(tableNode), tableNode);
      TransformerListening(this.$parent, tableNode);
      this.elementGroup.add(transformer);
      this.designStage.draggable(false);
      if (this.$parent.selectNode) {
        this.$parent.selectNode.draggable(false);
      }
      this.designLayer.draw();

      this.$parent.selectNode = tableNode;
      this.$parent.elementAttr = JSON.parse(JSON.stringify(tableNode.attrs.parameter));
      let newAttrs = getNodeAttrs(tableNode);
      recordHistory(this.$parent, "", newAttrs, "add");
    },
  },
};
</script>
