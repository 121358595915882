<template>
	<div class="memberDia" v-show="showUpgradeMember" @click="closeUpgradeMember">
		<div class="member-nav" @click.stop="">
			<CloseOutlined class="close-btn" @click.stop="closeUpgradeMember" />
			<div class="member-head">
				<div class="member-head-left">
					<div>
						<img v-if="userData.head_pic" class="member-avatar" :src="userData.head_pic" alt="" />
						<img v-else class="member-avatar" src="../assets/avatar.png" alt="" />
					</div>
					<div>
						<div class="member-nick">
							<span>{{ $t(`label.label10`) }}：{{ nickname }}</span>
							<img class="member-jiaobiao" :src="userMemberInfo.status == 1 ? require('../../static/icon/VIPjiaobiao-active.png') : require('../../static/icon/VIPjiaobiao.png')" alt="" />
						</div>
						<div class="member-tip">
							{{ userMemberInfo.status != 1 ? $t(`label.label52`) : userMemberInfo.new_expire_time }}
						</div>
					</div>
				</div>
				<div class="member-exchange" @click="showExchange()">
					{{ $t(`button.exchange`) }}
				</div>
			</div>
			<div class="member-type">
				<div
					class="member-item"
					v-for="(item, index) in list"
					:key="index"
					@click="handleClick(index)"
					:style="{
						border: selectIndex == index ? '1px solid #E8B65C' : '1px solid #D6DCE6',
						background: selectIndex == index ? '#FFF5E8' : '#FFFFFF',
						boxShadow: selectIndex == index ? '0px 4px 8px 0px rgba(129, 114, 87, 0.24)' : 'none'
					}">
					<div class="member-recommend" v-if="item.is_recommend == 1 && item.is_preference == 0">
						{{ $t(`label.label56`) }}
					</div>
					<div class="member-preference" v-if="item.is_preference == 1">
						{{ $t(`label.label66`) }}
					</div>
					<div>{{ item.the_term + $t(`label.label61`) }}</div>
					<div class="new-price">
						<span class="pay-currency">{{ item.currency_symbol }}</span
						>{{ item.discount_price }}
					</div>
					<div class="old-price">
						<span class="pay-currency">{{ item.currency_symbol }}</span
						>{{ item.original_price }}
					</div>
				</div>
			</div>
			<div class="pay-box" v-if="selectIndex > -1 && orderId">
				<img class="pay-code" :src="codeImage" />
				<div class="pay-info">
					<div>{{ $t(`label.label53`) }}</div>
					<div class="pay-type">
						<img src="../../static/icon/wepay.png" />
						<span>{{ $t(`label.label54`) }}</span>
					</div>
					<div class="pay-price"><span class="pay-currency">￥</span>{{ selectPrice }}</div>
				</div>
			</div>
			<div class="privilege-box" v-if="privilege.length > 0">
				<p class="privilege-title">{{ $t(`label.label55`) }}{{ privilege.length }}{{ $t(`label.label63`) }}</p>
				<a-row>
					<a-col :span="4" v-for="(item, index) in privilege" :key="index" style="display: flex; flex-direction: column; align-items: center; padding: 10px 0">
						<img :src="item.ico" class="privilege-img" />
						<a-tooltip placement="bottom">
							<template #title>
								<span>{{ item.name }}</span>
							</template>
							<span class="privilege-text">{{ item.name }}</span>
						</a-tooltip>
					</a-col>
				</a-row>
			</div>
		</div>
		<ExchangeDia ref="exchangeRef" @refresh="handleRefresh" @click.stop="" />
	</div>
</template>
<script>
	import { defineComponent, onMounted, getCurrentInstance, reactive, toRefs, watch, onUnmounted } from 'vue';
	import { getSign } from '@/utils/signature';
	import { message } from 'ant-design-vue';
	import { useStore } from 'vuex';
	import { formatTime } from '@/utils/formatTime';
	import ExchangeDia from '@/components/ExchangeDia.vue';
	import { CloseOutlined } from '@ant-design/icons-vue';
	export default defineComponent({
		components: {
			ExchangeDia,
			CloseOutlined
		},
		setup(props, { emit }) {
			const store = useStore();
			const { proxy } = getCurrentInstance();
			let globalProperties = getCurrentInstance().appContext.config.globalProperties;
			const axios = globalProperties.$axios;
			const axioshanma = globalProperties.$axioshanma;
			const userInfo = JSON.parse(localStorage.getItem('userInfo'));
			const state = reactive({
				userData: [],
				nickname: '',
				selectIndex: -1,
				selectPrice: '',
				isMember: false,
				list: [],
				privilege: [],
				userMemberInfo: [],
				codeImage: '',
				orderId: '',
				StatusTimer: null,
				exchangeRef: null,
				showUpgradeMember: false
			});

			//打开会员升级弹窗
			const show = () => {
				state.showUpgradeMember = true;
				getConfigIns();
				getList();
				getUserInfo();
				getMemberUser();
			};

			//获取用户信息
			const getUserInfo = () => {
				var timestamp = Date.parse(new Date());
				timestamp = timestamp / 1000;
				let param = {
					timestamp,
					fun: 'hmUserInfo',
					requestType: 'User',
					appId: 'a0f0e723-f2bb-11e8-bc50-00163e0c395e',
					appKey: 'a0f12ce4-f2bb-11e8-bc50-00163e0c395e',
					userId: userInfo.userid
				};
				var signature = getSign(param);
				let obj = JSON.parse(JSON.stringify(param));
				obj.signature = signature;
				axios('/api/apiservice/apiroute', obj).then(res => {
					state.userData = res;
					if (res.nickname != '') {
						state.nickname = res.nickname;
					} else {
						if (userInfo.phone != '') {
							state.nickname = userInfo.phone;
						} else if (userInfo.email != '') {
							state.nickname = userInfo.email;
						}
					}
				});
			};
			const getMemberUser = () => {
				axioshanma('/api/tem.app_member/getMemberUser', {
					user_id: userInfo.userid
				}).then(res => {
					state.userMemberInfo = res;
					state.userMemberInfo.new_expire_time = formatTime(state.userMemberInfo.expire_time);
					store.commit('setMemberInfo', state.userMemberInfo);
				});
			};

			//获取会员类型列表
			const getList = () => {
				axioshanma('/api/tem.app_member/getMemberList', { app: 3 }).then(res => {
					state.selectIndex = 0;
					state.list = res;
					state.selectPrice = state.list[0].discount_price;
					getPayCode();
				});
			};

			//获取会员权益列表
			const getConfigIns = () => {
				axioshanma('/api/tem.app_member/configIns').then(res => {
					state.privilege = res;
				});
			};

			// 获取支付二维码
			const getPayCode = () => {
				axioshanma('/api/tem.app_member/payScanCode', {
					user_id: userInfo.userid,
					m_id: state.list[state.selectIndex].m_id
				}).then(res => {
					state.codeImage = res.image;
					state.orderId = res.o_id;
					clearInterval(state.StatusTimer);
					state.StatusTimer = null;
					state.StatusTimer = setInterval(getStatusOrder, 3000);
				});
			};

			const handleClick = index => {
				state.selectIndex = index;
				state.selectPrice = state.list[index].discount_price;
				getPayCode();
			};

			const getStatusOrder = () => {
				axioshanma(
					'/api/tem.app_member/getStatusOrder',
					{
						user_id: userInfo.userid,
						o_id: state.orderId
					},
					{
						custom: {
							loading: false
						}
					}
				).then(res => {
					if (res.status == 2) {
						message.success(proxy.$t(`tips.tip94`));
						clearInterval(state.StatusTimer);
						getMemberUser();
					} else if (res.status == 3) {
						message.error(proxy.$t(`tips.tip95`));
						clearInterval(state.StatusTimer);
					}
				});
			};

			const showExchange = () => {
				state.exchangeRef.show();
			};

			const handleRefresh = () => {
				getUserInfo();
				getMemberUser();
			};

			//关闭会员升级弹窗
			const closeUpgradeMember = () => {
				state.showUpgradeMember = false;
				clearInterval(state.StatusTimer);
				state.StatusTimer = null;
			};

      onUnmounted(() => {
				clearInterval(state.StatusTimer);
				state.StatusTimer = null;
			});

			return {
				...toRefs(state),
				handleClick,
				showExchange,
				handleRefresh,
				formatTime,
				show,
				closeUpgradeMember
			};
		}
	});
</script>
<style scoped>
	.memberDia {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;
		background-color: rgba(0, 0, 0, 0.4);
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.member-nav {
		width: 817px;
		height: 820px;
		background: #ffffff;
		border-radius: 8px;
		position: relative;
		padding: 40px;
		overflow: hidden;
		overflow-y: auto;
	}
	.close-btn {
		position: absolute;
		right: 30px;
		top: 30px;
		font-size: 20px;
		z-index: 99;
		color: #7b89a1;
	}
	.member-head {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.member-head-left {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.member-avatar {
		flex-shrink: 0;
		width: 88px;
		height: 88px;
		border-radius: 50%;
		border: 1px solid #dedede;
		object-fit: cover;
	}
	.member-jiaobiao {
		width: 24px;
		height: 24px;
		margin-left: 8px;
	}
	.member-nick {
		flex-shrink: 0;
		margin-left: 16px;
		height: 32px;
		font-size: 20px;
		font-weight: 500;
		color: #1a1d21;
		line-height: 32px;
		display: flex;
		align-items: center;
	}
	.member-tip {
		margin-left: 16px;
		margin-top: 8px;
		height: 24px;
		font-size: 16px;
		color: #864510;
		line-height: 24px;
	}
	.member-exchange {
		min-width: 74px;
		height: 32px;
		background: linear-gradient(97deg, #fce8c9 0%, #fccf8a 100%);
		border-radius: 18px;
		padding: 0 16px;
		font-size: 14px;
		color: #864510;
		line-height: 32px;
		cursor: pointer;
	}
	.member-type {
		margin-top: 32px;
		margin-bottom: 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.member-item {
		list-style: none;
		width: calc(100% / 3 - 16px);
		height: 180px;
		background: #ffffff;
		border-radius: 8px;
		border: 1px solid #d6dce6;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 20px;
		font-weight: 500;
		color: #1a1d21;
		line-height: 28px;
		padding: 28px 0;
		position: relative;
		cursor: pointer;
	}
	.member-recommend {
		position: absolute;
		top: 0;
		left: 0;
		min-width: 49px;
		height: 24px;
		background: linear-gradient(97deg, #fce8c9 0%, #fccf8a 100%);
		border-radius: 8px 0px 8px 0px;
		font-size: 14px;
		font-weight: 500;
		color: #864510;
		line-height: 24px;
		text-align: center;
		padding: 0 5px;
	}
	.member-preference {
		position: absolute;
		top: 0;
		left: 0;
		min-width: 60px;
		height: 24px;
		background: linear-gradient(90deg, #ff7b67 0%, #ff383b 100%);
		border-radius: 8px 0px 8px 0px;
		font-size: 14px;
		font-weight: 500;
		color: #ffffff;
		line-height: 24px;
		text-align: center;
		padding: 0 5px;
	}
	.new-price {
		height: 45px;
		font-size: 32px;
		font-weight: 500;
		color: #e8b65c;
		line-height: 45px;
		margin: 16px 0;
	}
	.pay-currency {
		font-size: 18px;
	}
	.old-price {
		height: 20px;
		font-size: 18px;
		color: #7b89a1;
		line-height: 20px;
		text-decoration: line-through;
	}
	.old-price .pay-currency {
		font-size: 14px;
	}
	.pay-box {
		display: flex;
	}
	.pay-code {
		width: 96px;
		height: 96px;
	}
	.pay-info {
		margin-left: 13px;
		font-size: 14px;
		color: #535d6c;
		line-height: 20px;
    margin-top: 5px;
	}
	.pay-type {
		font-size: 12px;
		color: #7b89a1;
		line-height: 12px;
		display: flex;
		align-items: center;
		margin-top: 6px;
	}
	.pay-type img {
		width: 16px;
		height: 16px;
		margin-right: 4px;
	}
	.pay-price {
		height: 45px;
		font-size: 32px;
		font-weight: 500;
		color: #e8b65c;
		line-height: 45px;
		margin-top: 11px;
	}
	.privilege-box {
		margin-top: 40px;
	}
	.privilege-title {
		height: 25px;
		font-size: 18px;
		font-weight: 500;
		color: #535d6c;
		line-height: 25px;
	}
	.privilege-img {
		width: 57px;
		height: 57px;
	}
	.privilege-text {
		width: 100%;
		padding: 0 5px;
		text-align: center;
		height: 20px;
		font-size: 14px;
		color: #1a1d21;
		line-height: 20px;
		margin-top: 8px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	@media only screen and (max-width: 1280px) {
		.member-nav {
			width: 573px;
			height: 588px;
			padding: 32px 26px;
		}
    .close-btn{
      right: 26px;
      top: 12px;
      font-size: 14px;
    }
		.member-avatar {
			width: 49px;
			height: 49px;
		}
		.member-jiaobiao {
			width: 16px;
			height: 16px;
			margin-left: 5px;
		}
		.member-nick {
			margin-left: 10px;
			height: 21px;
			font-size: 16px;
			line-height: 24px;
		}
		.member-tip {
			margin-left: 10px;
			margin-top: 5px;
			height: 16px;
			font-size: 10px;
			line-height: 16px;
		}

		.member-type {
			margin-top: 21px;
			margin-bottom: 26px;
		}
		.member-item {
			height: 120px;
			font-size: 14px;
			line-height: 18px;
			padding: 18px 0;
		}
		.member-recommend {
			min-width: 32px;
			height: 16px;
			font-size: 8px;
			line-height: 16px;
		}
		.member-preference {
			min-width: 42px;
			height: 16px;
			font-size: 8px;
			line-height: 16px;
		}
		.new-price {
			height: 30px;
			font-size: 21px;
			line-height: 30px;
			margin: 10px 0;
		}
		.pay-currency {
			font-size: 14px;
		}
		.old-price {
			height: 13px;
			font-size: 14px;
			line-height: 13px;
		}
		.old-price .pay-currency {
			font-size: 14px;
		}

		.pay-info {
			font-size: 14px;
			line-height: 13px;
		}
		.pay-price {
			height: 30px;
			font-size: 21px;
			line-height: 30px;
			margin-top: 7px;
		}
		.privilege-box {
			margin-top: 26px;
		}
		.privilege-title {
			height: 16px;
			font-size: 14px;
			line-height: 16px;
		}
		.privilege-img {
			width: 38px;
			height: 38px;
		}
		.privilege-text {
			height: 15px;
			font-size: 10px;
			line-height: 12px;
			margin-top: 5px;
		}
	}
</style>
