<template>
  <div class="edit-print" id="editLabel" @mousedown="handleEditMousedown">
    <ToolArea
      ref="toolAreaRef"
      :designStage="designStage"
      :designArea="designArea"
      :designLayer="designLayer"
      :elementGroup="elementGroup"
      :labelAttr="labelAttr"
      :elementAttr="elementAttr"
      :historyList="historyList"
      :historyIndex="historyIndex"
      @mousedown.stop.prevent="
        $refs.toolAreaRef.closeRightMenu(), handleDestroyTextarea()
      "
    />

    <ElementArea
      ref="elementAreaRef"
      :designStage="designStage"
      :designArea="designArea"
      :designLayer="designLayer"
      :elementGroup="elementGroup"
      @mousedown.stop.prevent="
        $refs.toolAreaRef.closeRightMenu(), handleDestroyTextarea()
      "
    />

    <!-- 画布操作区域 -->
    <div class="edit-print-box" id="editBox" @contextmenu.prevent="">
      <div id="desgin_container" @click.stop=""></div>
    </div>

    <AttrsDia
      v-if="showAttrPanel"
      ref="attrsDiaRef"
      :elementAttr="elementAttr"
      :designArea="designArea"
      :designLayer="designLayer"
      @click="$refs.toolAreaRef.closeRightMenu()"
    />

    <CreateLabel ref="createLabelRef" @set="handleSetLabel" />

    <PrintLabel ref="PrintLabelRef" />
    <EditGuide v-if="showEditGuide" @close="closeEditGuide" />
    <MemberDia ref="MemberDiaRef" />
    <CloudImgDia ref="cloudImgDiaRef" @update="handleUpdateImg" />
  </div>
</template>
<script>
import MemberDia from "@/components/MemberDia.vue";
import { message } from "ant-design-vue";
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
import axios from "axios/dist/axios.min.js";
import PrintLabel from "@/components/PrintLabel.vue";
import EditGuide from "@/components/EditGuide.vue";
import CloudImgDia from "@/components/CloudImgDia.vue";
import { mapGetters } from "vuex";
import "./css/edit.css";
import "./css/edit-small.css";
import {
  getTextAlignValue,
  booleanChange,
  setFontStyle,
  getCodeType,
  getBarCodeTypeList,
  getQrCodeTypeList,
  getFontFamily,
  getGenerateCodeImg,
  changeType,
  getCellRang,
  sumData,
  pxToMm,
  mmToPx,
  getEclevelList,
  getNowDateOrTime,
  getTimeFormatList,
  dealTimeOffset,
  formatDate,
  dataPrecision,
  setSystemTime,
  removeTextarea,
  drawMirrorNode,
} from "./js/common.js";
import {
  changeOriginToCenter,
  changeOriginToTopleft,
  TransformerListening,
  destroyTransformer,
  destroyTableCellSelect,
  KonvaTransformer,
} from "./js/transformerStyle";
import { drawSketchRuler, sketchRulerMove, sketchRulerReset } from "./js/sketchRuler.js";
import { drawGridLayer, gridMoveAndScale } from "./js/gridLayer.js";
import AttrsDia from "./components/AttrsDia.vue";
import ElementArea from "./components/ElementArea.vue";
import ToolArea from "./components/ToolArea.vue";
import CreateLabel from "@/components/CreateLabel.vue";

export default {
  components: {
    PrintLabel,
    EditGuide,
    MemberDia,
    AttrsDia,
    ElementArea,
    CloudImgDia,
    ToolArea,
    CreateLabel,
  },
  data() {
    return {
      userMemberInfo: "", //记录用户会员信息
      VUE_APP_VERSION: process.env.VUE_APP_VERSION,
      origionCodeImage: "", //用来存储错误二维码的底图
      showEditGuide: true, //显示新手指导
      configuration: [], //配置文件大小
      loadingInstance: "",
      //七牛云token
      postData: {
        token: "",
        url: "",
      },
      imgHeader: "data:image/png;base64,",
      renameLabel: {
        renameVisible: false,
        renameType: "label",
        renameId: "",
        renameValue: "",
      },
      userInfo: "",
      language: this.$i18n.locale,
      userClassList: [], //个人模板文件夹
      designStage: {},
      designLayer: {},
      designArea: {},
      elementGroup: {},
      sketchRulerLayer: {}, //标尺图层
      gridLayer: {}, //网格图层
      mmToPoint: 3.96, // 1mm约等于3.96点 (31.7/8)  (203dpi(pc端采用203dpi进行计算)) 用于与pc端的数据转换
      magnification: 3.78, //1mm 约等于3.78px（96dpi）   像素 = 毫米 * ( 分辨率 / 25.4)
      UnitConversion: 1, //单位毫米时为1，1英寸等于25.4毫米，英寸时为25.4, 将英寸换算成毫米
      MeasureUnit: "Mm", //标签单位
      multiple: 1,
      canvasWidth: 0,
      canvasHeight: 0,
      labelAttr: [],
      labelStatus: "add", //标签状态：新建/编辑
      selectNode: "",
      flag: true,
      isRestSize: true, //是否允许编辑标签尺寸
      historyIndex: -1,
      historyList: [],
      elementAttr: "",
      showAttrPanel: false,
      canvasArr: [], //标签画布数组
      canvasIndex: 0,
      labelJsonData: "",
      isDraw: false,
      systemTimer: null, //系统时间定时器
      canvasLock: false,
    };
  },
  computed: {
    ...mapGetters(["getMemberInfo"]),
  },
  created() {
    this.configuration = JSON.parse(localStorage.getItem("configuration"));
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
  },

  mounted () {
    window.addEventListener("beforeunload", this.beforeunloadTip);
    this.getQiniuToken();
    this.getMemberUser();
    this.getUserClassList();

    this.canvasLock = localStorage.getItem('canvasLock') && localStorage.getItem('canvasLock') == 'true' ? true : false

    //第一次使用编辑器，显示新手引导
    let firstEdit = localStorage.getItem("firstEdit");
    if (firstEdit == "true") {
      this.showEditGuide = true;
    } else {
      this.showEditGuide = false;
    }

    let editBox = document.getElementById("editBox");
    this.$nextTick(() => {
      this.canvasWidth = editBox.offsetWidth;
      this.canvasHeight = editBox.offsetHeight;
      this.designStage = new Konva.Stage({
        id: "stage",
        container: "desgin_container",
        width: this.canvasWidth,
        height: this.canvasHeight,
      });

      this.designLayer = new Konva.Layer();
      this.designStage.add(this.designLayer);
      this.designLayer.draw();

      if (this.$route.query.labelAttr) {
        this.labelStatus = this.$route.query.labelStatus;
        this.labelAttr = JSON.parse(decodeURIComponent(this.$route.query.labelAttr));
        console.log("画布信息：", this.labelAttr);

        if (this.labelStatus == "edit") {
          //编辑标签
          localStorage.removeItem("labelBgImgInfo");
          this.OpenLabel();
        } else {
          //新建标签
          this.labelAttr.unit = this.MeasureUnit = this.labelAttr.unit;
          this.labelAttr.unit = this.labelAttr.unit.toLowerCase();
          if (this.MeasureUnit.toLowerCase() == "inch") {
            this.UnitConversion = 25.4;
          } else {
            this.UnitConversion = 1;
          }
          //获取背景图片
          let labelBgImgInfo = localStorage.getItem("labelBgImgInfo");
          if (labelBgImgInfo) {
            labelBgImgInfo = JSON.parse(labelBgImgInfo);
            if (labelBgImgInfo.name == this.labelAttr.name) {
              this.labelAttr.background = labelBgImgInfo.background;
            } else {
              localStorage.removeItem("labelBgImgInfo");
            }
          }
          //创建画布
          this.editInit();
        }
      }
    });
  },

  watch: {
    elementAttr() {
      if (!this.elementAttr || JSON.stringify(this.elementAttr) === "{}") {
        this.showAttrPanel = false;
      } else {
        this.showAttrPanel = true;
      }
    },
    //监听会员信息变化
    getMemberInfo(val) {
      this.userMemberInfo = val;
    },
  },

  methods: {
    beforeunloadTip(e) {
      if (this.elementGroup.children.length > 0 && this.isDraw) {
        e = e || window.event;
        e.returnValue = "内容未保存";
        return e;
      }
    },
    handleEditMousedown() {
      if (this.$refs.toolAreaRef) {
        this.$refs.toolAreaRef.showShortcutKey = false;
      }
      if (this.$refs.elementAreaRef) {
        this.$refs.elementAreaRef.showIcons = false;
        this.$refs.elementAreaRef.showRects = false;
      }
      this.handleDestroyTextarea();
    },
    //销毁文本输入框
    handleDestroyTextarea() {
      if (this.selectNode) {
        if (this.selectNode.attrs.type == "WinText") {
          removeTextarea(this);
        }
      }
    },
    //获取用户会员信息
    getMemberUser() {
      this.$axioshanma("/api/tem.app_member/getMemberUser", {
        user_id: this.userInfo.userid,
      }).then((res) => {
        this.userMemberInfo = res;
        this.$store.commit("setMemberInfo", this.userMemberInfo);
      });
    },
    //获取模板默认文件夹的ID
    getUserClassList() {
      this.$axioshanma("/api/tem.app_public/getUserClassList", {
        user_id: this.userInfo.userid,
      }).then((res) => {
        this.userClassList = res;
        if (this.labelStatus == "add" || this.labelStatus == "common") {
          this.labelAttr.parent_id = this.userClassList[0].id;
        }
      });
    },
    //获取七牛云token
    getQiniuToken() {
      //获取七牛云token
      let data = {
        bucket: this.VUE_APP_VERSION == "hprt" ? "hm-hprt" : "na-hprt",
      };
      this.$axioshanma("/api/tem.app_shared/getToken", data)
        .then((res) => {
          this.postData.token = res.data;
          this.postData.url = res.url;
        })
        .catch(() => {});
    },
    //画布初始化
    editInit() {
      let width = this.labelAttr.width * this.UnitConversion * this.magnification;
      let height = this.labelAttr.height * this.UnitConversion * this.magnification;
      let scaleX = (this.canvasWidth * 0.6) / width;
      let scaleY = (this.canvasHeight * 0.6) / height;
      let multiple = scaleX < scaleY ? scaleX : scaleY;
      this.multiple = Math.round(multiple * 10) / 10;

      //元素属性框的宽度+边距
      let clientWidth = document.body.clientWidth;
      let attrBoxWidth = clientWidth <= 1280 ? 320 : 476;
      let x = -(width - (this.canvasWidth - attrBoxWidth)) / 2 + width / 2;
      let y = -(height - this.canvasHeight) / 2 + height / 2;

      x = x < 50 + (width * this.multiple) / 2 ? 50 + (width * this.multiple) / 2 : x;

      this.designArea = new Konva.Group({
        id: "designArea",
        x,
        y,
        offsetX: width / 2,
        offsetY: height / 2,
        width,
        height,
        scaleX: this.multiple,
        scaleY: this.multiple,
        draggable: this.canvasLock ? false : true,
      });

      let background = new Konva.Rect({
        id: "background",
        x: 0,
        y: 0,
        width,
        height,
        fill: "#fff",
      });
      this.designArea.add(background);

      let backgroundCanvas = new Konva.Image({
        id: "backgroundCanvas",
        x: 0,
        y: 0,
        width,
        height,
        image: "",
        imageSrc: "",
      });
      this.designArea.add(backgroundCanvas);

      let backgroundImg = new Konva.Image({
        id: "backgroundImg",
        x: 0,
        y: 0,
        width,
        height,
        image: "",
        imageSrc: "",
      });
      this.designArea.add(backgroundImg);

      if (this.labelAttr.background) {
        let image = new Image();
        image.setAttribute("crossOrigin", "Anonymous");
        image.onload = (e) => {
          backgroundImg.image(image);
          backgroundImg.attrs.imageSrc = this.imgHeader + this.labelAttr.background;
          background.fill("");
          this.designLayer.draw();
        };
        image.src = this.imgHeader + this.labelAttr.background;
      }

      this.elementGroup = new Konva.Group({
        id: "elementGroup",
        x: 0,
        y: 0,
        width,
        height,
      });
      this.designArea.add(this.elementGroup);

      this.designLayer.add(this.designArea);
      //绘制标尺
      drawSketchRuler(this);
      //绘制网格
      drawGridLayer(this);
      this.designLayer.draw();
      console.log(this.designLayer);
      setSystemTime(this);
      this.layerListening();
      //监听窗口变化，自适应
      window.addEventListener("resize", this.fitStageIntoParentContainer);
    },
    //元素监听，事件处理
    layerListening() {
      let isTouchMove = false;
      let isFrameSelect = false;
      let frameArea = null; //框选元素
      let startX, startY;

      const handleMouseStart = (evt) => {
        // console.log(evt)
        // buttons 1为鼠标左键，2为鼠标右键
        let buttons = evt.evt.buttons;
        let id = evt.target.id();
        if (buttons == 2) {
          //显示右键菜单
          this.$refs.toolAreaRef.isShowMenu = true;
          this.$refs.toolAreaRef.isShowAlign = false;
          this.$refs.toolAreaRef.isShowMenu = true;
          //计算右键菜单的显示位置
          let clientWidth = document.body.clientWidth;
          let attrBoxWidth = clientWidth <= 1280 ? 320 : 480;
          clientWidth = this.selectNode ? clientWidth - attrBoxWidth : clientWidth;
          let clientHeight = this.canvasHeight;

          this.$nextTick(() => {
            let rightMenuBox = document.getElementById("rightMenu");
            let boxWidth = rightMenuBox.offsetWidth;
            let boxHeight = rightMenuBox.offsetHeight;

            let clientX = evt.evt.clientX;
            let clientY = evt.evt.clientY;

            if (clientY + boxHeight > clientHeight) {
              this.$refs.toolAreaRef.rightMenu.posY = clientY - boxHeight - 5;
            } else {
              this.$refs.toolAreaRef.rightMenu.posY = clientY + 5;
            }

            if (clientX + boxWidth > clientWidth) {
              this.$refs.toolAreaRef.rightMenu.posX = clientX - boxWidth - 5;
            } else {
              this.$refs.toolAreaRef.rightMenu.posX = clientX + 5;
            }
          });

          return;
        }
        if (this.$refs.toolAreaRef.isShowMenu) {
          this.$refs.toolAreaRef.isShowMenu = false;
        }
        if (
          id == "background" ||
          id == "backgroundImg" ||
          id == "backgroundCanvas" ||
          id == "stage"
        ) {
          if (!this.$refs.toolAreaRef.multipleSelection) {
            //非多选情况下
            destroyTableCellSelect(this.designLayer);
            destroyTransformer(this.designLayer);
            if (this.selectNode) {
              if (this.selectNode.attrs.type == "WinText") {
                removeTextarea(this);
              }
              this.selectNode.draggable(false);
              if (this.selectNode.attrs.type == "Table") {
                this.selectNode.attrs.parameter.mulCellPos = [];
                this.selectNode.attrs.parameter.cellMultiple = false;
              }
            }
            this.selectNode = "";
            this.elementAttr = {};
            this.designLayer.draw();
          } else {
            destroyTableCellSelect(this.designLayer);
            destroyTransformer(this.designLayer);
            this.$refs.toolAreaRef.multipleSelection = false;
            this.$refs.toolAreaRef.selectionElement = [];
          }

          if (!this.canvasLock) {
            isTouchMove = true;
            startX = evt.evt.clientX - this.designArea.x();
            startY = evt.evt.clientY - this.designArea.y();
          } else {
            //画布锁定 允许框选元素
            isFrameSelect = true;
            let editBox = document.getElementById("editBox");
            let offsetTop = editBox.offsetTop;
            let offsetLeft = editBox.offsetLeft;
            let designArea_x =
              this.designArea.x() -
              (this.designArea.width() * this.designArea.scaleX()) / 2;
            let designArea_y =
              this.designArea.y() -
              (this.designArea.height() * this.designArea.scaleY()) / 2;
            startX = evt.evt.clientX;
            startY = evt.evt.clientY;
            frameArea = new Konva.Line({
              id: "frameArea",
              x:
                (evt.evt.clientX - (offsetLeft + designArea_x)) /
                this.designArea.scaleX(),
              y:
                (evt.evt.clientY - (offsetTop + designArea_y)) / this.designArea.scaleY(),
              points: [0, 0, 0, 0, 0, 0, 0, 0],
              strokeWidth: 1 / this.designArea.scaleX(),
              stroke: "#003591",
              closed: true,
              dash: [2, 2],
            });
            this.elementGroup.add(frameArea);
            this.designLayer.batchDraw();
          }
        }
      };

      const handleMouseMove = (evt) => {
        //画布锁定
        if (this.canvasLock) {
          if (isFrameSelect) {
            let points = frameArea.points();
            let w = (evt.evt.clientX - startX) / this.designArea.scaleX();
            let h = (evt.evt.clientY - startY) / this.designArea.scaleY();
            points[2] = w;
            points[3] = points[1];

            points[4] = w;
            points[5] = h;

            points[6] = points[0];
            points[7] = h;
            frameArea.points(points);
            frameArea.width(Math.abs(w));
            frameArea.height(Math.abs(h));
            this.designLayer.batchDraw();
          }
          return;
        }
        if (!isTouchMove) return;
        this.designArea.x(evt.evt.clientX - startX);
        this.designArea.y(evt.evt.clientY - startY);
        sketchRulerMove(this);
        gridMoveAndScale(this);
        this.designLayer.batchDraw();
      };

      const handleMouseEnd = async (evt) => {
        isTouchMove = false;
        if (isFrameSelect) {
          if (frameArea) {
            // 查找框选区域内的元素
            await this.findFrameAreaNode(frameArea);
            frameArea.remove();
          }
        }
        isFrameSelect = false;
        frameArea = null;
      };

      // 绑定事件监听器
      this.designStage.on("mousedown", handleMouseStart);
      this.designStage.on("mousemove", handleMouseMove);
      this.designStage.on("mouseup", handleMouseEnd);
      this.designArea.on("dragmove", (evt) => {
        sketchRulerMove(this);
        gridMoveAndScale(this);
      });
    },
    // 查找框选区域内的元素
    findFrameAreaNode(frameArea) {
      return new Promise((resolve) => {
        //计算框选范围
        let points = frameArea.points();
        let startX = points[2] < 0 ? frameArea.x() - frameArea.width() : frameArea.x();
        let startY = points[5] < 0 ? frameArea.y() - frameArea.height() : frameArea.y();

        this.elementGroup.children.forEach((node) => {
          if (node.id() != "frameArea") {
            let type = node.attrs.type;
            let node_startX = node.x();
            let node_startY = node.y();
            if (type == "Ellipse") {
              node_startX = node.x() - node.radiusX() * node.scaleX();
              node_startY = node.y() - node.radiusY() * node.scaleY();
            }
            let node_width = 0;
            let node_height = 0;
            if (type == "Line" || type == "LineHorizontal" || type == "LineVertical") {
              let points = node.points();
              node_width = Math.abs((points[2] - points[0]) * node.scaleX());
              node_height = Math.abs((points[3] - points[1]) * node.scaleY());
            } else {
              node_width = node.width() * node.scaleX();
              node_height = node.height() * node.scaleY();
            }

            //求组件中心点与多选框中心点水平方向的距离
            let Lx = Math.abs(
              startX + frameArea.width() / 2 - (node_startX + node_width / 2)
            );
            //求组件中心点与多选框中心点垂直方向的距离
            let Ly = Math.abs(
              startY + frameArea.height() / 2 - (node_startY + node_height / 2)
            );
            if (
              Lx <= (frameArea.width() + node_width) / 2 &&
              Ly <= (frameArea.height() + node_height) / 2
            ) {
              if (!this.$refs.toolAreaRef.multipleSelection) {
                this.$refs.toolAreaRef.multipleSelection = true;
              }
              if (node.attrs.parameter.Lock) {
                node.draggable(false);
              } else {
                node.draggable(true);
              }

              //过滤掉画布镜像元素
              let isMirrorNode = node.attrs.mirrorNode 
              if (!isMirrorNode) {
                this.$refs.toolAreaRef.selectionElement.push(node);
              }
              let transformer = KonvaTransformer([], node);
              this.elementGroup.add(transformer);
            }
          }
        });
        resolve();
      });
    },
    //监听窗口缩放，自适应
    fitStageIntoParentContainer() {
      if (this.$refs.toolAreaRef) {
        this.$refs.toolAreaRef.closeRightMenu();
      }
      let editBox = document.getElementById("editBox");
      this.canvasWidth = editBox.offsetWidth;
      this.canvasHeight = editBox.offsetHeight;

      let scaleX = (this.canvasWidth * 0.6) / this.designArea.width();
      let scaleY = (this.canvasHeight * 0.6) / this.designArea.height();
      let multiple = scaleX < scaleY ? scaleX : scaleY;
      this.multiple = Math.round(multiple * 10) / 10;
      //元素属性框的宽度+边距
      let clientWidth = document.body.clientWidth;
      let attrBoxWidth = clientWidth <= 1280 ? 320 : 476;
      let x =
        -(this.designArea.width() - (this.canvasWidth - attrBoxWidth)) / 2 +
        this.designArea.width() / 2;
      let y =
        -(this.designArea.height() - this.canvasHeight) / 2 +
        this.designArea.height() / 2;
      x =
        x < 50 + (this.designArea.width() * this.multiple) / 2
          ? 50 + (this.designArea.width() * this.multiple) / 2
          : x;

      this.designStage.width(this.canvasWidth);
      this.designStage.height(this.canvasHeight);
      this.designArea.x(x);
      this.designArea.y(y);
      this.designArea.scaleX(this.multiple);
      this.designArea.scaleY(this.multiple);
      this.designStage.draw();
      sketchRulerReset(this);
      gridMoveAndScale(this);
    },
    //打开标签，转成json数据
    OpenLabel() {
      var form = new FormData();
      form.append("url", this.labelAttr.file_pc);
      this.$axiosnet("/LoadXml/writeFile", form).then((res) => {
        let regx = /^[\s\S]*\.tprts$/;
        if (regx.test(this.labelAttr.file_pc)) {
          //加密文件需要解密
          let jsonString = decodeURIComponent(escape(atob(res)));
          this.labelJsonData = JSON.parse(jsonString);
        } else {
          this.labelJsonData = JSON.parse(res);
        }

        console.log("标签数据：", this.labelJsonData);

        let DrawObject = this.labelJsonData.PrtLable.ObjectList.DrawObject;
        DrawObject = Array.isArray(DrawObject) ? DrawObject : [DrawObject];
        // console.log('初始元素数据：', DrawObject);
        let LabelPage = this.labelJsonData.PrtLable.PictureArea.LabelPage;

        let obj = {
          width: LabelPage.LabelWidth,
          height: LabelPage.LabelHeight,
          unit: LabelPage["@MeasureUnit"].toLowerCase(),
          background: LabelPage.Background,
          printBackground: booleanChange(LabelPage.PrintBackground),
          columns: LabelPage.Columns,
          rows: LabelPage.Rows,
          rowSpacing: LabelPage.RowSpacing,
          columnSpacing: LabelPage.ColumnSpacing,
          paddingTop: LabelPage.UpperMargin,
          paddingBottom: LabelPage.LowerMargin,
          paddingLeft: LabelPage.LeftMargin,
          paddingRight: LabelPage.RightMargin,
        };
        this.labelAttr = Object.assign(this.labelAttr, obj);

        if (LabelPage["@MeasureUnit"].toLowerCase() == "inch") {
          this.MeasureUnit = "Inch";
          this.UnitConversion = 25.4;
        } else {
          this.MeasureUnit = "Mm";
          this.UnitConversion = 1;
        }

        this.editInit();
        setTimeout(() => {
          //行业模板需要从外部带canvas_img数据进来
          if (this.labelAttr.type == "common") {
            LabelPage.Canvas = this.labelAttr.canvas_img;
          } else {
            LabelPage.Canvas = LabelPage.Canvas ? LabelPage.Canvas.split(",") : [];
          }
          this.canvasArr = LabelPage.Canvas;
          this.canvasIndex = 0;
          let background = this.designLayer.find("#background")[0];
          if (LabelPage.Canvas.length > 0) {
            //绘制耗材背景
            this.isRestSize = false;
            let backgroundCanvas = this.designLayer.find("#backgroundCanvas")[0];
            let image = new Image();
            image.setAttribute("crossOrigin", "Anonymous");
            image.onload = (e) => {
              background.fill("");
              backgroundCanvas.image(image);
              backgroundCanvas.attrs.imageSrc = LabelPage.Canvas[0];
              this.designLayer.draw();
            };
            image.src = LabelPage.Canvas[this.canvasIndex];
          } else {
            this.isRestSize = true;
            background.fill("#FFFFFF");
          }
          this.generateNode(JSON.parse(JSON.stringify(DrawObject)));
        }, 10);
      });
    },
    //元素处理
    async generateNode(nodeList) {
      for (let i = 0; i < nodeList.length; i++) {
        let item = nodeList[i];
        let Tag;
        let text;
        let fontObj;
        let fontFamily;
        let BoxWidth;
        let Data = "";
        let strokeWidth = 0;
        let PenWidth = 0;
        if (item.Font) {
          var { font_style, text_decoration } = setFontStyle(item.Font["@FontStyle"]);
        }

        let x = 0;
        let y = 0;
        let width = 0;
        let height = 0;
        let lineHeight = 1;
        item.StartX = Number(item.StartX);
        item.StartY = Number(item.StartY);
        item.Width = item.Width ? Number(item.Width) : "";
        item.Height = item.Height ? Number(item.Height) : "";

        switch (item.Type) {
          case "WinText":
            x = (item.StartX / this.mmToPoint) * this.magnification;
            y = (item.StartY / this.mmToPoint) * this.magnification;

            let textNode = new Konva.Label({
              id: item.Id,
              type: item.Type,
              x,
              y,
              rotation: Number(item.AngleRound) == 270 ? -90 : Number(item.AngleRound),
              scaleX: 1,
              scaleY: 1,
              draggable: false,
            });
            Tag = new Konva.Tag({
              fill: booleanChange(item.AntiBlack) ? "#000000" : "",
            });
            textNode.add(Tag);

            item.DataSourceList = Array.isArray(item.DataSourceList)
              ? item.DataSourceList
              : [item.DataSourceList];
            item.DataSourceList.forEach((value) => {
              Data += value.FirstValue;
              value.InitialValue = value.DataSourceType == 2 ? value.InitialValue : "1";
              value.AddedValue =
                value.DataSourceType == 2 ? Number(value.AddedValue) : "";
              value.Quantity = value.DataSourceType == 2 ? Number(value.Quantity) : "";

              value.ColumnName = value.DataSourceType == 1 ? value.ColumnName : "";
              value.QuerySql = value.DataSourceType == 1 ? value.QuerySql : "";
              value.TableName = value.DataSourceType == 1 ? value.TableName : "";
              value.SheetIndex = [-1, -1];
              value.DataBase = [];
              value.DataSourceType = Number(value.DataSourceType);
              value.SequenceType = Number(value.SequenceType);
              value.DataType = Number(value.DataType);
              value.SequenceType = Number(value.SequenceType);
              value.NormalText = value.FirstValue;
              value.ReferenceId = value.DataSourceType == 3 ? value.ReferenceId : "";
            });

            if (item.TextArrange == 1) {
              this.drawVerticalText(textNode, item, Data);
            } else {
              this.drawHorizontalText(textNode, item, Data);
            }
            width = textNode.width();
            height = textNode.height();
            //根据左上角坐标计算出实际点坐标
            textNode.x(
              item.AngleRound == 90 ? x + height : item.AngleRound == 180 ? x + width : x
            );
            textNode.y(
              item.AngleRound == 180 ? y + height : item.AngleRound == 270 ? y + width : y
            );

            this.elementGroup.add(textNode);
            BoxWidth =
              item.TextArrange == 1
                ? pxToMm(textNode.height() * textNode.scaleY(), 2)
                : pxToMm(textNode.width() * textNode.scaleX(), 2);
            textNode.setAttr("parameter", {
              Id: textNode.id(),
              Type: item.Type,
              Name: item.Name,
              StartX: pxToMm(x, 2),
              StartY: pxToMm(y, 2),
              Width: pxToMm(textNode.width() * textNode.scaleX(), 2),
              Height: pxToMm(textNode.height() * textNode.scaleY(), 2),
              BoxWidth,
              Lock: booleanChange(item.Lock),
              Rotate: textNode.rotation(),
              AntiBlack: booleanChange(item.AntiBlack),
              MirrorImage: booleanChange(item.MirrorImage),
              FontFamily: item.Font["@FontFamily"],
              FontSize: dataPrecision(
                (item.Font["@FontSize"] / this.mmToPoint) * this.magnification,
                1
              ),
              Bold: font_style.indexOf("bold") > -1 ? true : false,
              Italic: font_style.indexOf("italic") > -1 ? true : false,
              UnderLine: text_decoration.indexOf("underline") > -1 ? true : false,
              DeleteLine: text_decoration.indexOf("line-through") > -1 ? true : false,
              TextAlign: item.TextAlign,
              LineHeight: Math.round(item.RowSpacing1 / this.mmToPoint),
              LetterSpacing: Math.round(item.WordSpacing / this.mmToPoint),
              AutoWrap: booleanChange(item.AutoWrap),
              Data,
              TextPrefix: item.TextPrefix,
              TextSuffix: item.TextSuffix,
              TextArrange: item.TextArrange,
              DataSourceList: item.DataSourceList,
              dataSourceIndex: 0,
              CopyMirrorImage: booleanChange(item.CopyMirrorImage),
            });
            this.getExcelCloudData(textNode);
            TransformerListening(this, textNode);
            if (booleanChange(item.CopyMirrorImage)) {
              drawMirrorNode(textNode, this, false);
            }
            break;
          case "TimeText":
            x = (item.StartX / this.mmToPoint) * this.magnification;
            y = (item.StartY / this.mmToPoint) * this.magnification;
            let timeDateNode = new Konva.Label({
              id: item.Id,
              type: item.Type,
              x,
              y,
              width,
              height,
              rotation: Number(item.AngleRound) == 270 ? -90 : Number(item.AngleRound),
              scaleX: 1,
              scaleY: 1,
              draggable: false,
            });

            Tag = new Konva.Tag({
              fill: booleanChange(item.AntiBlack) ? "#000000" : "",
            });

            timeDateNode.add(Tag);

            let SelectDate = "";
            let SelectTime = "";
            let date = "";
            if (item.TimeDate == 0) {
              //系统时间
              SelectDate = getNowDateOrTime("date");
              SelectTime = getNowDateOrTime("time");
            } else {
              //指定时间
              let fixedTime = "";
              if (item.TimeStamp != "" && item.TimeStamp != 0) {
                fixedTime = new Date(Number(item.TimeStamp));
              } else {
                //TimeStamp不存在的话，根据data反推出时间
                let time = new Date(item.Data).getTime();
                let time1 =
                  time -
                  item.DayOffset * 24 * 60 * 60 * 1000 -
                  item.MinuteOffset * 60 * 1000;
                fixedTime = new Date(time1);
              }
              let year = fixedTime.getFullYear();
              let month = fixedTime.getMonth() + 1;
              let date = fixedTime.getDate();
              SelectDate = `${year}-${month}-${date}`;

              let hours = fixedTime.getHours();
              let minutes = fixedTime.getMinutes();
              let seconds = fixedTime.getSeconds();
              SelectTime = `${hours}:${minutes}:${seconds}`;
            }
            date = dealTimeOffset(
              SelectDate,
              SelectTime,
              item.DayOffset,
              item.MinuteOffset
            );

            let { timeFormatList, allDateFormatList } = getTimeFormatList();
            let format_arr = item.DataFormat.split(" ");
            let DataFormat = [];
            let TimeFormat = [];
            format_arr.forEach((format) => {
              let isDateFormat = allDateFormatList.some((d) => d == format);
              if (isDateFormat) {
                DataFormat.push(format);
              }

              let isTimeFormat = timeFormatList.some((t) => t == format);
              if (isTimeFormat) {
                TimeFormat.push(format);
              }
            });
            DataFormat = DataFormat.join(" ");
            TimeFormat = TimeFormat.join(" ");
            let DateFormatChecked = [];
            if (DataFormat) {
              DateFormatChecked.push("date");
            }
            if (TimeFormat) {
              DateFormatChecked.push("time");
            }
            //如果日期和时间都为空，则设置为默认时间格式
            if (!DataFormat && !TimeFormat) {
              DateFormatChecked.push("date");
              DateFormatChecked.push("time");
            }
            Data = formatDate(date, DataFormat, TimeFormat);
            if (item.TextArrange == 1) {
              this.drawVerticalText(timeDateNode, item, Data);
            } else {
              this.drawHorizontalText(timeDateNode, item, Data);
            }

            width = timeDateNode.width();
            height = timeDateNode.height();
            //根据左上角坐标计算出实际点坐标
            timeDateNode.x(
              item.AngleRound == 90 ? x + height : item.AngleRound == 180 ? x + width : x
            );
            timeDateNode.y(
              item.AngleRound == 180 ? y + height : item.AngleRound == 270 ? y + width : y
            );

            this.elementGroup.add(timeDateNode);

            BoxWidth =
              item.TextArrange == 1
                ? pxToMm(timeDateNode.height() * timeDateNode.scaleY(), 2)
                : pxToMm(timeDateNode.width() * timeDateNode.scaleX(), 2);
            timeDateNode.setAttr("parameter", {
              Id: timeDateNode.id(),
              Type: item.Type,
              Name: item.Name,
              StartX: pxToMm(x, 2),
              StartY: pxToMm(y, 2),
              Width: pxToMm(timeDateNode.width() * timeDateNode.scaleX(), 2),
              Height: pxToMm(timeDateNode.height() * timeDateNode.scaleY(), 2),
              BoxWidth,
              Lock: booleanChange(item.Lock),
              Data,
              DataSourceType: 0,
              Rotate: timeDateNode.rotation(),
              AntiBlack: booleanChange(item.AntiBlack),
              MirrorImage: booleanChange(item.MirrorImage),
              FontFamily: item.Font["@FontFamily"],
              FontSize: dataPrecision(
                (item.Font["@FontSize"] / this.mmToPoint) * this.magnification,
                1
              ),
              Bold: font_style.indexOf("bold") > -1 ? true : false,
              Italic: font_style.indexOf("italic") > -1 ? true : false,
              UnderLine: text_decoration.indexOf("underline") > -1 ? true : false,
              DeleteLine: text_decoration.indexOf("line-through") > -1 ? true : false,
              TextAlign: item.TextAlign,
              LineHeight: Math.round(item.RowSpacing1 / this.mmToPoint),
              LetterSpacing: Math.round(item.WordSpacing / this.mmToPoint),
              AutoWrap: booleanChange(item.AutoWrap),
              TextArrange: item.TextArrange,
              TimeType: Number(item.TimeDate),
              DataFormat,
              TimeFormat,
              DayOffset: Number(item.DayOffset),
              MinuteOffset: Number(item.MinuteOffset),
              SelectDate,
              SelectTime,
              DateFormatChecked,
              CopyMirrorImage: booleanChange(item.CopyMirrorImage),
            });
            TransformerListening(this, timeDateNode);
            if (booleanChange(item.CopyMirrorImage)) {
              drawMirrorNode(timeDateNode, this, false);
            }
            break;
          case "GeneralBarcode":
          case "QRCode":
            try {
              let Localtype =
                item.Localtype == "BOTTOMLEFT" || item.Localtype == "TOPLEFT"
                  ? 0
                  : item.Localtype == "BOTTOMRIGHT" || item.Localtype == "TOPRIGHT"
                  ? 2
                  : item.Localtype == "BOTTOMJUSTIFY" || item.Localtype == "TOPJUSTIFY"
                  ? 3
                  : 1;
              let fontSize =
                item.Type == "GeneralBarcode"
                  ? (item.Font["@FontSize"] / this.mmToPoint) * this.magnification
                  : 12;
              item.DataSourceList = Array.isArray(item.DataSourceList)
                ? item.DataSourceList
                : [item.DataSourceList];
              item.DataSourceList.map((value) => {
                Data += value.FirstValue;
                value.InitialValue = value.DataSourceType == 2 ? value.InitialValue : "1";
                value.AddedValue =
                  value.DataSourceType == 2 ? Number(value.AddedValue) : "";
                value.Quantity = value.DataSourceType == 2 ? Number(value.Quantity) : "";

                value.ColumnName = value.DataSourceType == 1 ? value.ColumnName : "";
                value.QuerySql = value.DataSourceType == 1 ? value.QuerySql : "";
                value.TableName = value.DataSourceType == 1 ? value.TableName : "";
                value.DataBase = [];
                value.SheetIndex = [-1, -1];
                value.DataSourceType = Number(value.DataSourceType);
                value.SequenceType = Number(value.SequenceType);
                value.DataType = Number(value.DataType);
                value.SequenceType = Number(value.SequenceType);
                value.NormalText = value.FirstValue;
                value.ReferenceId = value.DataSourceType == 3 ? value.ReferenceId : "";
              });
              let codeList =
                item.Type == "GeneralBarcode"
                  ? getBarCodeTypeList()
                  : item.Type == "QRCode"
                  ? getQrCodeTypeList()
                  : [];
              let data = codeList.find((val) => val.value == item.BarcodeType);
              let options = {
                bcid: data
                  ? data.bcid
                  : item.Type == "GeneralBarcode"
                  ? "code128"
                  : "qrcode",
                text: Data,
                scale: 12,
                includetext: booleanChange(item.HasLabel), //是否显示文本
                textxalign: getTextAlignValue(Localtype), //left center right justify
                textsize: fontSize,
                textyalign:
                  item.DisplaPosition == 5 ||
                  item.DisplaPosition == 6 ||
                  item.DisplaPosition == 7 ||
                  item.DisplaPosition == 8
                    ? "above"
                    : "bottom", //above bottom
              };
              if (item.Type == "GeneralBarcode") {
                options.height = 8;
              } else {
                if (options.bcid == "qrcode" || options.bcid == "pdf417") {
                  let eclevelList = getEclevelList(item.BarcodeType);
                  if (eclevelList.length > 0) {
                    let eclevel = eclevelList.find(
                      (val) => val.label == item.ECLevelBits
                    );
                    options.eclevel = eclevel ? eclevel.value : eclevelList[2].value;
                  }
                }
              }
              x = (item.StartX / this.mmToPoint) * this.magnification;
              y = (item.StartY / this.mmToPoint) * this.magnification;
              let barcodeGroup = new Konva.Group({
                id: item.Id,
                type: item.Type,
                x,
                y,
                rotation: Number(item.AngleRound) == 270 ? -90 : Number(item.AngleRound),
                draggable: false,
              });
              let barcodeImg = new Konva.Image({
                type: item.Type,
                x: 0,
                y: 0,
                scaleX: 1,
                scaleY: 1,
              });
              barcodeGroup.add(barcodeImg);
              this.elementGroup.add(barcodeGroup);

              barcodeGroup.setAttr("options", options);
              barcodeGroup.setAttr("parameter", {
                Id: barcodeGroup.id(),
                Type: barcodeGroup.attrs.type,
                Name: item.Name,
                Lock: booleanChange(item.Lock),
                Data: options.text,
                Rotate: barcodeGroup.rotation(),
                FontSize: dataPrecision(options.textsize, 1),
                BarcodeType: item.BarcodeType,
                TextAlign: Localtype,
                BarcodeNumPosition: options.includetext
                  ? options.textyalign == "above"
                    ? 2
                    : 1
                  : 0,
                QRCodeWidth: barcodeGroup.width() / item.Zoom,
                ECLevelBits: options.eclevel,
                MirrorImage: booleanChange(item.MirrorImage),
                DataSourceList: item.DataSourceList,
                dataSourceIndex: 0,
                isEdit: false, //用来判断条码内容是否编辑过
                CopyMirrorImage: booleanChange(item.CopyMirrorImage),
              });

              let codeImg = await getGenerateCodeImg(barcodeGroup);
              barcodeGroup.setAttrs({
                width: codeImg.width,
                height: codeImg.height,
                scaleX: mmToPx(item.Width / this.mmToPoint, 3) / codeImg.width,
                scaleY: mmToPx(item.Height / this.mmToPoint, 3) / codeImg.height,
              });
              barcodeImg.setAttrs({
                image: codeImg,
                imageSrc: codeImg.src,
                width: codeImg.width,
                height: codeImg.height,
              });
              width = barcodeGroup.width() * barcodeGroup.scaleX();
              height = barcodeGroup.height() * barcodeGroup.scaleY();
              // 镜像处理  先获取完x的值之后再镜像
              if (booleanChange(item.MirrorImage)) {
                let scaleX = barcodeImg.scaleX();
                barcodeImg.scaleX(-scaleX);
                barcodeImg.x(barcodeImg.width());
              }
              //根据左上角坐标计算出实际点坐标
              barcodeGroup.x(
                item.AngleRound == 90
                  ? x + height
                  : item.AngleRound == 180
                  ? x + width
                  : x
              );
              barcodeGroup.y(
                item.AngleRound == 180
                  ? y + height
                  : item.AngleRound == 270
                  ? y + width
                  : y
              );
              barcodeGroup.attrs.parameter.StartX = pxToMm(x, 2);
              barcodeGroup.attrs.parameter.StartY = pxToMm(y, 2);
              barcodeGroup.attrs.parameter.Width = pxToMm(
                barcodeGroup.width() * barcodeGroup.scaleX(),
                2
              );
              barcodeGroup.attrs.parameter.Height = pxToMm(
                barcodeGroup.height() * barcodeGroup.scaleY(),
                2
              );

              this.getExcelCloudData(barcodeGroup);
              TransformerListening(this, barcodeGroup);
              if (booleanChange(item.CopyMirrorImage)) {
                drawMirrorNode(barcodeGroup, this, false);
              }
              this.designLayer.draw();
            } catch (e) {
              console.log(e);
            }
            break;
          case "Image":
            x = (item.StartX / this.mmToPoint) * this.magnification;
            y = (item.StartY / this.mmToPoint) * this.magnification;
            width = (item.Width / this.mmToPoint) * this.magnification;
            height = (item.Height / this.mmToPoint) * this.magnification;
            let image = new Image();
            image.setAttribute("crossOrigin", "Anonymous");
            image.onload = async () => {
              let imgGroup = new Konva.Group({
                id: item.Id,
                type: item.Type,
                x,
                y,
                width,
                height,
                rotation: Number(item.AngleRound) == 270 ? -90 : Number(item.AngleRound),
                scaleX: 1,
                scaleY: 1,
                draggable: false,
              });
              let imageObj = new Konva.Image({
                type: item.Type,
                x: 0,
                y: 0,
                width,
                height,
                image,
                imageSrc: image.src,
                scaleX: booleanChange(item.MirrorImage) ? -1 : 1,
                scaleY: 1,
              });
              imgGroup.add(imageObj);
              this.elementGroup.add(imgGroup);
              if (booleanChange(item.MirrorImage)) {
                imageObj.x(width);
              }
              //根据左上角坐标计算出实际点坐标
              imgGroup.x(
                item.AngleRound == 90
                  ? x + height
                  : item.AngleRound == 180
                  ? x + width
                  : x
              );
              imgGroup.y(
                item.AngleRound == 180
                  ? y + height
                  : item.AngleRound == 270
                  ? y + width
                  : y
              );
              imgGroup.setAttr("parameter", {
                Id: imgGroup.id(),
                Type: imgGroup.attrs.type,
                Name: item.Name,
                StartX: pxToMm(x, 2),
                StartY: pxToMm(y, 2),
                Width: pxToMm(imgGroup.width() * imgGroup.scaleX(), 2),
                Height: pxToMm(imgGroup.height() * imgGroup.scaleY(), 2),
                Lock: booleanChange(item.Lock),
                Rotate: imgGroup.rotation(),
                imageMode: 0,
                ISParticipating: booleanChange(item.ISParticipating),
                MirrorImage: booleanChange(item.MirrorImage),
                CopyMirrorImage: booleanChange(item.CopyMirrorImage),
              });
              TransformerListening(this, imgGroup);
              if (booleanChange(item.CopyMirrorImage)) {
                drawMirrorNode(imgGroup, this, false);
              }
              this.designLayer.draw();
            };
            image.src = this.imgHeader + item.OriginalImage;
            break;
          case "LineHorizontal":
          case "LineVertical":
          case "Line":
            let StartX = (item.StartX / this.mmToPoint) * this.magnification;
            let StartY = (item.StartY / this.mmToPoint) * this.magnification;
            let EndX = (item.EndX / this.mmToPoint) * this.magnification;
            let EndY = (item.EndY / this.mmToPoint) * this.magnification;
            x = StartX < EndX ? StartX : EndX;
            y = StartY < EndY ? StartY : EndY;
            PenWidth = Math.max(item.PenWidth / this.mmToPoint, 0.3);
            strokeWidth = mmToPx(PenWidth, 2);
            let lineNode = new Konva.Line({
              id: item.Id,
              type: item.Type,
              x,
              y,
              points: [StartX - x, StartY - y, EndX - x, EndY - y],
              stroke: "#000000",
              strokeWidth,
              dash: [3, 1],
              dashEnabled: item.DashStyle == 0 ? false : true,
              hitStrokeWidth: 10,
              draggable: false,
            });
            this.elementGroup.add(lineNode);

            let LineDirection = item.Type;
            if (item.Type == "Line") {
              if (item.StartX < item.EndX && item.StartY < item.EndY) {
                LineDirection = "RightLine";
              } else {
                LineDirection = "LeftLine";
              }
            }
            lineNode.setAttr("parameter", {
              Id: lineNode.id(),
              Type: lineNode.attrs.type,
              Lock: booleanChange(item.Lock),
              StartX: pxToMm(lineNode.x(), 2),
              StartY: pxToMm(lineNode.y(), 2),
              EndX: pxToMm(EndX, 2),
              EndY: pxToMm(EndY, 2),
              Width: pxToMm(lineNode.width() * lineNode.scaleX(), 2),
              Height: pxToMm(lineNode.height() * lineNode.scaleY(), 2),
              PenWidth: pxToMm(lineNode.strokeWidth(), 1),
              Dash: lineNode.dashEnabled(),
              DashStyle: item.DashStyle,
              LineDirection, //LineHorizontal水平 LineVertical垂直  LeftLine左斜线 RightLine右斜线
              CopyMirrorImage: booleanChange(item.CopyMirrorImage),
            });
            TransformerListening(this, lineNode);
            if (booleanChange(item.CopyMirrorImage)) {
              drawMirrorNode(lineNode, this, false);
            }
            break;
          case "Rectangle":
          case "SolidRectangle":
          case "RoundRectangle":
            PenWidth = Math.max(item.PenWidth / this.mmToPoint, 0.3);
            strokeWidth = mmToPx(PenWidth, 2);
            let rectNode = new Konva.Rect({
              id: item.Id,
              type: item.Type == "SolidRectangle" ? "Rectangle" : item.Type,
              x:
                ((item.StartX + item.PenWidth / 2) / this.mmToPoint) * this.magnification,
              y:
                ((item.StartY + item.PenWidth / 2) / this.mmToPoint) * this.magnification,
              width: ((item.Width - item.PenWidth) / this.mmToPoint) * this.magnification,
              height:
                ((item.Height - item.PenWidth) / this.mmToPoint) * this.magnification,
              fill:
                booleanChange(item.Fill) || item.Type == "SolidRectangle"
                  ? "#000000"
                  : "",
              stroke: "black",
              strokeWidth,
              dash: [3, 1],
              dashEnabled: item.DashStyle == 0 ? false : true,
              rotation: 0,
              cornerRadius:
                item.Type == "RoundRectangle"
                  ? (item.CornerRadius / this.mmToPoint) * this.magnification
                  : 0,
              draggable: false,
            });
            this.elementGroup.add(rectNode);
            rectNode.setAttr("parameter", {
              Id: rectNode.id(),
              Type: rectNode.attrs.type,
              Name: item.Name,
              StartX: pxToMm(rectNode.x(), 2),
              StartY: pxToMm(rectNode.y(), 2),
              Width: pxToMm(rectNode.width() * rectNode.scaleX(), 2),
              Height: pxToMm(rectNode.height() * rectNode.scaleY(), 2),
              Lock: booleanChange(item.Lock),
              Rotate: rectNode.rotation(),
              PenWidth: pxToMm(rectNode.strokeWidth(), 1),
              CornerRadius: pxToMm(rectNode.cornerRadius(), 1),
              Dash: rectNode.dashEnabled(),
              DashStyle: item.DashStyle, //0实线 1虚线
              Fill: item.Type == "SolidRectangle" ? true : booleanChange(item.Fill),
              CopyMirrorImage: booleanChange(item.CopyMirrorImage),
            });
            TransformerListening(this, rectNode);
            if (booleanChange(item.CopyMirrorImage)) {
              drawMirrorNode(rectNode, this, false);
            }
            break;
          case "Ellipse":
            PenWidth = Math.max(item.PenWidth / this.mmToPoint, 0.3);
            strokeWidth = mmToPx(PenWidth, 2);
            x = ((item.StartX + item.Width / 2) / this.mmToPoint) * this.magnification;
            y = ((item.StartY + item.Height / 2) / this.mmToPoint) * this.magnification;

            let ellipseNode = new Konva.Ellipse({
              id: item.Id,
              type: item.Type,
              x,
              y,
              width: (item.Width / this.mmToPoint) * this.magnification,
              height: (item.Height / this.mmToPoint) * this.magnification,
              radiusX: ((item.Width / this.mmToPoint) * this.magnification) / 2,
              radiusY: ((item.Height / this.mmToPoint) * this.magnification) / 2,
              fill: booleanChange(item.Fill) ? "#000000" : "",
              stroke: "black",
              strokeWidth,
              dash: [3, 1],
              dashEnabled: item.DashStyle == 0 ? false : true,
              rotation: 0,
              draggable: false,
            });
            this.elementGroup.add(ellipseNode);
            ellipseNode.setAttr("parameter", {
              Id: ellipseNode.id(),
              Type: ellipseNode.attrs.type,
              Name: item.Name,
              StartX: pxToMm(
                ellipseNode.x() - ellipseNode.radiusX() * ellipseNode.scaleX(),
                2
              ),
              StartY: pxToMm(
                ellipseNode.y() - ellipseNode.radiusY() * ellipseNode.scaleY(),
                2
              ),
              Width: pxToMm(ellipseNode.width() * ellipseNode.scaleX(), 2),
              Height: pxToMm(ellipseNode.height() * ellipseNode.scaleY(), 2),
              Lock: booleanChange(item.Lock),
              Rotate: ellipseNode.rotation(),
              PenWidth: pxToMm(ellipseNode.strokeWidth(), 1),
              Dash: ellipseNode.dashEnabled(),
              DashStyle: item.DashStyle,
              Fill: booleanChange(item.Fill),
              CopyMirrorImage: booleanChange(item.CopyMirrorImage),
            });
            TransformerListening(this, ellipseNode);
            if (booleanChange(item.CopyMirrorImage)) {
              drawMirrorNode(ellipseNode, this, false);
            }
            break;
          case "Diamond":
            PenWidth = Math.max(item.PenWidth / this.mmToPoint, 0.3);
            strokeWidth = mmToPx(PenWidth, 2);
            x = (item.StartX / this.mmToPoint) * this.magnification;
            y = (item.StartY / this.mmToPoint) * this.magnification;

            let diamond_width = (item.Width / this.mmToPoint) * this.magnification;
            let diamond_height = (item.Height / this.mmToPoint) * this.magnification;

            let diamondNode = new Konva.Line({
              id: item.Id,
              type: item.Type,
              x,
              y,
              width: diamond_width,
              height: diamond_height,
              points: [
                0,
                diamond_height / 2,
                diamond_width / 2,
                0,
                diamond_width,
                diamond_height / 2,
                diamond_width / 2,
                diamond_height,
              ],
              closed: true,
              fill: booleanChange(item.Fill) ? "#000000" : "",
              stroke: "black",
              strokeWidth,
              dash: [3, 1],
              dashEnabled: item.DashStyle == 0 ? false : true,
              rotation: 0,
              scaleX: 1,
              scaleY: 1,
              draggable: false,
            });
            this.elementGroup.add(diamondNode);
            diamondNode.setAttr("parameter", {
              Id: diamondNode.id(),
              Type: diamondNode.attrs.type,
              Name: item.Name,
              StartX: pxToMm(diamondNode.x(), 2),
              StartY: pxToMm(diamondNode.y(), 2),
              Width: pxToMm(diamondNode.width() * diamondNode.scaleX(), 2),
              Height: pxToMm(diamondNode.height() * diamondNode.scaleY(), 2),
              Lock: booleanChange(item.Lock),
              Rotate: diamondNode.rotation(),
              PenWidth: pxToMm(diamondNode.strokeWidth(), 1),
              Dash: diamondNode.dashEnabled(),
              DashStyle: item.DashStyle,
              Fill: booleanChange(item.Fill),
              CopyMirrorImage: booleanChange(item.CopyMirrorImage),
            });
            TransformerListening(this, diamondNode);
            if (booleanChange(item.CopyMirrorImage)) {
              drawMirrorNode(diamondNode, this, false);
            }
            break;
          case "Triangle":
            PenWidth = Math.max(item.PenWidth / this.mmToPoint, 0.3);
            strokeWidth = mmToPx(PenWidth, 2);
            x = (item.StartX / this.mmToPoint) * this.magnification;
            y = (item.StartY / this.mmToPoint) * this.magnification;

            let triangle_width = (item.Width / this.mmToPoint) * this.magnification;
            let triangle_height = (item.Height / this.mmToPoint) * this.magnification;

            let triangleNode = new Konva.Line({
              id: item.Id,
              type: item.Type,
              x,
              y,
              width: triangle_width,
              height: triangle_height,
              points: [
                0,
                triangle_height,
                triangle_width / 2,
                0,
                triangle_width,
                triangle_height,
              ],
              closed: true,
              fill: booleanChange(item.Fill) ? "#000000" : "",
              stroke: "black",
              strokeWidth,
              dash: [3, 1],
              dashEnabled: item.DashStyle == 0 ? false : true,
              rotation: 0,
              scaleX: 1,
              scaleY: booleanChange(item.MirrorImage) ? -1 : 1,
              draggable: false,
            });
            this.elementGroup.add(triangleNode);
            if (booleanChange(item.MirrorImage)) {
              triangleNode.y(y + triangle_height);
            }

            triangleNode.setAttr("parameter", {
              Id: triangleNode.id(),
              Type: triangleNode.attrs.type,
              Name: item.Name,
              StartX: pxToMm(triangleNode.x(), 2),
              StartY: pxToMm(triangleNode.y(), 2),
              Width: pxToMm(triangleNode.width() * triangleNode.scaleX(), 2),
              Height: pxToMm(triangleNode.height() * triangleNode.scaleY(), 2),
              Lock: booleanChange(item.Lock),
              StartX: triangleNode.x(),
              StartY: triangleNode.y(),
              Rotate: triangleNode.rotation(),
              PenWidth: pxToMm(triangleNode.strokeWidth(), 1),
              Dash: triangleNode.dashEnabled(),
              DashStyle: item.DashStyle,
              Fill: booleanChange(item.Fill),
              MirrorImage: booleanChange(item.MirrorImage),
              CopyMirrorImage: booleanChange(item.CopyMirrorImage),
            });
            TransformerListening(this, triangleNode);
            if (booleanChange(item.CopyMirrorImage)) {
              drawMirrorNode(triangleNode, this, false);
            }
            break;
          case "Table":
            x = (item.StartX / this.mmToPoint) * this.magnification;
            y = (item.StartY / this.mmToPoint) * this.magnification;
            let tableWidth = (item.Width / this.mmToPoint) * this.magnification;
            let tableHeight = (item.Height / this.mmToPoint) * this.magnification;
            PenWidth = Math.max(item.PenWidth / this.mmToPoint, 0.3);
            strokeWidth = mmToPx(PenWidth, 2);
            let tableNode = new Konva.Group({
              id: item.Id,
              type: item.Type,
              x,
              y,
              width: tableWidth,
              height: tableHeight,
              rotation: Number(item.AngleRound) == 270 ? -90 : Number(item.AngleRound),
              scaleX: 1,
              scaleY: 1,
              draggable: true,
            });
            let DicLineLocW = item.DicLineLocW ? item.DicLineLocW.split(",") : [];
            let DicLineLocH = item.DicLineLocH ? item.DicLineLocH.split(",") : [];
            let row = DicLineLocH.length + 1;
            let column = DicLineLocW.length + 1;

            let CellWidth = []; //记录每一列的宽度
            let CellHeight = []; //记录每一行的高度
            DicLineLocW = DicLineLocW.map((val) => {
              return (val / this.mmToPoint) * this.magnification;
            });
            DicLineLocW.unshift(0);
            DicLineLocW.push(tableWidth - strokeWidth);
            DicLineLocH = DicLineLocH.map((val) => {
              return (val / this.mmToPoint) * this.magnification;
            });
            DicLineLocH.unshift(0);
            DicLineLocH.push(tableHeight - strokeWidth);

            for (let i = 1; i < DicLineLocW.length; i++) {
              let val = DicLineLocW[i] - DicLineLocW[i - 1] - strokeWidth;
              CellWidth.push(val);
            }
            for (let i = 1; i < DicLineLocH.length; i++) {
              let val = DicLineLocH[i] - DicLineLocH[i - 1] - strokeWidth;
              CellHeight.push(val);
            }

            //绘制横线
            let tableRowLine = new Konva.Group({
              name: "tableRowLine",
            });
            tableNode.add(tableRowLine);
            for (let i = 0; i <= row; i++) {
              let y = DicLineLocH[i];
              let rowLine = new Konva.Rect({
                name: `rowLine_${i}`,
                x: 0,
                y,
                width: tableWidth,
                height: strokeWidth,
                fill: "#000000",
                hitFunc: (context) => {
                  context.beginPath();
                  context.rect(0, y, tableWidth, strokeWidth);
                  context.closePath();
                },
              });
              tableRowLine.add(rowLine);
            }
            //绘制竖线
            let tableColumnLine = new Konva.Group({
              name: "tableColumnLine",
            });
            tableNode.add(tableColumnLine);
            for (let i = 0; i <= column; i++) {
              let x = DicLineLocW[i];
              let columnLine = new Konva.Rect({
                name: `columnLine_${i}`,
                x,
                y: 0,
                width: strokeWidth,
                height: tableHeight,
                fill: "#000000",
                hitFunc: (context) => {
                  context.beginPath();
                  context.rect(x, 0, strokeWidth, tableHeight);
                  context.closePath();
                },
              });
              tableColumnLine.add(columnLine);
            }
            //绘制单元格
            let cellGroup = new Konva.Group({
              name: "cellGroup",
              x: strokeWidth,
              y: strokeWidth,
            });
            tableNode.add(cellGroup);

            item.DataSourceList = Array.isArray(item.DataSourceList)
              ? item.DataSourceList
              : [item.DataSourceList];
            let DataSourceList = item.DataSourceList.map((data) => {
              let allPos = data.Key.split("|");
              for (let i = 0; i < allPos.length; i++) {
                allPos[i] = changeType(allPos[i].split(","));
              }
              let pos = allPos[0];

              let cell_x = DicLineLocW[pos[1]];
              let cell_y = DicLineLocH[pos[0]];
              let cellWidth = CellWidth[pos[1]];
              let cellHeight = CellHeight[pos[0]];

              if (allPos.length > 1) {
                //合并的单元格
                const { minRow, maxRow, minColumn, maxColumn } = getCellRang(allPos);
                cellWidth =
                  sumData(CellWidth.slice(minColumn, maxColumn + 1)) +
                  strokeWidth * (maxColumn - minColumn);
                cellHeight =
                  sumData(CellHeight.slice(minRow, maxRow + 1)) +
                  strokeWidth * (maxRow - minRow);
              }

              let cell = new Konva.Group({
                name: `cell_${pos[0]}_${pos[1]}`,
                x: cell_x,
                y: cell_y,
                width: cellWidth,
                height: cellHeight,
                pos,
                allPos,
                clipWidth: cellWidth,
                clipHeight: cellHeight,
              });
              let cellBg = new Konva.Rect({
                name: "cellBg",
                pos,
                x: 0,
                y: 0,
                width: cellWidth,
                height: cellHeight,
                fill: booleanChange(data.AntiBlack) ? "#000000" : "#FFFFFF",
                antiBlack: booleanChange(data.AntiBlack),
              });
              cell.add(cellBg);

              let { font_style, text_decoration } = setFontStyle(data.Font["@FontStyle"]);

              lineHeight =
                ((data.RowSpacing1 / this.mmToPoint) * this.magnification) /
                  ((data.Font["@FontSize"] / this.mmToPoint) * this.magnification) +
                1;

              let cellText = new Konva.Text({
                name: "cellText",
                pos,
                x: booleanChange(data.MirrorImage) ? cellWidth : 0,
                y: 0,
                width: cellWidth,
                height: cellHeight,
                text: data.Data,
                fontFamily: data.Font["@FontFamily"],
                fontSize: (data.Font["@FontSize"] / this.mmToPoint) * this.magnification,
                fontStyle: font_style,
                textDecoration: text_decoration,
                align: getTextAlignValue(data.TextAlign),
                verticalAlign: "middle",
                lineHeight,
                letterSpacing: (data.WordSpacing / this.mmToPoint) * this.magnification,
                fill: booleanChange(data.AntiBlack) ? "#FFFFFF" : "#000000",
                scaleX: booleanChange(data.MirrorImage) ? -1 : 1,
                scaleY: 1,
                wrap: "char",
              });
              cell.add(cellText);

              let cellSelect = new Konva.Rect({
                name: "cellSelect",
                pos,
                x: 0,
                y: 0,
                width: cellWidth,
                height: cellHeight,
                fill: "#3F80F5",
                opacity: 0.1,
                visible: pos[0] == 0 && pos[1] == 0 ? true : false,
              });
              cellSelect.hide();
              cell.add(cellSelect);
              cellSelect.moveToTop();

              cell.setAttr("parameter", {
                AllKeys: cell.attrs.allPos, //存放合并的单元格坐标
                Key: cell.attrs.pos,
                StartX: pxToMm(cell.x(), 2),
                StartY: pxToMm(cell.y(), 2),
                Width: pxToMm(cell.width(), 2),
                Height: pxToMm(cell.height(), 2),
                FirstValue: cellText.text() ? cellText.text() : "",
                NormalText: cellText.text() ? cellText.text() : "",
                SequenceType: Number(data.SequenceType),
                InitialValue: Number(data.DataSourceType) == 2 ? data.InitialValue : "1",
                AddedValue: Number(data.AddedValue),
                Quantity: Number(data.Quantity),
                ColumnName: data.ColumnName,
                QuerySql: data.QuerySql,
                TableName: data.TableName,
                ConnectionStr: data.ConnectionStr,
                DataSourceType: Number(data.DataSourceType),
                AutoWrap: booleanChange(data.AutoWrap),
                AntiBlack: booleanChange(data.AntiBlack),
                MirrorImage: booleanChange(data.MirrorImage),
                FontFamily: cellText.fontFamily(),
                FontSize: dataPrecision(cellText.fontSize(), 1),
                Bold: cellText.fontStyle().indexOf("bold") > -1 ? true : false,
                Italic: cellText.fontStyle().indexOf("italic") > -1 ? true : false,
                UnderLine:
                  cellText.textDecoration().indexOf("underline") > -1 ? true : false,
                DeleteLine:
                  cellText.textDecoration().indexOf("line-through") > -1 ? true : false,
                TextAlign: data.TextAlign,
                LineHeight: Math.round(data.RowSpacing1 / this.mmToPoint),
                LetterSpacing: Math.round(pxToMm(cellText.letterSpacing(), 2)),
                SheetIndex: [-1, -1],
                DataBase: [],
              });

              cellGroup.add(cell);
              return cell.attrs.parameter;
            });

            tableNode.setAttr("parameter", {
              Id: tableNode.id(),
              Type: tableNode.attrs.type,
              Name: item.Name,
              StartX: pxToMm(tableNode.x(), 2),
              StartY: pxToMm(tableNode.y(), 2),
              Width: pxToMm(tableNode.width() * tableNode.scaleX(), 2),
              Height: pxToMm(tableNode.height() * tableNode.scaleY(), 2),
              Lock: booleanChange(item.Lock),
              Rotate: tableNode.rotation(),
              DataSourceList, //记录单元格数据
              DicLineLocW, //记录列线
              DicLineLocH, //记录行线
              Row: row, //行数
              Column: column, //列数
              CellWidth, //记录每列单元格的宽度
              CellHeight, //记录每行单元格的高度
              cellMultiple: false, //是否开启多选
              mulCellPos: [], //记录多选的单元格的坐标
              currentPos: [0, 0], //当前选中单元格的坐标
              dataSourceIndex: 0, //当前选中单元格的下标
              PenWidth: pxToMm(strokeWidth, 1),
            });

            this.elementGroup.add(tableNode);
            this.getExcelCloudData(tableNode);
            TransformerListening(this, tableNode);

            break;
        }
      }
      this.designLayer.draw();
    },
    //绘制横向文本
    drawHorizontalText(node, item, Data) {
      let lineHeight =
        ((item.RowSpacing1 / this.mmToPoint) * this.magnification) /
          ((item.Font["@FontSize"] / this.mmToPoint) * this.magnification) +
        1;

      item.TextPrefix = item.TextPrefix ? item.TextPrefix : "";
      item.TextSuffix = item.TextSuffix ? item.TextSuffix : "";

      let textStr = Data;
      if (node.attrs.type == "WinText") {
        textStr = item.TextPrefix + Data + item.TextSuffix;
      }

      const { font_style, text_decoration } = setFontStyle(item.Font["@FontStyle"]);

      let text = new Konva.Text({
        text: textStr,
        fontFamily: item.Font["@FontFamily"],
        fontSize: (item.Font["@FontSize"] / this.mmToPoint) * this.magnification,
        fontStyle: font_style,
        textDecoration: text_decoration,
        align: getTextAlignValue(item.TextAlign),
        lineHeight,
        letterSpacing: (item.WordSpacing / this.mmToPoint) * this.magnification,
        fill: booleanChange(item.AntiBlack) ? "#FFFFFF" : "#000000",
        scaleX: booleanChange(item.MirrorImage) ? -1 : 1,
        scaleY: 1,
        wrap: "char",
      });
      node.add(text);
      let textWidth = text.width();
      let oldWidth = booleanChange(item.AutoWrap) ? item.BoxWidth : item.Width;
      let width = (oldWidth / this.mmToPoint) * this.magnification;
      let scale = width / textWidth;
      if (booleanChange(item.AutoWrap)) {
        if (item.TextAlign == 4) {
          text.width(textWidth);
          node.width(textWidth);
          node.scaleX(scale);
        } else {
          text.width(width);
          node.width(width);
        }
      } else {
        if (item.TextAlign == 4) {
          node.scaleX(scale);
        }
        text.width(textWidth);
        node.width(textWidth);
      }
      //翻转重置文本坐标
      if (booleanChange(item.MirrorImage)) {
        if (item.TextAlign == 4) {
          text.x(textWidth);
        } else {
          text.x(width);
        }
      }
    },
    //绘制竖向文本
    drawVerticalText(node, item, Data) {
      item.TextPrefix = item.TextPrefix ? item.TextPrefix : "";
      item.TextSuffix = item.TextSuffix ? item.TextSuffix : "";
      let textStr = Data;
      if (node.attrs.type == "WinText") {
        textStr = item.TextPrefix + Data + item.TextSuffix;
      }
      let textArr = textStr.split(/\r?\n/);

      const { font_style, text_decoration } = setFontStyle(item.Font["@FontStyle"]);

      let fontSize = (item.Font["@FontSize"] / this.mmToPoint) * this.magnification;
      let letterSpacing = (item.WordSpacing / this.mmToPoint) * this.magnification;
      let lineHeight =
        ((item.RowSpacing1 / this.mmToPoint) * this.magnification) /
          ((item.Font["@FontSize"] / this.mmToPoint) * this.magnification) +
        1;

      let regex1 = /^[0-9a-zA-Z]*$/;
      let regex2 = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·#（）“”‘|《》、【】[\] ]/;

      let columnText = []; //每列的内容

      let height = (item.Height / this.mmToPoint) * this.magnification;
      if (booleanChange(item.AutoWrap) && item.TextAlign != 4) {
        //自动换行
        height = (item.Height / this.mmToPoint) * this.magnification;
        for (let i = 0; i < textArr.length; i++) {
          let words = textArr[i].split("");
          let currentLine = "";
          let wordsHeight = 0;
          for (let j = 0; j < words.length; j++) {
            let word = words[j];
            let isRotate = regex1.test(word) || regex2.test(word);
            let currentText = currentLine + word;
            let new_wordsHeight = isRotate
              ? wordsHeight + fontSize * 0.55
              : wordsHeight + fontSize;
            let actualHeight = new_wordsHeight + letterSpacing * (currentText.length - 1);
            if (actualHeight < height) {
              currentLine += word;
              wordsHeight = new_wordsHeight;
            } else {
              columnText.push({
                words: currentLine,
                height: wordsHeight,
              });
              currentLine = word;
              wordsHeight = isRotate ? fontSize * 0.55 : fontSize;
            }
          }
          columnText.push({
            words: currentLine,
            height: wordsHeight,
          });
        }
      } else {
        let maxHeight = 0;
        textArr.forEach((item) => {
          let words = item.split("");
          let wordsHeight = 0;
          for (let j = 0; j < words.length; j++) {
            let word = words[j];
            let isRotate = regex1.test(word) || regex2.test(word);
            wordsHeight = isRotate
              ? wordsHeight + fontSize * 0.55
              : wordsHeight + fontSize;
          }
          columnText.push({
            words: item,
            height: wordsHeight,
          });
          maxHeight = Math.max(wordsHeight, maxHeight);
        });
        height = maxHeight;
        if (item.TextAlign == 4) {
          let oldHeight = item.Height;
          oldHeight = (oldHeight / this.mmToPoint) * this.magnification;
          let scale = oldHeight / height;
          node.scaleY(scale);
        }
      }

      let columnCount = columnText.length; //总列数
      // 计算总宽度
      let textWidth = columnCount * fontSize + lineHeight * (columnCount - 1);
      let width = (item.Width / this.mmToPoint) * this.magnification;
      width = textWidth > width ? textWidth : width;
      node.width(width);
      node.height(height);
      node.getTag().width(width);
      node.getTag().height(height);
      //text1 用来撑开高度，label会取第一个text的高度作为整体的高度
      let text1 = new Konva.Text({
        name: "tempText",
        x: 0,
        y: 0,
        width,
        height,
        textWidth: textWidth, //记录实际文本宽度
      });
      text1.hide();
      node.add(text1);
      let textGroup = new Konva.Group({
        name: "textGroup",
        x: booleanChange(item.MirrorImage) ? width : 0,
        y: 0,
        scaleX: booleanChange(item.MirrorImage) ? -1 : 1,
        scaleY: 1,
        width: width,
        height,
      });
      node.add(textGroup);

      let currentX = width - fontSize;
      for (let i = 0; i < columnText.length; i++) {
        let currentY =
          item.TextAlign == 1
            ? (height -
                columnText[i].height -
                letterSpacing * (columnText[i].words.length - 1)) /
              2
            : item.TextAlign == 2
            ? height -
              columnText[i].height -
              letterSpacing * (columnText[i].words.length - 1)
            : 0;
        for (let j = 0; j < columnText[i].words.length; j++) {
          let isRotate =
            regex1.test(columnText[i].words[j]) || regex2.test(columnText[i].words[j]);
          let text = new Konva.Text({
            x: isRotate ? currentX + Math.ceil(fontSize * 0.3) / 2 : currentX,
            y: currentY,
            offsetX: 0,
            offsetY: 0,
            width: Math.ceil(fontSize), //向上取整，防止字体实际大小超出我们所预设的宽高导致文字无法显示的问题
            height: isRotate ? Math.ceil(fontSize * 0.55) : Math.ceil(fontSize),
            text: columnText[i].words[j],
            fontSize: fontSize,
            fontFamily: item.Font["@FontFamily"],
            fontStyle: font_style,
            textDecoration: text_decoration,
            rotation: 0,
            align: "center",
            lineHeight: 1,
            letterSpacing: 0,
            fill: booleanChange(item.AntiBlack) ? "#ffffff" : "#000000",
            scaleX: 1,
            scaleY: 1,
          });
          if (isRotate) {
            changeOriginToCenter(text);
            text.rotation(90);
            changeOriginToTopleft(text);
          }
          currentY = isRotate
            ? currentY + fontSize * 0.55 + letterSpacing
            : currentY + fontSize + letterSpacing;
          if (item.TextAlign == 3) {
            //平铺
            let space =
              (height - columnText[i].height) / (columnText[i].words.length - 1);
            currentY += space;
          }
          textGroup.add(text);
        }
        currentX -= fontSize + lineHeight;
      }
    },
    //处理初始化excel表格数据
    async getExcelCloudData(node) {
      let parameter = node.attrs.parameter;
      let DataSourceList = parameter.DataSourceList;
      for (let i = 0; i < DataSourceList.length; i++) {
        let list = DataSourceList[i];
        let DataSourceType = list.DataSourceType;
        let ColumnName = list.ColumnName;
        let TableName = list.TableName;
        let ConnectionStr = list.ConnectionStr;
        if (DataSourceType != 1) continue;
        if (!ColumnName) {
          list.DataSourceType = 0;
          continue;
        }
        const reg = new RegExp("^(http|https)://", "i");
        var match = reg.test(ConnectionStr);
        //云端数据
        if (match) {
          let sheetList = [];
          let ConnectionData = [];
          await this.getSheet(ConnectionStr).then((res) => {
            TableName = TableName ? TableName : res[0].sheetName; //TableName为空时默认取第一张表的数据
            res.forEach((item, index) => {
              let obj = {
                text: item.sheetName,
                index,
                children: [],
              };
              if (item.data.length > 0) {
                item.data.forEach((column, c_index) => {
                  obj.children.push({
                    text: column[0] ? column[0] : `col_`,
                    index: c_index,
                    column: column.slice(1),
                  });
                  if (item.sheetName == TableName && column[0] == ColumnName) {
                    ConnectionData = column.slice(1);
                    list.SheetIndex = [index, c_index];
                  }
                });
              } else {
                obj.children.push({
                  text: this.$t(`tips.tip107`),
                  index: 0,
                  column: [],
                });
              }
              sheetList.push(obj);
            });

            list.DataBase = sheetList;
            list.ConnectionData = ConnectionData;
          });
        } else {
          //非云端数据,转成普通文本
          list.DataSourceType = 0;
          list.ColumnName = "";
          list.QuerySql = "";
          list.TableName = "";
          list.ConnectionStr = "";
        }
      }
      parameter.DataSourceList = DataSourceList;
    },
    //请求初始化云端数据表内容
    getSheet(url) {
      return new Promise((resolve, reject) => {
        this.$axioshanma("/api/tem.new_class/import", {
          FileUrl: url,
          RowIndex: 0,
          TableIndex: -1, //传-1可拿到所有的表数据
        })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject();
          });
      });
    },
    //设置云端图片
    handleUpdateImg(imgUrl) {
      this.$refs.attrsDiaRef.handleUpdateImg(imgUrl);
    },
    //标签设置
    handleSetLabel(value) {
      let isNodeAdaptive =
        this.labelAttr.width != value.width || this.labelAttr.height != value.height;
      if (isNodeAdaptive) {
        //宽高改变需要自适应标签元素位置和大小
        let scale = value.width / this.labelAttr.width;
        this.$refs.toolAreaRef.handleNodeAdaptive(scale);
      }

      this.labelAttr = value;
      this.MeasureUnit = value.unit;
      if (this.MeasureUnit.toLowerCase() == "inch") {
        this.UnitConversion = 25.4;
      } else {
        this.UnitConversion = 1;
      }
      this.$refs.toolAreaRef.handleCanvasAdaptive();

      //设置背景
      let backgroundImg = this.designLayer.find("#backgroundImg")[0];
      if (this.labelAttr.background) {
        let image = new Image();
        image.setAttribute("crossOrigin", "Anonymous");
        image.onload = (e) => {
          backgroundImg.image(image);
          backgroundImg.attrs.imageSrc = this.imgHeader + this.labelAttr.background;
          this.designLayer.draw();
        };
        image.src = this.imgHeader + this.labelAttr.background;
      } else {
        backgroundImg.image("");
        backgroundImg.attrs.imageSrc = "";
        this.designLayer.draw();
      }
    },
    //关闭新手引导
    closeEditGuide() {
      this.showEditGuide = false;
      localStorage.setItem("firstEdit", "false");
    },
  },
  unmounted() {
    clearInterval(this.systemTimer);
    this.systemTimer = null;
    document.onkeydown = null;
    window.removeEventListener("resize", this.fitStageIntoParentContainer);
    window.removeEventListener("beforeunload", this.beforeunloadTip);
  },
  destroyed() {
    clearInterval(this.systemTimer);
    this.systemTimer = null;
    message.destroy();
    document.onkeydown = null;
    document.removeEventListener("mousewheel");
    document.removeEventListener("DOMMouseScroll");
  },
};
</script>
