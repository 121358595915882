export default {
	navbar: {
		login: "登录",
		home: "首页",
		register: "注册",
		shareTemplate: "行业模板",
		cloudTemplate: "云端模板",
		cloudData: "云端数据",
		printSetting: "打印设置",
		moreSetting: "更多设置",
		user: "个人中心",
		unit: "单位",
		logout: "退出登录",
		error404: "Error404",
		renamePassword: "修改密码",
		editLabel: "编辑标签",
		member: "会员中心",
		suggest: "意见反馈",
		download: "下载中心",
		help: "帮助中心",
		bind: "绑定账号",
    onlinetool: '实用工具',
    expansion: '云空间扩容',
    pdfPrint: '文档打印',
	},
	button: {
		login: "登录",
		register: "注册",
		getCode: "获取验证码",
		print: "立即打印",
		editLabel: "编辑标签",
		copyLabel: "复制标签",
		rename: "重命名",
		delete: "删除",
		createLabel: "新建标签",
		move: "移动",
		createFile: "新建文件夹",
		upload: "上传",
		cancel: "取消",
		confirm: "确定",
		save: "保存",
		create: "创建",
		saveToCloudTem: "保存到云端模板",
		download: "下载",
		updateAvatar: "修改头像",
		forgetPass: "忘记密码",
		set: "设置",
		goRegister: "立即注册",
		goLogin: "前往登录",
		backLogin: "返回登录",
		reset: "重置",
		useEmail: "使用电子邮箱注册",
		usePhone: "使用手机号注册",
		labelset: "标签设置",
		printLabel: "打印",
		exitEdit: "退出编辑",
		choose: "选择",
		help: "帮助中心",
		downloadPC: "下载客户端",
		Renewal: "续费",
		Upgrade: "升级会员专享会员特权",
		bingLogin: "绑定并登录",
		upgradeMember: "立即前往",
		exchange: "兑换码",
    exchangeNow: '立即兑换',
    expansion: '扩容',
    abandon: '放弃',
    logout: '账号注销',
	},
	lang: {
		chinese: "简体中文",
		english: "英语",
		portuguese: "葡萄牙语",
		Spanish: "西班牙语",
		Russian: "俄语",
	},
	tips: {
		tip1: "登录成功",
		tip2: "您确定要删除文件夹及文件夹内的所有数据吗？",
		tip3: "您确定要删除此数据吗？",
		tip4: "您确定要删除此标签吗？",
		tip5: "请输入标签名称/尺寸",
		tip6: "请输入行业",
		tip7: "请输入手机号/邮箱",
		tip8: "请输入验证码",
		tip9: "请设置新密码",
		tip10: "请确认新密码",
		tip11: "请输入密码",
		tip12: "请输入用户名",
		tip13: "请输入手机号",
		tip14: "请设置密码",
		tip15: "请确认密码",
		tip16: "请选择行业",
		tip17: "请输入邮箱",
		tip18: "请输入正确的手机号/邮箱",
		tip19: "请勾选阅读协议",
		tip20: "请输入正确手机号",
		tip21: "请输入正确邮箱",
		tip22: "两次输入的密码不一致",
		tip23: "没有找到结果，请试试其他的吧",
		tip24: "暂无内容",
		tip25: "快来创建你的标签吧",
		tip26: "请选择tprt、tprts格式文件!",
		tip27: "上传失败",
		tip28: "删除成功",
		tip29: "请选择png、jpg、jpeg、xlsx、xls、csv格式文件!",
		tip30: "文件太大，无法上传，请上传小于",
		tip31: "K的文件!",
		tip32: "还没账号？",
		tip33: "已有账号，",
		tip34: "修改成功",
		tip35: "复制成功",
		tip36: "请选择图层",
		tip37: "粘贴成功",
		tip38: "条形码不能有汉字！",
		tip39: "请选择JPG、JPEG、PNG格式的图片!",
		tip40: "暂无云端图片",
		tip41: "名称不能为空",
		tip42: "文件名不能包含下列任何字符:",
		tip43: "昵称不能为空",
		tip44: "修改成功，即将跳转到登录页面",
		tip45: "文件已存在是否覆盖",
		tip46: "网络错误！",
		tip47: "文件太大，请使用PC客户端打开！",
		tip48: "修改成功",
		tip49: "修改失败",
		tip50: "模板内容不能为空",
		tip51: "请选择单个图层",
		tip52: "至少选择一条数据",
		tip53: "请输入关键字搜索",
		tip54: "验证码发送成功",
		tip55: "注册成功",
		tip56: "添加成功",
		tip57: "移动成功",
		tip58: "保存成功",
		tip59: "提交成功",
		tip60: "请上传小于2M的图片",
		tip61: "内容不能为空",
		tip62: "当前文件夹未上传数据",
		tip63: "为了您的账户安全，请绑定手机号/邮箱！",
		tip64: "连接成功！",
		tip65: "连接失败！",
		tip66: "绑定成功！",
		tip67: "修改昵称为会员专属特权，请升级会员后使用!",
		tip68: "授权成功！",
		tip69: "标签字号显示异常，请调整浏览器最小字号！",
		tip70: "如何修改？",
		tip71: "提示",
		tip72: "已被其他元素关联，不可去关联元素！",
		tip73: "请输入0~9组成的十进制数",
		tip74: "请输入0~9,A~F组成的十六进制数",
		tip75: "请输入A~Z组成的二十六进制数",
		tip76: "请输入0~9,A~Z组成的三十六进制",
		tip77: "当前数据被其他元素引用，是否确定删除？",
		tip78: "当前数据存在被其他元素引用的数据，是否确定删除？",
		tip79: "建议使用Chrome、Microsoft Edge浏览器进行标签的编辑或打印！",
		tip80: "编码格式不正确！",
		tip81: "安全验证",
		tip82: "看不清? 换一张",
		tip83: "打印内容不能为空！",
		tip84: "请选择打印机",
		tip85: "点击",
		tip86: "下载安装并启动",
		tip87: "打印助手",
		tip88: "如果已安装并启动，请点此",
		tip89: "连接",
		tip90: "获取打印机失败，请重新连接打印助手！",
		tip91: "下发成功！",
		tip92: "下发失败，请重试！",
		tip93: "请安装并启动打印助手！",
		tip94: '支付成功',
		tip95: '支付超时',
		tip96: '请输入兑换码',
		tip97: '使用说明',
		tip98: '兑换码可通过参与汉码官方举办的运营活动获得;',
		tip99: '兑换流程：核实账户信息，填写兑换码，点击立即兑换;',
		tip100: '若您是会员，则兑换后会员有效期将顺延;',
		tip101: '请您注意兑换时间并在兑换有效期内兑换，过期作废。',
		tip102: '兑换成功',
		tip103: '模板解析错误',
		tip104: '表格功能为会员专属特权，请升级会员后使用!',
    tip105: '请使用电脑浏览器打开!',
    tip106: '请选择要删除的文件夹',
    tip107: '暂无数据',
    tip108: '请选择要操作的控件',
    tip109: '请先解锁锁定的控件',
    tip110: '请选择数据',
    tip111: '生成失败',
    tip112: '该控件已锁定',
    tip113: '请选择图片',
    tip114: '请选择机型',
    tip115: '您确定要删除选中的文件夹及文件夹内的所有数据吗?',
    tip116: '至少要有一个文件夹！',
    tip117: '是否保存当前编辑内容',
    tip118: '智能标签分割为会员专属特权，请升级会员后使用!',
    tip119: '自定义裁剪为会员专属特权，请升级会员后使用!',
    tip120: '注销成功!',
    tip121: '注销后会清理数据，请谨慎操作！如果注销后您希望继续使用汉码，需重新注册账号！',
    tip122: '控件已锁定',
    tip123: '控件已解锁',
    tip124: '请输入文本内容',
	},
  label: {
    label1: "文件夹",
    label2: "最近使用的标签",
    label3: "名称",
    label4: "编辑文件夹名称",
    label5: "编辑文件名称",
    label6: "编辑标签名称",
    label7: "修改昵称",
    label8: "编辑昵称",
    label9: "修改密码",
    label10: "昵称",
    label11: "账号",
    label12: "密码",
    label13: "行业",
    label14: "选择行业",
    label15: "修改",
    label16: "标签名称",
    label17: "保存标签至",
    label18: "移动标签至",
    label19: "打印预览",
    label20: "标签大小",
    label21: "模板类型",
    label23: "宽度",
    label24: "高度",
    label25: "多行多列",
    label26: "列数",
    label27: "行数",
    label28: "列间距",
    label29: "行间距",
    label30: "边距",
    label31: "左",
    label32: "右",
    label33: "上",
    label34: "下",
    label35: "我创建的",
    label36: "数据名称",
    label37: "添加日期",
    label38: "默认",
    label39: "名称A-Z",
    label40: "名称Z-A",
    label41: "日期升序",
    label42: "日期降序",
    label43: "尺寸小-大",
    label44: "尺寸大-小",
    label45: "账号登录",
    label46: "重置密码",
    label47: "我已阅读并同意",
    label48: "服务协议",
    label49: "和",
    label50: "隐私政策",
    label51: "新建文件夹1",
    label52: "开通会员畅享专属特权",
    label53: "使用微信支付",
    label54: "微信",
    label55: "升级会员专享",
    label63: "项会员特权",
    label56: "推荐",
    label57: "",
    label58: "天后到期", //几天后到期
    label59: "到期",
    label60: "今天到期",
    label61: "个月",
    label62: "第三方登录",
    label64: "打印机",
    label65: "打印份数",
    label66: "限时特惠",
    label67: "全部文件夹",
    label68: "已选中<span style='color: #003591'>{ total }</span>个文件夹",
    label69: '管理',
    label70: '年',
  },
	privilege: {
		privilege1: "云空间扩容",
		privilege2: "扫码取模",
		privilege3: "文件夹无上限",
		privilege4: "扫码打印",
		privilege5: "分享模板",
		privilege6: "快速打印",
		privilege7: "变量模板",
		privilege8: "专属字体",
		privilege9: "表格功能",
		privilege10: "打印记录",
		privilege11: "修改昵称",
    privilege12: "身份特权",
    privilege13: "云空间"
	},
	editAttr: {
		attrText1: "组件属性",
		attrText2: "数据源",
		attrText3: "普通文本",
		attrText4: "数据库文本",
		attrText5: "序列化文本",
		attrText6: "字体",
		attrText7: "字号",
		attrText8: "样式",
		attrText9: "旋转",
		attrText10: "字间距",
		attrText11: "行间距",
		attrText12: "自动换行",
		attrText13: "镜像",
		attrText14: "黑反",
		attrText15: "位置",
		attrText16: "日期",
		attrText17: "数字",
		attrText18: "时间类型",
		attrText19: "系统时间",
		attrText20: "指定时间",
		attrText21: "条码类型",
		attrText22: "数据位置",
		attrText23: "不显示",
		attrText24: "下方",
		attrText25: "缩放倍率",
		attrText26: "居左",
		attrText27: "居中",
		attrText28: "居右",
		attrText29: "纠错率",
		attrText30: "添加本地图片",
		attrText31: "添加云端图片",
		attrText32: "等比缩放",
		attrText33: "线条类型",
		attrText34: "线条粗细",
		attrText35: "数据类型",
		attrText36: "请选择",
		attrText37: "连接数据库",
		attrText38: "插入数据",
		attrText39: "递变数值",
		attrText40: "递变量",
		attrText41: "数量",
		attrText42: "数字格式",
		attrText43: "日期格式",
		attrText44: "选择时间",
		attrText45: "圆角半径",
		attrText46: "适应画布",
		attrText47: "请输入内容",
		attrText48: "实线",
		attrText49: "虚线",
		attrText50: "复制",
		attrText51: "粘贴",
		attrText52: "锁定",
		attrText53: "解锁",
		attrText54: "对齐",
		attrText55: "左对齐",
		attrText56: "垂直居中",
		attrText57: "右对齐",
		attrText58: "水平居中",
		attrText59: "顶对齐",
		attrText60: "底对齐",
		attrText61: "水平分布",
		attrText62: "垂直分布",
		attrText63: "图层顺序",
		attrText64: "置顶",
		attrText65: "置底",
		attrText66: "删除",
		attrText67: "云端数据",
		attrText68: "本地数据",
		attrText69: "撤销",
		attrText70: "恢复",
		attrText71: "保存",
		attrText72: "另存为",
		attrText73: "删除",
		attrText74: "上移",
		attrText75: "下移",
		attrText76: "左移",
		attrText77: "右移",
		attrText78: "全选",
		attrText79: "打印",
		attrText80: "画布放大",
		attrText81: "画布缩小",
		attrText82: "多选",
		attrText83: "滚轮向上",
		attrText84: "滚轮向下",
		attrText85: "左键点击",
		attrText86: "新手引导",
		attrText87: "查看快捷键",
		attrText88: "快捷键说明",
		attrText89: "适应窗口",
		attrText90: "缩小",
		attrText91: "放大",
		attrText92: "序列类型",
		attrText93: "10进制",
		attrText94: "对齐",
		attrText95: "天数偏移",
		attrText96: "分钟偏移",
		attrText97: "背景图片",
		attrText98: "数据",
		attrText99: "打印背景图片",
		attrText100: "选择数据",
		attrText101: "元素值",
		attrText102: "新增数据",
		attrText103: "16进制",
		attrText104: "26进制",
		attrText105: "36进制",
		attrText106: "行高",
		attrText107: "列宽",
		attrText108: "内容",
		attrText109: "插入行",
		attrText110: "插入列",
		attrText111: "删除行",
		attrText112: "删除列",
		attrText113: "合并",
		attrText114: "拆分",
		attrText115: "时间格式",
		attrText116: "上方",
		attrText117: "填充",
		attrText118: "前缀",
    attrText119: "后缀",
    attrText120: '时间',
    attrText121: '线条方向',
    attrText122: '云端图片',
    attrText123: '平铺',
    attrText124: '拉伸',
    attrText125: '横向文本',
    attrText126: '竖向文本',
    attrText127: '弧形文本',
    attrText128: '加粗',
    attrText129: '斜体',
    attrText130: '下划线',
    attrText131: '删除线',
    attrText132: '网格',
    attrText133: '画布锁定',
    attrText134: '画布解锁',
	},
	module: {
		editText1: "文本",
		editText2: "时间",
		editText3: "条形码",
		editText4: "二维码",
		editText5: "图片",
		editText6: "形状",
		editText7: "矩形",
		editText8: "圆角矩形",
		editText9: "菱形",
		editText10: "椭圆",
		editText11: "三角形",
		editText12: "实心矩形",
		editText13: "图标",
		editText14: "斜线",
		editText15: "水平线",
		editText16: "垂直线",
		editText17: "表格",
	},
	guide: {
		guideText1: "欢迎来到",
		guideText2: "简单高效",
		guideText3: "应用场景化",
		guideText4: "便捷的资源库",
		guideText5: "多端数据互通",
		guideText6: "HereLabel标签设计软件让标签设计实现所见即所得，只需简单拖拽即可完成，标签设计从未如此简单高效。快来体验吧！",
		guideText7: "跳过",
		guideText8: "开始学习",
		guideText9: "功能指引：行业模板",
		guideText10: "场景化的标签模板，提供了零售、服装、条码等各行业常用标签。",
		guideText11: "功能指引：云端模板",
		guideText12: "个人标签自动存储在云端，可创建文件夹管理标签，可在PC、移动端使用标签。",
		guideText13: "功能指引：云端数据",
		guideText14: "图片、表格等文件可永久存储在云端，可在Web、PC、移动端随时随地使用。",
		guideText15: "功能指引：个人中心",
		guideText16: "点击可修改语言、切换单位或进入个人中心页修改资料。",
		guideText17: "功能指引：新建标签",
		guideText18: "设置好标签名称、尺寸、行数、列数，开始创建你的个人标签吧！",
		guideText19: "知道了",
		guideText20: "开始创建标签",
		guideText21: "欢迎进入编辑页",
		guideText22: "只需简单几步即可完成标签编辑，快来一起学习吧！",
		guideText23: "元件库",
		guideText24: "提供了文本、时间、条码等多种元件，鼠标点击或拖拽即可添加对应元件。",
		guideText25: "继续学习",
		guideText26: "功能区",
		guideText27: "元件功能包含了锁定、撤销、恢复、对齐方式、图层置顶、图层置底和复制、粘贴、删除。",
		guideText28: "也可以右击画布内的元件打开右键菜单进行操作。",
		guideText29: "属性区",
		guideText30: "选中元件可打开属性区，修改对应元件的数据源、样式、大小、位置等属性。",
		guideText31: "保存打印",
		guideText32: "点击标签设置可修改当前标签尺寸。<br />标签编辑完成，即可尝试打印当前标签哦！",
		guideText33: "我都学会了",
		guideText34: "下一步",
    guideText35: "汉码标签设计软件让标签设计实现所见即所得，只需简单拖拽即可完成，标签设计从未如此简单高效。快来体验吧！",
    guideText36: "功能引导：文档打印",
    guideText37: "支持Word、Excel、PPT、PDF、图片等多种格式"
	},
	suggest: {
		suggest1: "问题类型",
		suggest2: "功能异常",
		suggest3: "产品建议",
		suggest4: "问题描述",
		suggest5: "请详细描述您遇到的问题，我们会尽快解决！",
		suggest6: "联系方式",
		suggest7: "请输入您的联系方式",
		suggest8: "问题截图",
		suggest9: "提交",
		suggest10: '提交成功，感谢您的反馈！',
		suggest11: '有帮助',
		suggest12: '无帮助',
		suggest13: '很抱歉，请问出了什么问题？',
		suggest14: '描述不清晰/看不懂',
		suggest15: '描述的内容不准确/与主题无关',
		suggest16: '这个功能不好用',
		suggest17: '其他问题',
		suggest18: '请选择问题类型',
		suggest19: '感谢反馈，您还有其他建议么？',
		suggest20: '请输入您的建议',
	},
	download: {
		download1: "中文",
		download2: "English",
		download3: "下载",
		download4: "下载手机端",
		download5: "多端数据互通",
		download6: "PC、WEB、iOS、Android四端数据实时同步， 让标签存储不再受时间空间限制。",
		download7: "简单高效",
		download8: "让标签设计实现所见即所得，只需简单拖拽即可完成，标签设计从未如此简单高效。",
		download9: "应用场景化",
		download10: "针对不同的行业应用，提供了丰富的场景化标签模板，地址标签、文本标签、条码标签、运输标签、零售标签等等，只需一键即可设计出心仪的标签。",
		download11: "便捷的资源库",
		download12: "我们希望优质的资源是触手可及的，海量的常用场景标志使标签设计更加便捷高效。",
		download13: "（仅支持网页版）",
	},
	help: {
		help1: "汉码帮助中心",
		help2: "查看全部",
		help3: "目录",
		help4: "更新时间",
		help5: "搜索结果",
  },
  document: {
    text1: '打开文档',
    text2: '点击或将文件直接拖拽进该区域即可上传',
    text3: '自动裁剪',
    text4: '智能标签裁剪',
    text5: '自定义裁剪',
    text6: '直接打印',
    text7: '文档',
    text8: '一键裁剪文档多余空白',
    text9: '智能标签分割',
    text10: '裁剪为多份标签文件',
    text11: '行数',
    text12: '列数',
    text13: '示例',
    text14: '如行数*列数为：2*2',
    text15: '下一步',
    text16: '设置纸张尺寸',
    text17: '自定义',
    text18: '宽度',
    text19: '高度',
    text20: '打印效果',
    text21: '文档模式',
    text22: '图片模式',
    text23: '清晰度',
    text24: '排列方式',
    text25: '拉伸',
    text26: '填充',
    text27: '等比',
    text28: '打印份数',
    text29: '页码范围',
    text30: '全部',
    text31: '当前页',
    text32: '页码范围',
    text33: '页码用逗号分隔(如：1-3,5)',
    text34: '上一步',
    text35: '裁剪',
    text36: '请上传txt, pptx, ppt, pdf, doc, docx, xls, xlsx, bp, jpg, png文件',
    text37: '文档内容为空',
    text38: '页面范围输入有误',
    text39: '解析失败',
    text40: '是否应用整个文档',
  },
  expandCloud: {
    text1: '已使用',
    text2: '总空间',
    text3: '购买记录',
    text4: '购买空间',
    text5: '购买日期',
    text6: '有效期至',
    text7: '暂无购买记录',
    text8: '扩容套餐',
    text9: '空间套餐购买成功后5分钟内生效；购买的空间过期后会回收，但会保留已上传的文件。'
  },
  industryTemplate: {
    text1: '选择机型',
    text2: '更多筛选',
    text3: '搜索内容',
    text4: '筛选条件',
    text5: '模板尺寸',
    text6: '选择模板宽度',
    text7: '选择模板高度',
    text8: '{tempCount}个模板',
    text9: '切换机型',
    text10: '筛选内容',
	text11: '选择系列',
  }
};