import { drawMirrorNode, getAssociate, resetNodeAttr } from "../js/common";
import {
  TransformerListening,
  destroyTableCellSelect,
  destroyTransformer
} from "../js/transformerStyle";
var _this;

//记录元素的属性
const getNodeAttrs = (node) => {
  let attrs = JSON.parse(JSON.stringify(node.attrs));
  if (node.attrs.type == "WinText" || node.attrs.type == "TimeText") {
    if (node.attrs.parameter.TextArrange == 1) {
      let textGroup = node.find(".textGroup")[0];
      let group = []
      textGroup.children.forEach((item) => {
        group.push(JSON.parse(JSON.stringify(item.attrs)))
      })
      let tempText = node.find('.tempText')[0]
      attrs.children = {
        //文本背景层
        tag: JSON.parse(JSON.stringify(node.getTag().attrs)),
        text: JSON.parse(JSON.stringify(tempText.attrs)),
        //文本内容层
        textGroup: JSON.parse(JSON.stringify(textGroup.attrs)),
        textGroupChildren: group,
      };
    } else {
      attrs.children = {
        //文本背景层
        tag: JSON.parse(JSON.stringify(node.getTag().attrs)),
        //文本内容层
        text: JSON.parse(JSON.stringify(node.getText().attrs)),
      };
    }
  } else if (node.attrs.type == "GeneralBarcode" || node.attrs.type == "QRCode" || node.attrs.type == "Image") {
    attrs.children = JSON.parse(JSON.stringify(node.children[0].attrs));
  } else if (node.attrs.type == "Table") {
    let tableRowLine = node.find(".tableRowLine")[0];
    attrs.tableRowLine = tableRowLine.children.map((rowLine) => {
      return JSON.parse(JSON.stringify(rowLine.attrs));
    });
    let tableColumnLine = node.find(".tableColumnLine")[0];
    attrs.tableColumnLine = tableColumnLine.children.map((columnLine) => {
      return JSON.parse(JSON.stringify(columnLine.attrs));
    });
    let cellGroup = node.find(".cellGroup")[0];
    attrs.cellGroup = cellGroup.children.map((cell) => {
      let cellBg = cell.find(".cellBg")[0];
      let cellText = cell.find(".cellText")[0];
      let cellSelect = cell.find(".cellSelect")[0];
      return {
        cell: JSON.parse(JSON.stringify(cell.attrs)),
        cellBg: JSON.parse(JSON.stringify(cellBg.attrs)),
        cellText: JSON.parse(JSON.stringify(cellText.attrs)),
        cellSelect: JSON.parse(JSON.stringify(cellSelect.attrs)),
      };
    });
  }

  return attrs;
};

// 记录历史记录
//type: add、del、edit、changeShape
const recordHistory = (event, oldAttrs, newAttrs, type) => {
  _this = event;
  //当历史记录指针未处于最后位置时，删除指针后面的所有元素
  if (_this.historyIndex < _this.historyList.length - 1) {
    _this.historyList = _this.historyList.slice(0, _this.historyIndex + 1);
  }

  _this.historyList.push({
    oldAttrs,
    newAttrs,
    type,
  });

  _this.historyIndex = _this.historyList.length - 1;
  // console.log(_this.historyList);
};

//记录多选数据历史记录
const recordMultipleHistory = (event, attrsData) => {
  _this = event;
  //当历史记录指针未处于最后位置时，删除指针后面的所有元素
  if (_this.historyIndex < _this.historyList.length - 1) {
    _this.historyList = _this.historyList.slice(0, _this.historyIndex + 1);
  }
  _this.historyList.push({
    attrsData,
    type: "multiple",
  });
  _this.historyIndex = _this.historyList.length - 1;
  // console.log(_this.historyList)
};

//撤销
const undoStep = (event) => {
  _this = event;
  destroyTableCellSelect(_this.designLayer)
  destroyTransformer(_this.designLayer);

  if (_this.selectNode) {
    _this.selectNode.draggable(false);
    _this.selectNode = "";
    _this.elementAttr = {};
  }

  let data = _this.historyList[_this.historyIndex];
  const dealNode = (data) => {
    let node = "";
    switch (data.type) {
      case "add":
        node = _this.designLayer.find("#" + data.newAttrs.id)[0];
        node.remove();
        _this.designLayer.draw();
        break;
      case "edit":
        editNode(data.oldAttrs);
        break;
      case "del":
        addNode(data.oldAttrs);
        break;
      case "changeShape":
        node = _this.designLayer.find("#" + data.newAttrs.id)[0];
        node.remove();
        _this.designLayer.draw();
        addNode(data.oldAttrs);
        break;
      case "multiple":
        data.attrsData.map((item) => {
          dealNode(item);
        });
        break;
    }
  };
  dealNode(data);

  _this.historyIndex--;
};
//恢复
const restoreStep = (event) => {
  _this = event;
  _this.historyIndex++;
  destroyTableCellSelect(_this.designLayer)
  destroyTransformer(_this.designLayer);

  if (_this.selectNode) {
    _this.selectNode.draggable(false);
    _this.selectNode = "";
    _this.elementAttr = {};
  }
  let data = _this.historyList[_this.historyIndex];
  const dealNode = (data) => {
    let node = "";
    switch (data.type) {
      case "add":
        addNode(data.newAttrs);
        break;
      case "edit":
        editNode(data.newAttrs);
        break;
      case "del":
        node = _this.designLayer.find("#" + data.oldAttrs.id)[0];
        node.remove();
        _this.designLayer.draw();
        break;
      case "changeShape":
        node = _this.designLayer.find("#" + data.oldAttrs.id)[0];
        node.remove();
        _this.designLayer.draw();
        addNode(data.newAttrs);
        break;
      case "multiple":
        data.attrsData.map((item) => {
          dealNode(item);
        });
        break;
    }
  };
  dealNode(data);
};

//新增节点
const addNode = (nodeAttrs) => {
  let type = nodeAttrs.type;
  let attrs = ''
  switch (type) {
    case "WinText":
    case "TimeText":
      attrs = JSON.parse(JSON.stringify(nodeAttrs));
      let textNode = new Konva.Label();
      delete attrs.children;
      textNode.setAttrs(attrs);

      let tag = new Konva.Tag();
      tag.setAttrs(nodeAttrs.children.tag);
      textNode.add(tag);

      if (textNode.attrs.parameter.TextArrange == 1) {
        let text = new Konva.Text();
        text.setAttrs(nodeAttrs.children.text)
        textNode.add(text);

        let textGroup = new Konva.Group();
        textGroup.setAttrs(nodeAttrs.children.textGroup)
        textNode.add(textGroup)
        let textGroupChildren = JSON.parse(JSON.stringify(nodeAttrs.children.textGroupChildren));
        textGroupChildren.forEach((attr) => {
          let text = new Konva.Text();
          text.setAttrs(attr);
          textGroup.add(text); 
        })
      } else {
        let text = new Konva.Text();
        text.setAttrs(nodeAttrs.children.text);
        textNode.add(text);
      }
      dealMirrorNode(textNode,_this)
      _this.elementGroup.add(textNode);
      TransformerListening(_this, textNode);
      _this.designLayer.draw();
      break;
    case "GeneralBarcode":
    case "QRCode":
    case "Image":
      attrs = JSON.parse(JSON.stringify(nodeAttrs));
      let children = JSON.parse(JSON.stringify(attrs.children))
      let imgObj = new Image();
      imgObj.setAttribute("crossOrigin", "Anonymous");
      imgObj.onload = async () => {
        let imageNode = new Konva.Image();
        imageNode.setAttrs(children);
        delete attrs.children
        imageNode.image(imgObj);
        let imageGroup = new Konva.Group() 
        imageGroup.setAttrs(attrs)
        imageGroup.add(imageNode)
        _this.elementGroup.add(imageGroup);
        TransformerListening(_this, imageGroup);
        dealMirrorNode(imageGroup,_this)
        _this.designLayer.draw();
      };
      imgObj.src = children.imageSrc;
      break;
    case 'LineHorizontal':
    case 'LineVertical':
    case 'Line':
      let lineNode = new Konva.Line();
      lineNode.setAttrs(nodeAttrs);
      _this.elementGroup.add(lineNode);
      TransformerListening(_this, lineNode);
      dealMirrorNode(lineNode,_this)
      _this.designLayer.draw();
      break;
    case "Rectangle":
    case "RoundRectangle":
      let rectNode = new Konva.Rect();
      rectNode.setAttrs(nodeAttrs);
      _this.elementGroup.add(rectNode);
      TransformerListening(_this, rectNode);
      dealMirrorNode(rectNode,_this)
      _this.designLayer.draw();
      break;
    case "Ellipse":
      let ellipseNode = new Konva.Ellipse();
      ellipseNode.setAttrs(nodeAttrs);
      _this.elementGroup.add(ellipseNode);
      TransformerListening(_this.$parent, ellipseNode);
      dealMirrorNode(ellipseNode,_this)
      _this.designLayer.draw();
      break;
    case "Diamond":
      let diamondNode = new Konva.Line();
      diamondNode.setAttrs(nodeAttrs);
      _this.elementGroup.add(diamondNode);
      TransformerListening(_this, diamondNode);
      dealMirrorNode(diamondNode,_this)
      _this.designLayer.draw();
      break;
    case "Triangle":
      let triangleNode = new Konva.Line();
      triangleNode.setAttrs(nodeAttrs);
      _this.elementGroup.add(triangleNode);
      TransformerListening(_this, triangleNode);
      dealMirrorNode(triangleNode,_this)
      _this.designLayer.draw();
      break;
    case "Table":
      let tableAttrs = JSON.parse(JSON.stringify(nodeAttrs));
      let tableRowLineAttrs = JSON.parse(
        JSON.stringify(tableAttrs.tableRowLine)
      );
      let tableColumnLineAttrs = JSON.parse(
        JSON.stringify(tableAttrs.tableColumnLine)
      );
      let cellGroupAttrs = JSON.parse(JSON.stringify(tableAttrs.cellGroup));
      delete tableAttrs.tableRowLine;
      delete tableAttrs.tableColumnLine;
      delete tableAttrs.cellGroup;

      tableAttrs.parameter.cellMultiple = false
      tableAttrs.parameter.mulCellPos = []

      let tableNode = new Konva.Group();
      tableNode.setAttrs(tableAttrs);
      //绘制横线
      let tableRowLine = new Konva.Group({
        name: "tableRowLine",
      });
      tableNode.add(tableRowLine);
      tableRowLineAttrs.map((item) => {
        let rowLine = new Konva.Rect();
        rowLine.setAttrs(item);
        rowLine.hitFunc((context) => {
          context.beginPath();
          context.rect(item.x, item.y, item.width, item.height);
          context.closePath();
        });
        tableRowLine.add(rowLine);
      });
      //绘制竖线
      let tableColumnLine = new Konva.Group({
        name: "tableColumnLine",
      });
      tableNode.add(tableColumnLine);
      tableColumnLineAttrs.map((item) => {
        let columnLine = new Konva.Rect();
        columnLine.setAttrs(item);
        columnLine.hitFunc((context) => {
          context.beginPath();
          context.rect(item.x, item.y, item.width, item.height);
          context.closePath();
        });
        tableColumnLine.add(columnLine);
      });

      let strokeWidth = tableRowLineAttrs[0].height;
      //绘制单元格
      let cellGroup = new Konva.Group({
        name: "cellGroup",
        x: strokeWidth,
        y: strokeWidth,
      });
      tableNode.add(cellGroup);
      cellGroupAttrs.map((item) => {
        let cell = new Konva.Group();
        cell.setAttrs(item.cell);
        cellGroup.add(cell);

        let cellBg = new Konva.Rect();
        cellBg.setAttrs(item.cellBg);
        cell.add(cellBg);

        let cellText = new Konva.Text();
        cellText.setAttrs(item.cellText);
        cell.add(cellText);

        let cellSelect = new Konva.Rect();
        cellSelect.setAttrs(item.cellSelect);
        cellSelect.hide();
        cell.add(cellSelect);
      });
      _this.elementGroup.add(tableNode);
      TransformerListening(_this, tableNode);
      _this.designLayer.draw();
      break;
  }
};

//编辑节点
const editNode = (nodeAttrs) => {
  let node = _this.designLayer.find("#" + nodeAttrs.id)[0];
  let type = nodeAttrs.type;
  let attrs = ''
  switch (type) {
    case "WinText":
    case "TimeText":
      attrs = JSON.parse(JSON.stringify(nodeAttrs));
      delete attrs.children;
      node.setAttrs(attrs);
      node.removeChildren();
      let tag = new Konva.Tag();
      tag.setAttrs(nodeAttrs.children.tag);
      node.add(tag)
      if (node.attrs.parameter.TextArrange == 1) {
        let text = new Konva.Text();
        text.setAttrs(nodeAttrs.children.text)
        node.add(text);

        let textGroup = new Konva.Group();
        textGroup.setAttrs(nodeAttrs.children.textGroup)
        node.add(textGroup)
        let textGroupChildren = JSON.parse(JSON.stringify(nodeAttrs.children.textGroupChildren));
        textGroupChildren.forEach((attr) => {
          let text = new Konva.Text();
          text.setAttrs(attr);
          textGroup.add(text); 
        })
      } else {
        let text = new Konva.Text();
        text.setAttrs(nodeAttrs.children.text);
        node.add(text)
        resetNodeAttr(node);
      }
      dealMirrorNode(node,_this)
      _this.designLayer.draw();
      if (type == 'WinText') {
        getAssociate(_this, node);
      }
      
      break;
    case "GeneralBarcode":
    case "QRCode":
    case "Image":
      attrs = JSON.parse(JSON.stringify(nodeAttrs));
      let children = JSON.parse(JSON.stringify(attrs.children))
      let imgObj = new Image();
      imgObj.setAttribute("crossOrigin", "Anonymous");
      imgObj.onload = async () => {
        node.children[0].setAttrs(children)
        node.children[0].image(imgObj);
        delete attrs.children
        node.setAttrs(attrs);
        dealMirrorNode(node, _this)
        _this.designLayer.draw();
        if (type == 'GeneralBarcode' || type == 'QRCode') {
          getAssociate(_this, node);
        }
      };
      imgObj.src = children.imageSrc;
      break;
    case 'LineHorizontal':
    case 'LineVertical':
    case 'Line':
    case "Rectangle":
    case "RoundRectangle":
    case "Ellipse":
    case "Diamond":
    case "Triangle":
      node.setAttrs(nodeAttrs);
      dealMirrorNode(node,_this)
      _this.designLayer.draw();
      break;
    case "Table":
      let tableAttrs = JSON.parse(JSON.stringify(nodeAttrs));
      let tableRowLineAttrs = JSON.parse(
        JSON.stringify(tableAttrs.tableRowLine)
      );
      let tableColumnLineAttrs = JSON.parse(
        JSON.stringify(tableAttrs.tableColumnLine)
      );
      let cellGroupAttrs = JSON.parse(JSON.stringify(tableAttrs.cellGroup));
      delete tableAttrs.tableRowLine;
      delete tableAttrs.tableColumnLine;
      delete tableAttrs.cellGroup;
      tableAttrs.parameter.cellMultiple = false
      tableAttrs.parameter.mulCellPos = []
      
      node.setAttrs(tableAttrs);

      //横线
      let tableRowLine = node.find(".tableRowLine")[0];
      tableRowLine.removeChildren();
      tableRowLineAttrs.forEach((item) => {
        let rowLine = new Konva.Rect();
        rowLine.setAttrs(item);
        rowLine.hitFunc((context) => {
          context.beginPath();
          context.rect(item.x, item.y, item.width, item.height);
          context.closePath();
        });
        tableRowLine.add(rowLine);
      });

      //竖线
      let tableColumnLine = node.find(".tableColumnLine")[0];
      tableColumnLine.removeChildren();
      tableColumnLineAttrs.forEach((item) => {
        let columnLine = new Konva.Rect();
        columnLine.setAttrs(item);
        columnLine.hitFunc((context) => {
          context.beginPath();
          context.rect(item.x, item.y, item.width, item.height);
          context.closePath();
        });
        tableColumnLine.add(columnLine);
      });

      let strokeWidth = tableRowLineAttrs[0].height;
      //绘制单元格
      let cellGroup = node.find(".cellGroup")[0];
      cellGroup.setAttrs({
        x: strokeWidth,
        y: strokeWidth,
      });
      cellGroup.removeChildren();

      cellGroupAttrs.forEach((item) => {
        let cell = new Konva.Group();
        cell.setAttrs(item.cell);
        cellGroup.add(cell);

        let cellBg = new Konva.Rect();
        cellBg.setAttrs(item.cellBg);
        cell.add(cellBg);

        let cellText = new Konva.Text();
        cellText.setAttrs(item.cellText);
        cell.add(cellText);

        let cellSelect = new Konva.Rect();
        cellSelect.setAttrs(item.cellSelect);
        cellSelect.hide();
        cell.add(cellSelect);
      });

      _this.designLayer.draw();
      break;
  }
};

const dealMirrorNode = (node, _this) => {
  if(node.attrs.parameter.CopyMirrorImage){
    drawMirrorNode(node, _this, false)
  } else {
    let mirrorNode = _this.elementGroup.find(`#${node.id()}_mirror`)[0]
    if (mirrorNode) {
      let transformers = _this.designLayer.find(`.${mirrorNode.id()}`)[0]
      if (transformers) {
        transformers.destroy()
      }
      mirrorNode.destroy()
    }
  }
}

export {
  getNodeAttrs, recordHistory, recordMultipleHistory, restoreStep, undoStep
};

